import { useEffect } from 'react';
import store from '../../redux/store';
import { ALLOW_USERNAME_CHANGE } from '../../redux/types';
import countdown from '../Countdownjs/countdown';
import { useTranslation } from 'react-i18next';
const TimerCountdown = ({
	releaseDate,
	preview,
	post,
	previewLetter,
	letter,
	changeUsernameTimer,
	selectorId,
	text,
	countdownTimer,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		let interval;
		interval = countdown(
			new Date(releaseDate),
			function (ts) {
				if (ts.value <= -500) {
					if (preview || post || previewLetter || letter || countdownTimer) {
						document.getElementById(selectorId).innerHTML = `${
							text ? text : t('release_in')
						} ${ts.toHTML('strong')}.`;
					} else if (changeUsernameTimer) {
						document.getElementById(selectorId).innerHTML = `${t(
							'change_username_in',
						)} ${ts.toHTML('strong')}.`;
					} else {
						document.getElementById(selectorId).innerHTML = ts.toHTML('strong');
					}
				} else {
					if (preview || post) {
						document.getElementById(selectorId).innerHTML = preview
							? t('choose_future_prompt', {
									action: t('released'),
							  })
							: `<strong>${t('releasing')}</strong>.`;
					} else if (previewLetter || letter) {
						document.getElementById(selectorId).innerHTML = previewLetter
							? t('choose_future_prompt', {
									action: t('sent').toLowerCase(),
							  })
							: `<strong>${t('sending')}</strong>.`;
					} else if (changeUsernameTimer) {
						document.getElementById(selectorId).innerHTML = t(
							'can_change_username',
						);
						store.dispatch({ type: ALLOW_USERNAME_CHANGE, payload: true });
					} else {
						document.getElementById(selectorId).innerHTML = t('countdown_end');
					}
					if (interval) {
						window.clearInterval(interval);
					}
				}
			},
			countdown.YEARS |
				countdown.MONTHS |
				countdown.DAYS |
				countdown.HOURS |
				countdown.MINUTES |
				countdown.SECONDS,
		).toString();

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [changeUsernameTimer, post, preview, releaseDate, selectorId]);

	return (
		<div
			id={selectorId ? selectorId : 'timerCountdown'}
			// className='notranslate'
		></div>
	);
};
export default TimerCountdown;
