import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import ImgSrc from './ImgSrc';
import {
	queryUsers,
	clearQueriedUsers,
	addRecipients,
	deleteRecipients,
	clearQueriedUsersErrors,
	setOpenedDialog,
	loadSearchbar,
	errSearchbar,
} from '../../redux/actions/dataActions';
import { connect } from 'react-redux';
import {
	BottomNavigationAction,
	DialogContent,
	IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Toolbar } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import QueryUsersSkeleton from '../Skeletons/QueryUsersSkeleton';
import { Helmet } from 'react-helmet-async';
import FollowButton from './FollowButton';
import CustomAlerts from './CustomAlerts';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CopyText from './CopyText';
import { withRouter } from '../../redux/withRouter';
import CustomTooltip from './CustomTooltip';
import Media from 'react-media';
import CustomInput from './CustomInput';
import { toast } from 'react-toastify';
import { AccountCircle } from '@mui/icons-material';
import { t } from 'i18next';

class Searchbar extends Component {
	state = {
		open: false,
		searchInput: '',
		delete: false,
		viewResults: false,
	};

	componentWillUnmount() {
		if (
			this.props.data.errorQueryingUsers !== null ||
			this.props.data.errorQueryingMoreUsers !== null
		) {
			this.props.clearQueriedUsersErrors();
		}
	}
	handleOpen = () => {
		this.props.setOpenedDialog(true);
		if (
			this.state.searchInput === '' &&
			this.props.data.errorQueryingUsers !== null &&
			this.props.data.errorQueryingMoreUsers !== null
		) {
			this.props.clearQueriedUsersErrors();
		}
		this.setState({ open: true });
	};
	handleClose = () => {
		this.setState({
			open: false,
			searchInput: '',
			viewResults: false,
		});
		if (this.props.data.openedDialog) {
			this.props.setOpenedDialog(false);
		}
		if (
			this.props.data.errorQueryingUsers !== null ||
			this.props.data.errorQueryingMoreUsers !== null
		) {
			this.props.clearQueriedUsersErrors();
		}
	};

	debounce = (callback, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => callback(...args), delay);
		};
	};

	debouncedLog = this.debounce(
		(queryData) => this.props.queryUsers(queryData),
		600,
	);

	handleChange = (event) => {
		event.preventDefault();
		this.setState({
			[event.target.name]: event.target.value.toLowerCase().trim(),
		});
		if (this.props.onChange) {
			this.props.onChange(event.target.value.toLowerCase().trim());
		}
		if (
			this.props.data.errorQueryingUsers !== null ||
			this.props.data.errorQueryingMoreUsers !== null
		) {
			this.props.clearQueriedUsersErrors();
		}
		var input = event.target.value.toLowerCase().trim();
		if (input !== '' && !this.props.data.loadingSearchbar) {
			this.props.loadSearchbar();
		}
		if (input === '') {
			this.props.errSearchbar('type_a_username');
		}
		var queryData = {
			search: input,
			collection: 'usernames',
		};
		if (this.props.searchGroup) {
			queryData.collection = 'followers';
			queryData.queryList = this.props.searchGroup;
			queryData.queryUser = this.props.paramsHandle;
		}
		this.debouncedLog(queryData);
		if (input === '') {
			this.props.clearQueriedUsers();
			this.setState({
				viewResults: false,
			});
		}
	};
	resetInput = () => {
		this.setState({
			searchInput: '',
			viewResults: false,
			lastSearch: '',
		});
		this.props.clearQueriedUsers();
		if (
			this.props.data.errorQueryingUsers !== null ||
			this.props.data.errorQueryingMoreUsers !== null
		) {
			this.props.clearQueriedUsersErrors();
		}
	};
	viewAllResults = (lastQueriedUserId) => {
		this.setState({ viewResults: true, lastSearch: lastQueriedUserId });
		this.getMoreUsers(lastQueriedUserId);
	};
	getMoreUsers = (lastQueriedUserId) => {
		if (lastQueriedUserId !== this.state.lastSearch) {
			this.props.queryUsers({
				lastQueriedUser: lastQueriedUserId,
				charAt: this.state.searchInput.length - 1,
				collection: 'usernames',
			});
			this.setState({
				lastSearch: lastQueriedUserId,
			});
		}
	};

	focus = (id) => {
		document.getElementById(id).focus();
	};
	addAndRemoveRecipient = (userId, handle, name, imgUrl, imgColor) => {
		let recipient = {
			userId: userId,
			handle: handle,
			name: name,
			imageUrl: imgUrl,
			imageColor: imgColor,
		};

		const from = this.props.goodbyeLetter ? 'letter' : 'post';
		let recipients = this.props.goodbyeLetter
			? this.props.data.letterRecipients
			: this.props.data.postRecipients;

		if (this.props.user.credentials.handle !== recipient.handle) {
			if (
				recipients.findIndex((recip) => recip.handle === recipient.handle) ===
				-1
			) {
				if (recipients.length < 15) {
					this.props.addRecipients(recipient, from);
				} else {
					toast.info(t('only_15_recipients'), {
						type: 'info',
						isLoading: false,
						autoClose: 6000,
						hideProgressBar: false,
						closeOnClick: true,
						draggable: true,
						progress: undefined,
						containerId: 'app',
						toastId: 'recipientsExceeded',
					});
				}

				this.setState({
					searchInput: '',
					viewResults: false,
					lastSearch: '',
				});
			} else {
				this.props.deleteRecipients(recipient);
			}
		}
	};

	travelUser = (dataHandle, disabled, dataUserId) => {
		this.props.history(`/users/${dataHandle}`, {
			state: { disabled: disabled, data: dataUserId },
		});
		this.handleClose();
	};
	render() {
		const { searchInput, viewResults, open } = this.state;
		const {
			adminFunctionality,
			setRecipient,
			makePostFunctionality,
			user: {
				authenticated,
				emailVerified,
				credentials: {
					userId,
					imageUrl,
					imageColor,
					name,
					admin,
					thumbnail,
					handle,
				},
			},
			data: {
				queriedUsers,
				queriedFollowingUsers,
				lastQueriedUserId,
				loadingSearchbar,
				errorQueryingUsers,
				errorQueryingMoreUsers,
				letterRecipients,
				postRecipients,
			},
			UI: { mode },
			focus,
			disabledSearch,
			errorMP,
			searchGroup,
			queryCount,
			// paramsHandle,
			goodbyeLetter,
			navbar,
			navMobile,
			hasMoreResults,
		} = this.props;
		const recipientArray = goodbyeLetter ? letterRecipients : postRecipients;
		const userResults =
			queriedUsers.length > 0
				? queriedUsers
				: !searchGroup && queriedFollowingUsers
				? queriedFollowingUsers
				: [];
		const notificationBackgroundColor =
			mode === 'light' ? 'notificationReadLight' : 'notificationReadDark';
		const queryUsersMarkup = authenticated ? (
			loadingSearchbar && !viewResults ? (
				<QueryUsersSkeleton
					number={queryCount ? queryCount : 1}
					showHr
					hasMore={hasMoreResults}
				/>
			) : errorQueryingUsers === 'user_not_found' ? (
				<CustomAlerts
					info={errorQueryingUsers}
					message={t('user_not_found')}
					icon={<AccountCircle />}
					margin='0.5rem 0 0'
				/>
			) : errorQueryingUsers !== null &&
			  errorQueryingUsers !== 'type_a_username' ? (
				<CustomAlerts error={errorQueryingUsers} />
			) : userResults.length > 0 ? (
				<div>
					<InfiniteScroll
						dataLength={userResults.length}
						scrollThreshold={0.7}
						next={() => this.getMoreUsers(lastQueriedUserId)}
						hasMore={
							viewResults &&
							queriedUsers.length > 0 &&
							queriedUsers.length % 5 === 0 &&
							!errorQueryingUsers &&
							!errorQueryingMoreUsers
						}
						scrollableTarget={
							makePostFunctionality
								? 'scrollableMakePostDiv'
								: adminFunctionality
								? 'scrollableAdminDiv'
								: searchGroup === 'followers' || searchGroup === 'following'
								? 'scrollableFollowersDiv'
								: 'scrollableDiv'
						}
						endMessage={
							queriedUsers.length > 10 &&
							queriedUsers.length % 5 !== 0 && (
								<CustomAlerts info={true} message={t('no_more_results')} />
							)
						}
					>
						{userResults.map((user, index) => {
							const bool = user.userId === userId;
							const dataUserId = bool ? userId : user.userId;
							const dataHandle = bool ? handle : user.handle;
							const isAdmin = bool ? admin : user.admin;
							const dataName = bool ? name : user.name;
							const imgUrl = bool ? imageUrl : user.imageUrl;
							const thumb = bool ? thumbnail : user.thumbnail;
							const imgColor = bool ? imageColor : user.imageColor;

							return (
								<ul key={index} className='ul_list'>
									<li
										className={`
										${user.disabled ? 'flex center pd iBQuery' : 'flex center pd'}
										${notificationBackgroundColor}
												`}
									>
										{adminFunctionality ? (
											<div className='followList spaceBetween'>
												<div
													className='flex'
													style={{ width: '60%' }}
													onClick={() => setRecipient(user)}
												>
													<ImgSrc
														handle={dataHandle}
														img={imgUrl}
														thumbnail={thumb}
														imgColor={imgColor}
														css='mediaImg'
														fontSize='20px'
													/>
													<div className='listItemText notranslate'>
														<span className='listItemSpan'>{dataHandle}</span>
														{dataName && (
															<p className='mg0 capitalize'>{dataName}</p>
														)}
													</div>
												</div>
												<CopyText frase='ID:' info={true} text={dataUserId} />
											</div>
										) : makePostFunctionality ? (
											<div
												className='followList'
												onClick={() =>
													this.addAndRemoveRecipient(
														dataUserId,
														dataHandle,
														dataName,
														imgUrl,
														imgColor,
													)
												}
											>
												<ImgSrc
													handle={dataHandle}
													img={imgUrl}
													thumbnail={thumbnail}
													imgColor={imgColor}
													css='mediaImg'
													fontSize='20px'
												/>
												<div className='listItemText notranslate'>
													<div className='flex alignItemsCenter'>
														<p className='listItemSpan mg0'>{dataHandle}</p>
														{isAdmin && <VerifiedUserIcon fontSize='small' />}
													</div>
													{dataName && (
														<p className='mg0 capitalize'>{dataName}</p>
													)}
												</div>
												{recipientArray.findIndex(
													(recipient) => recipient.handle === dataHandle,
												) === -1 ? (
													<span className='new s_resetInput bold underline'>
														{handle === dataHandle
															? t('you_will_be_notified')
															: t('add')}
													</span>
												) : (
													<span className='new s_resetInput bold underline red'>
														{t('remove')}
													</span>
												)}
											</div>
										) : (
											<div className='followList'>
												<div
													onClick={() =>
														this.travelUser(
															dataHandle,
															user.disabled,
															dataUserId,
														)
													}
													className='followList'
												>
													<ImgSrc
														handle={dataHandle}
														img={imgUrl}
														thumbnail={thumb}
														imgColor={imgColor}
														css='mediaImg'
														fontSize='20px'
													/>
													<div className='listItemText notranslate'>
														<span className='listItemSpan flex alignItemsCenter'>
															{dataHandle}{' '}
															{isAdmin && (
																<VerifiedUserIcon
																	className='mglH'
																	fontSize='small'
																/>
															)}
														</span>
														{dataName && (
															<p className='mg0 capitalize'>{dataName}</p>
														)}
													</div>
												</div>
												<div onClick={this.handleClose}>
													<FollowButton
														userHandle={dataHandle}
														userId={dataUserId}
														navbar={navbar || navMobile}
													/>
												</div>
											</div>
										)}
									</li>
									{index !== userResults.length - 1 && <hr className='mg0' />}
								</ul>
							);
						})}
					</InfiniteScroll>
					{loadingSearchbar && (
						<div>
							{userResults.length > 0 && <hr className='mg0' />}
							<QueryUsersSkeleton number={1} showHr />
						</div>
					)}
					<CustomAlerts info={errorQueryingMoreUsers} />

					{queriedUsers.length > 0 &&
					queriedUsers.length % 5 === 0 &&
					!viewResults ? (
						<button
							className='moreResultsBtn pointer'
							onClick={() => this.viewAllResults(lastQueriedUserId)}
						>
							{t('view_all_results')}
						</button>
					) : null}
				</div>
			) : null
		) : (
			<Navigate to='/Login' />
		);

		let dialogActions = (
			<section className='pd'>
				<CustomInput
					variant='outlined'
					// titleLabel={errorMP && 'Please type in a recipient'}
					placeholder={t('type_username')}
					name='searchInput'
					startIconType='search'
					inputId='searchbar'
					required={true}
					onChange={this.handleChange}
					helperText={
						errorQueryingUsers === 'type_a_username' && t('type_a_username')
					}
					inputError={errorMP || errorQueryingUsers === 'type_a_username'}
					inputValue={searchInput}
					inputAdornmentPosition={searchInput === '' ? '' : 'end'}
					maxLength={15}
					fullWidth
					iconFunction={() => this.setState({ recipientEmail: '' })}
					disabled={disabledSearch}
					focus={focus}
					deletable={this.resetInput}
				/>
			</section>
		);

		return (
			<Fragment>
				{open && (
					<Helmet>
						<title>Search - Goodbye App</title>
					</Helmet>
				)}
				{adminFunctionality ||
				makePostFunctionality ||
				searchGroup !== undefined ? (
					<div>
						{dialogActions}
						{queryUsersMarkup}
					</div>
				) : (
					<Fragment>
						{navMobile ? (
							<BottomNavigationAction
								value='searchbar'
								onClick={this.handleOpen}
								id='navMobileAction'
								icon={<SearchIcon className='white' />}
							/>
						) : (
							<span
								onClick={this.handleOpen}
								className='flexColumn underlineHover nav-links-user'
							>
								{authenticated && navbar ? (
									<CustomTooltip title={t('search')} arrow>
										<IconButton
											aria-owns={true ? 'simple-menu' : undefined}
											aria-haspopup='true'
											color='primary'
											id='mgX'
										>
											<SearchIcon className='white' />
										</IconButton>
									</CustomTooltip>
								) : (
									<SearchIcon />
								)}
								{!emailVerified && t('search')}
							</span>
						)}
						<Media
							queries={{
								isMobile: '(max-width: 1064px)',
							}}
						>
							{(matches) => (
								<Dialog
									fullWidth
									maxWidth={!matches.isMobile ? 'xl' : false}
									fullScreen={matches.isMobile}
									style={{ zIndex: '4' }}
									open={open}
									keepMounted={true}
									onClose={this.handleClose}
								>
									<Toolbar id='dialogBar' style={{ minWidth: '60vw' }}>
										<div className='titleToolbar'>{t('search_users')}</div>
										<CloseIcon
											id='closeDialogButton'
											onClick={this.handleClose}
											aria-label='close'
										/>
									</Toolbar>
									{dialogActions}
									<DialogContent sx={{ padding: 0 }} id='scrollableDiv'>
										{queryUsersMarkup}
									</DialogContent>
								</Dialog>
							)}
						</Media>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

Searchbar.propTypes = {
	queryUsers: PropTypes.func.isRequired,
	// queryMoreUsers: PropTypes.func.isRequired,
	clearQueriedUsers: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	queriedUsers: PropTypes.array,
	clearQueriedUsersErrors: PropTypes.func.isRequired,
	addRecipients: PropTypes.func.isRequired,
	deleteRecipients: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
	loadSearchbar: PropTypes.func.isRequired,
	errSearchbar: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	queryUsers,
	// queryMoreUsers,
	clearQueriedUsers,
	clearQueriedUsersErrors,
	addRecipients,
	deleteRecipients,
	setOpenedDialog,
	loadSearchbar,
	errSearchbar,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(Searchbar));
