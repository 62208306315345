import { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	CircularProgress,
	Toolbar,
	Grow,
	Alert,
	DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { getLikes, scrollToTopFunc } from '../../redux/actions/dataActions';
import ImgSrc from './ImgSrc';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FollowButton from './FollowButton';
import QueryUsersSkeleton from '../Skeletons/QueryUsersSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withRouter } from '../../redux/withRouter';

import PullToRefresh from 'react-simple-pull-to-refresh';
import Media from 'react-media';
import { t } from 'i18next';
import CustomAlerts from './CustomAlerts';

class Likes extends Component {
	state = {
		showLikes: false,
		oldPath: '',
		newPath: '',
		likeKey: '',
		onlyLiked: false,
	};
	componentDidMount() {
		this.getLikesFunc();
	}

	getMoreLikes = (key) => {
		if (this.state.likeKey !== key) {
			let post = this.props.data.post;
			let likeCount = post.likeCount;
			let postLikes = post.likes.length;
			let diff = likeCount - postLikes;
			let limit = diff > 10 ? 10 : diff;
			let postId = this.props.location.state?.postId;
			let commentId = this.props.location.state?.commentId;
			this.setState({ likeKey: key });
			this.props.getLikes({
				key: key,
				postId: postId,
				commentId: commentId,
				limit: limit,
			});
		}
	};
	getLikesFunc = async (refresh) => {
		let likeCount = this.props.location.state?.limit;
		let limit =
			likeCount === undefined || refresh ? 10 : likeCount > 10 ? 10 : likeCount;
		this.setState({
			showLikes: true,
		});
		let postId = this.props.location.state?.postId;
		let commentId = this.props.location.state?.commentId;
		if (this.props.location.state?.onlyLiked) {
			this.setState({ onlyLiked: true });
		}
		await this.props.getLikes({
			postId: postId,
			commentId: commentId,
			limit: limit,
		});
	};

	hideLikes = () => {
		let obj = {};
		const { pathname } = this.props.location;
		let split = pathname.split('/');
		let splicedStr = split.slice(0, split.length - 1).join('/');
		obj.pathname = splicedStr;

		this.props.history(splicedStr, {
			state: { onlyLiked: this.state.onlyLiked },
		});
		this.setState({ showLikes: false, likeKey: '' });
	};

	hideLikesAndPostDialog = (userId, userHandle) => {
		this.setState({ showLikes: false, likeKey: '' });
		this.props.history(`/users/${userHandle}`, {
			state: { data: userId, onlyLiked: this.state.onlyLiked },
		});
		scrollToTopFunc('instant');
	};

	render() {
		const { showLikes } = this.state;
		const {
			data: {
				loadingLikes,
				errorLoadingLikes,
				loadingMoreLikes,
				errorLoadingMoreLikes,
				post: { likes },
			},
			credentials: {
				userId,
				handle,
				imageUrl,
				name,
				admin,
				thumbnail,
				imageColor,
				followingUserIds,
			},
			UI: { mode },
		} = this.props;
		const notificationBackgroundColor =
			mode === 'light' ? 'notificationReadLight' : 'notificationReadDark';
		let remainingLikeCount =
			this.props.location.state?.limit >= 10
				? 10
				: this.props.location.state?.limit;

		let likesList = errorLoadingLikes ? (
			<CustomAlerts error={errorLoadingLikes} noMargin />
		) : likes === undefined || loadingLikes ? (
			<div>
				<QueryUsersSkeleton fulline number={remainingLikeCount} showHr />
				{remainingLikeCount === 10 && (
					<div id='circularProgressQuery'>
						<CircularProgress
							variant='indeterminate'
							id='queryUserAuthProgress'
							size={30}
							thickness={3}
						/>
					</div>
				)}
			</div>
		) : likes && likes.length > 0 ? (
			<InfiniteScroll
				dataLength={likes.length} //This is important field to render the next data
				scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
				next={() => this.getMoreLikes(likes[likes.length - 1].createdAt)}
				hasMore={likes.length % 10 === 0 && !errorLoadingMoreLikes}
				scrollableTarget='scrollableLikesDiv'
				loader={
					loadingMoreLikes && (
						<div>
							<hr className='mg0' />
							<QueryUsersSkeleton fulline number={remainingLikeCount} showHr />
							<div id='circularProgressQuery'>
								<CircularProgress
									variant='indeterminate'
									id='queryUserAuthProgress'
									size={30}
									thickness={3}
								/>
							</div>
						</div>
					)
				}
			>
				{likes.map((like, index) => {
					const bool = like.userId === userId;
					const imgUrl = bool ? imageUrl : like.userImage;
					const imgColor = bool ? imageColor : like.userImageColor;
					const thumbUrl = bool ? thumbnail : like.userThumbnail;
					const dataUserId = bool ? userId : like.userId;
					const dataHandle = bool ? handle : like.userHandle;
					const isAdmin = bool ? admin : like.admin;
					const dataName = bool ? name : like.name;
					return (
						<div key={dataUserId}>
							<li
								className={`followList spaceBetween pd ${notificationBackgroundColor}`}
							>
								<div
									className={`flex ${
										followingUserIds && followingUserIds.includes(dataUserId)
											? 'fullWidth'
											: 'width70'
									}`}
									onClick={() =>
										this.hideLikesAndPostDialog(dataUserId, dataHandle)
									}
								>
									<ImgSrc
										img={imgUrl}
										thumbnail={thumbUrl}
										imgColor={imgColor}
										handle={dataHandle}
										userId={dataUserId}
										css='mediaImg'
										fontSize='20px'
									/>
									<div className='listItemText notranslate'>
										<div className='flex alignItemsCenter'>
											<p className='listItemSpan mg0'>{dataHandle}</p>
											{isAdmin && <VerifiedUserIcon fontSize='small' />}
										</div>
										{dataName && <p className='mg0'>{dataName}</p>}
									</div>
								</div>
								<FollowButton
									isFollowingBackBool={like.isFollowingBack}
									userHandle={dataHandle}
									userId={dataUserId}
								/>
							</li>
							{likes.length - 1 !== index && <hr className='mg0' />}
						</div>
					);
				})}
			</InfiniteScroll>
		) : (
			<Grow
				in={likes.length === 0}
				style={{ transformOrigin: 'bottom' }}
				{...(likes.length === 0 ? { timeout: 800 } : {})}
			>
				<Alert id='muiAlert' severity='info'>
					{t('like_first')}
				</Alert>
			</Grow>
		);

		return (
			<div>
				{showLikes && (
					<Media
						queries={{
							isMobile: '(max-width: 1064px)',
						}}
					>
						{(matches) => (
							<Dialog
								disableEnforceFocus
								open={true}
								onClose={this.hideLikes}
								style={{ zIndex: '6' }}
								fullWidth
								maxWidth={!matches.isMobile ? 'md' : false}
								fullScreen={matches.isMobile}
							>
								<Toolbar id='dialogBar'>
									<div className='titleToolbar notranslate'>
										{/* {this.props.location.state?.author}'s{' '} */}
										{/* {this.props.location.state?.repliedId
									? 'reply'
									: this.props.location.state?.commentId
									? 'comment'
									: 'post'}{' '} */}
										{t('likes')}
									</div>

									<CloseIcon
										id='closeDialogButton'
										onClick={this.hideLikes}
										aria-label='close'
									/>
								</Toolbar>
								<DialogContent id='scrollableLikesDiv' className='pd0Dialog'>
									<div>
										<PullToRefresh
											onRefresh={() => this.getLikesFunc(true)}
											isPullable={!loadingLikes || !loadingMoreLikes}
											pullDownThreshold={50}
											resistance={1}
											refreshingContent={
												<div id='circularProgressQuery'>
													<CircularProgress
														variant='indeterminate'
														id='queryUserAuthProgress'
														size={30}
														thickness={3}
													/>
												</div>
											}
											pullingContent={
												<h3 className='center' id='mgt'>
													{t('pull_to_refresh')}
												</h3>
											}
										>
											<ul className='ul_list'>{likesList}</ul>
										</PullToRefresh>
									</div>
								</DialogContent>
							</Dialog>
						)}
					</Media>
				)}
			</div>
		);
	}
}

Likes.propTypes = {
	getLikes: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	credentials: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	credentials: state.user.credentials,
	UI: state.UI,
});

const mapActionsToProps = {
	getLikes,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Likes));
