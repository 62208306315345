import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	deleteComment,
	getMoreComments,
	getMoreCommentsAdmin,
	fetchOnlyUserComments,
	fetchMoreOnlyUserCommentsAdmin,
	getOnlyPostComments,
	clearLoadingCommentsErrors,
	clearLoadingPostCommentsErrors,
	clearLoadingCommentRepliesErrors,
	getCommentReplies,
	getCommentRepliesAdmin,
	fetchUserLikedData,
	resetUserLikedData,
} from '../../redux/actions/dataActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@mui/material';
import QueryCommentsSkeleton from '../Skeletons/QueryCommentsSkeleton';
import Comment from './Comment';
import CustomAlerts from './CustomAlerts';
import DottedMenu from './DottedMenu';
import { withRouter } from '../../redux/withRouter';
import Appeal from './Appeal';
import { t } from 'i18next';

class PostComments extends Component {
	state = {
		viewMoreComments: false,
		lastCommentId: '',
		lastCommentKeyDelete: '',
		noMoreCalls: false,
		noComponentDidUpdate: false,
		lastCommentKey: '',
		queryCommentsOrder: 'desc',
		orderFromParams: false,
	};
	componentDidMount() {
		if (this.props.replies && this.props.params.repliedId) {
			this.setState({ orderFromParams: true });
		} else if (this.props.params.repliedId) {
			this.setState({ queryCommentsOrder: 'desc' });
		}
	}

	delete = (commentId, postId, infringement, repliedId, replyCount) => {
		if (this.props.comments.length - 1 === 0) {
			// if i delete last comment and then I press to view more comments, I quicly save last view comment created at for viewMoreCom function and then delete comment
			let lastCommentId =
				this.props.comments[this.props.comments.length - 1].commentId;
			this.setState({ lastCommentKeyDelete: lastCommentId });
		}
		if (this.props.params.paramsCommentId) {
			this.removeLastParamFromCurrentURL();
		}

		this.props.deleteComment({
			postId,
			commentId,
			livePost: this.props.livePost,
			commentInfringements: infringement,
			repliedId,
		});
	};
	viewMoreCom = (lastCommentKey, lastCommentId, commentId, key) => {
		// view more comments span - button
		if (this.props.replies) {
			let formData = {
				commentId:
					this.props.comments[this.props.comments.length - 1].repliedId,
				commentCollection: 'comments',
				// limit: 10,
				limit: this.props.commentCount - this.props.comments.length,
				order: this.state.queryCommentsOrder,
				key: this.props.comments[this.props.comments.length - 1].createdAt,
			};
			this.props.getCommentReplies(formData);
		} else if (
			this.props.comments.length === 0 &&
			this.props.commentCount - this.props.comments.length > 0 &&
			lastCommentKey === undefined
		) {
			this.getComments(this.state.lastCommentKeyDelete, lastCommentId);
			this.setState({ viewMoreComments: true });
		} else {
			// get comment from last key in comments array
			this.setState({ viewMoreComments: true });
			this.getComments(lastCommentKey, lastCommentId);
		}
	};

	getComments = (lastCommentKey, lastCommentId, withLimit) => {
		// get more
		// comment count is the post comments count
		let commentCount = this.props.commentCount;
		// comments in array are the comments stored in redux
		let commentsInArray = this.props.comments.length;
		let lastLimit = commentCount - commentsInArray;
		let adminPanel = this.props.user.adminPanel;
		// if I only have to fetch less than 10 comments..

		if (adminPanel.adminPanelActive && adminPanel.queryBy === 'user') {
			this.props.fetchMoreOnlyUserCommentsAdmin({
				key: lastCommentKey,
				userId: this.props.comments[0].userId,
				commentCollection: this.props.subCollection
					? this.props.subCollection
					: 'comments',
				order: adminPanel.order,
			});
		} else if (
			lastLimit < 10 &&
			this.state.noMoreCalls === false &&
			this.props.comments.length > 0
		) {
			if (adminPanel.adminPanelActive) {
				this.props.getMoreCommentsAdmin({
					key: lastCommentKey,
					limit: withLimit ? lastLimit : 10,
					// limit: lastLimit,
					order: adminPanel.order,
					postId: this.props.postId,
					commentCollection: this.props.subCollection
						? this.props.subCollection
						: 'comments',
				});
			} else {
				this.props.getMoreComments({
					key: lastCommentKey,
					limit: withLimit ? lastLimit : 10,
					// limit: lastLimit,
					order: this.state.queryCommentsOrder,
					postId: this.props.postId,
				});
			}
		} else if (
			lastLimit < 10 &&
			this.state.noMoreCalls === false &&
			this.props.data.post.comments.length === 0
		) {
			let formData = {
				order: this.state.queryCommentsOrder,
				postId: this.props.data.post.postId,
				limit: lastLimit,
			};

			this.props.getOnlyPostComments(formData);
		} else if (
			lastLimit >= 10
			// checking not to cause infinite loop by repeatedly calling after same key
		) {
			if (adminPanel.adminPanelActive) {
				this.props.getMoreCommentsAdmin({
					key: lastCommentKey,
					limit: 10,
					order: adminPanel.order,
					postId: this.props.postId,
					commentCollection: this.props.subCollection
						? this.props.subCollection
						: 'comments',
				});
			} else {
				this.props.getMoreComments({
					key: lastCommentKey,
					limit: 10,
					order: this.state.queryCommentsOrder,
					postId: this.props.postId,
				});
			}
		} else if (this.props.onlyComments) {
			this.props.fetchOnlyUserComments({
				key: lastCommentKey,
				order: this.state.queryCommentsOrder,
			});
		} else if (this.props.onlyLiked) {
			this.props.fetchUserLikedData({
				key: lastCommentKey,
				order: this.state.queryCommentsOrder,
				sortBy: this.props.sortBy,
			});
		}
		this.setState({ lastCommentId: lastCommentId });
	};

	componentDidUpdate(prevProps, prevState) {
		// If i click to see comment from notification and then I continue loading in asc order I get to a point where there is no longer comments at the end but they are at the beggining-. For this reason there arent comments found in database after x comment buy there still are comments createdAt before X comments and I know this thanks to Post commentCount number
		// if I get error and post commentCount is greater than comments in my redux array and !noMoreCalls and !noCompZonentDidUpdate
		let commentCount = this.props.commentCount;
		let commentsInArray = this.props.comments.length;
		let lastLimit = commentCount - commentsInArray;

		if (
			this.props.commentCount > this.props.comments.length &&
			this.state.noMoreCalls === false &&
			this.state.noComponentDidUpdate === false
		) {
			if (this.props.data.errorLoadingMoreComments === 'no_more_comments') {
				// 'no more comments found...'
				// So after error of no more comments but there are still comments before, I pass empty string to cloud function which will fetch the first comment of post and since the limit of comment I need to fetch is less than 10 I no longer require more calls from infiniteScrolling component.

				if (lastLimit < 10) {
					// console.log('derived last limit');
					this.setState({ noMoreCalls: true });
				} else {
					// there are still more comments to fetch than 10 but I dont want to keep calling componentDidUpdate from new props so I set to false and set commentCreatedKey
					this.setState({
						noComponentDidUpdate: true,
					});
				}
				this.getComments('', '', true);
			}

			if (
				this.props.replies &&
				this.props.data.errorLoadingCommentReplies.includes(
					this.props.commentId,
				) &&
				!this.props.data.loadingCommentReplies.includes(this.props.commentId) &&
				this.props.commentCount > this.props.comments.length &&
				this.state.noMoreCalls === false &&
				this.state.noComponentDidUpdate === false
				// &&this.viewMoreComments
			) {
				let formData = {
					commentId: this.props.commentId,
					commentCollection: 'comments',
					limit: lastLimit > 10 ? 10 : lastLimit,
					order: 'asc',
				};
				console.log('no more replies found!');

				if (lastLimit < 10) {
					// console.log('derived last limit');
					this.setState({ noMoreCalls: true });
				} else {
					// there are still more comments to fetch than 10 but I dont want to keep calling componentDidUpdate from new props so I set to false and set commentCreatedKey
					this.setState({
						noComponentDidUpdate: true,
					});
				}

				this.props.getCommentReplies(formData, true);
			}
		}

		if (
			this.props.data.deletingComments !== '' &&
			this.props.comments.length === 10 &&
			commentCount > 10 &&
			!this.props.data.loadingPost &&
			!this.props.data.loadingPostComments &&
			!this.props.data.loadingMoreComments &&
			this.props.comments[this.props.comments.length - 1].commentId !==
				this.state.lastCommentId &&
			this.state.lastCommentId !== ''
		) {
			this.setState({
				lastCommentId:
					this.props.comments[this.props.comments.length - 1].commentId,
			});

			this.props.getMoreComments({
				key: this.props.comments[this.props.comments.length - 1].createdAt,
				limit: lastLimit,
				order: this.state.queryCommentsOrder,
				postId: this.props.postId,
			});
		}
	}

	handleChangeQueryOrder = (order, commentId) => {
		if (
			order !== this.state.queryCommentsOrder ||
			(this.state.orderFromParams && order === this.state.queryCommentsOrder)
		) {
			// let limit =
			// 	this.props.data.post.commentCount -
			// 	this.props.data.post.comments.length;
			// if (this.props.data.post.commentCount > 0 && limit === 0) {
			// 	limit = 10;
			// }
			let formData;

			if (this.props.replies) {
				formData = {
					commentId: '',
					commentCollection: 'comments',
					limit: 10,
					// limit: this.props.commentCount >= 10 ? 10 : this.props.commentCount,
					order: order,
				};
				if (commentId) {
					formData.commentId = this.props.params.paramsCommentId;
					formData.repliedId = commentId;
					this.setState({ orderFromParams: true });
				} else {
					formData.commentId =
						this.props.comments[this.props.comments.length - 1].repliedId;
					this.setState({ orderFromParams: false });
				}
				this.props.getCommentReplies(formData, false, 'changeOrder');
			} else if (this.props.onlyComments) {
				this.props.fetchOnlyUserComments({ order: order }, 'changeOrder');
			} else if (this.props.onlyLiked) {
				this.props.fetchUserLikedData({
					sortBy: this.props.sortBy,
					order: order,
				});
				this.props.resetUserLikedData();
			} else {
				formData = {
					postId: this.props.data.post.postId,
					order: order,
					limit:
						this.props.data.post.commentCount >= 10
							? 10
							: this.props.data.post.commentCount,
				};
				if (commentId) {
					formData.commentId = commentId;
					this.setState({ orderFromParams: true });
				} else {
					this.setState({ orderFromParams: false });
				}
				this.props.getOnlyPostComments(formData);
			}
			this.setState({ queryCommentsOrder: order, noMoreCalls: false });

			if (!this.props.replies && this.props.params.paramsCommentId) {
				this.removeLastParamFromCurrentURL();
			}
		}
	};
	removeLastParamFromCurrentURL = () => {
		let urlPath = this.props.location.pathname.split('/').slice(0, 5).join('/');
		const paramsState = { ...this.props.history.params };
		delete paramsState.paramsCommentId;
		this.props.history(urlPath, {
			state: paramsState,
		});
	};
	retryReplies = (collection) => {
		let formData = {
			commentCollection: collection,
			limit: 0,
			repliedId: this.props.data.adminReportCommentId,
			commentId: this.props.data.adminReportRepliedId,
			order: 'asc',
		};
		this.props.getCommentRepliesAdmin(formData);
	};
	componentWillUnmount() {
		if (
			this.props.data.errorLoadingMoreComments
			// ||this.props.data.loadingCommentReplies
		) {
			this.props.clearLoadingCommentsErrors();
		}
		if (this.props.data.errorLoadingPostComments) {
			this.props.clearLoadingPostCommentsErrors();
		}
		if (
			!this.props.data.loadingCommentReplies.includes(this.props.commentId) &&
			this.props.data.errorLoadingCommentReplies.includes(
				this.props.params.paramsCommentId,
			)
		) {
			this.props.clearLoadingCommentRepliesErrors();
		}
	}
	render() {
		const {
			user: {
				credentials: { userId },
				adminPanel: { adminPanelActive, panel, queryBy },
				// user signed in
			},
			data: {
				loadingMoreComments,
				loadingNewCommentOrder,
				deletingComments,
				errorLoadingMoreComments,
				loadingPost,
				loadingPostComments,
				errorLoadingPostComments,
				loadingCommentReplies,
				errorLoadingCommentReplies,
				loadingOnlyComments,
				onlyUserComments,
				errorLoadingOnlyComments,
				loadingLikedCommentData,
				likedCommentData,
				errorLoadingLikedCommentData,
				loadingLikedReplyData,
				likedReplyData,
				errorLoadingLikedReplyData,
				submittingComments,
			},
			commentCount,
			comments,
			replies,
			paramsCommentId,
			subCollection,
			onlyComments,
			onlyLiked,
			commentId,
			livePost,
			sortBy,
			notFound,
		} = this.props;
		const {
			viewMoreComments,
			noMoreCalls,
			queryCommentsOrder,
			orderFromParams,
		} = this.state;
		const remainingCommentCount =
			(onlyComments || onlyLiked) &&
			(loadingOnlyComments ||
				loadingLikedCommentData ||
				loadingLikedReplyData ||
				loadingNewCommentOrder)
				? 1
				: (loadingNewCommentOrder &&
						paramsCommentId &&
						!loadingCommentReplies.includes(paramsCommentId)) ||
				  loadingPostComments
				? commentCount
				: commentCount - (comments ? comments.length : 0);

		const viewCommentLogic =
			remainingCommentCount === 1
				? `View ${remainingCommentCount} more ${replies ? 'reply' : 'comment'}`
				: `View ${remainingCommentCount} more ${
						replies ? 'replies' : 'comments'
				  }`;

		let lastCommentKey = '';
		let lastCommentId = '';
		if (this.props.comments.length > 0) {
			let lastComment = this.props.comments[this.props.comments.length - 1];
			lastCommentId = lastComment.commentId;
			lastCommentKey = onlyLiked ? lastComment.key : lastComment.createdAt;
		}
		const error = onlyComments
			? errorLoadingOnlyComments
			: onlyLiked && sortBy === 'comment'
			? errorLoadingLikedCommentData
			: onlyLiked && sortBy === 'reply'
			? errorLoadingLikedReplyData
			: errorLoadingPostComments;

		return (
			<div
				className={
					!replies &&
					((submittingComments.length > 0 && commentCount === 0) ||
						commentCount > 0)
						? 'pdbH'
						: ''
				}
			>
				{loadingPostComments ||
				(loadingOnlyComments && onlyUserComments.length === 0) ||
				(loadingLikedCommentData && likedCommentData.length === 0) ||
				(loadingLikedReplyData && likedReplyData.length === 0) ||
				((loadingPost || loadingCommentReplies.includes(commentId)) &&
					commentCount > 0 &&
					comments.length === 0) ||
				(loadingNewCommentOrder && (replies || onlyComments || onlyLiked)) ? (
					<div className='loadingPostComments'>
						<QueryCommentsSkeleton
							fulline
							number={
								replies && this.props.params.repliedId
									? 1
									: remainingCommentCount >= 10
									? 10
									: remainingCommentCount > 0
									? remainingCommentCount
									: comments && comments.length > 0
									? comments.length
									: 1
							}
							onlyComments={onlyComments || onlyLiked}
							replies={replies}
						/>
						{remainingCommentCount === 10 && (
							<div id='circularProgressQuery'>
								<CircularProgress
									variant='indeterminate'
									id='queryUserAuthProgress'
									size={30}
									thickness={3}
								/>
							</div>
						)}
					</div>
				) : (comments && comments?.length > 0) ||
				  (comments?.length === 0 && remainingCommentCount > 0) ? (
					<div className={onlyComments || onlyLiked ? 'pdb' : ''}>
						{!replies && comments.length > 1 && !notFound ? (
							<div
								className={`orderCommentsIcon  ${
									onlyComments || onlyLiked
										? 'mgt flex alignItemsCenter mgbH-'
										: 'mgYH-'
								}`}
							>
								<DottedMenu
									queryFromCommentId={
										this.props.queryFromCommentId || this.props.params.repliedId
									}
									orderData={true}
									noTooltip={onlyComments}
									orderFromParams={orderFromParams}
									comment={false}
									replies={replies}
									queryOrder={queryCommentsOrder}
									handleChangeQueryOrder={this.handleChangeQueryOrder}
								/>
								{(onlyComments || onlyLiked) && (
									<span className='mgl4px'>{t('order')}</span>
								)}
							</div>
						) : (
							<div className={onlyComments ? '' : 'mgb-'}></div>
						)}

						<InfiniteScroll
							dataLength={comments.length} //This is important field to render the next data
							scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
							next={() => this.getComments(lastCommentKey, lastCommentId)}
							hasMore={
								(!replies &&
									!loadingPost &&
									!loadingMoreComments &&
									!errorLoadingMoreComments &&
									((viewMoreComments &&
										commentCount - comments.length > 0 &&
										noMoreCalls === false &&
										!errorLoadingMoreComments &&
										!errorLoadingPostComments) ||
										(adminPanelActive &&
											!errorLoadingMoreComments &&
											comments.length % 10 === 0 &&
											!errorLoadingPostComments &&
											!errorLoadingCommentReplies.includes(commentId)) ||
										(commentCount - comments.length > 0 &&
											comments.length % 10 === 0 &&
											!loadingMoreComments))) ||
								(onlyComments && !errorLoadingOnlyComments) ||
								(onlyLiked &&
									(!errorLoadingLikedCommentData ||
										!errorLoadingLikedReplyData))
							}
							scrollableTarget={
								adminPanelActive
									? 'scrollableAdminCommentDiv'
									: onlyLiked || onlyComments
									? 'scrollableOnlyComments'
									: 'scrollableDivComments'
							}
							endMessage={
								adminPanelActive &&
								queryBy === 'postAndComment' &&
								comments.length % 10 !== 0 &&
								remainingCommentCount === 0 && (
									<CustomAlerts info={true} message={t('no_more_results')} />
								)
							}
						>
							{errorLoadingCommentReplies.includes(commentId) ? (
								<div>
									<CustomAlerts error={t('error_loading_replies')} close />
									{adminPanelActive && (
										<div>
											<button onClick={() => this.retryReplies('comments')}>
												Search on Comments Collection
											</button>
											<hr />
											<button
												onClick={() => this.retryReplies('removedComments')}
											>
												Search on Removed Comments Collection
											</button>
										</div>
									)}
								</div>
							) : null}
							<ul className='ul_list' id='pdt'>
								{comments.map((comment, index) => {
									// const { body, createdAt, userImage, userHandle, commentId } =
									// 	comment;
									let commentId = comment.commentId;

									return (
										<div className='fullWidth' key={commentId}>
											<Comment
												notFound={notFound}
												comment={comment}
												paramsCommentId={paramsCommentId}
												pathname={this.props.location.pathname}
												onlyComments={onlyComments}
												onlyLiked={onlyLiked}
												replyComments={replies}
												// closeDialogs={closeDialogs}
												// closeComments={closeComments}
												function={() =>
													this.delete(
														commentId,
														comment.repliedPostId
															? comment.repliedPostId
															: comment.postId,
														comment.infringements,
														comment.repliedId,
														comment.replyCount,
													)
												}
												queryDocId={panel === 'queryReports' ? true : false}
												subCollection={subCollection}
												livePost={livePost}
											/>
											{comment.infringements &&
												!deletingComments.includes(commentId) && (
													<div>
														{comment.userId === userId && !adminPanelActive ? (
															<Appeal
																socialComponent={
																	comment.postId ? 'comment' : 'reply'
																}
																deleteFunction={() =>
																	this.delete(
																		commentId,
																		comment.repliedPostId
																			? comment.repliedPostId
																			: comment.postId,
																		comment.infringements,
																		comment.repliedId,
																		comment.replyCount,
																	)
																}
																commentAuthor={comment.userId}
															/>
														) : null}
													</div>
												)}
										</div>
									);
								})}
								{/* {errorLoadingMoreComments && (
									<CustomAlerts error={t(errorLoadingMoreComments)} />
								)} */}
							</ul>

							{((!replies && loadingMoreComments) ||
								loadingCommentReplies.includes(commentId) ||
								loadingOnlyComments ||
								loadingLikedCommentData ||
								loadingLikedReplyData) && (
								<QueryCommentsSkeleton
									fulline
									moreComments
									number={
										remainingCommentCount >= 10 ? 10 : remainingCommentCount
									}
								/>
							)}
							{!replies && loadingMoreComments && (
								<div id='circularProgressQuery'>
									<CircularProgress
										variant='indeterminate'
										id='queryUserAuthProgress'
										size={30}
										thickness={3}
									/>
								</div>
							)}
							{!loadingCommentReplies.includes(commentId) &&
							!errorLoadingCommentReplies.includes(commentId) &&
							remainingCommentCount > 0 &&
							((!viewMoreComments && !errorLoadingMoreComments) ||
								adminPanelActive) ? (
								<button
									className='moreResultsBtn pointer'
									onClick={() =>
										this.viewMoreCom(lastCommentKey, lastCommentId)
									}
								>
									{viewCommentLogic}
								</button>
							) : null}
							<CustomAlerts noMargin info={error} />
						</InfiniteScroll>
					</div>
				) : null}
			</div>
		);
	}
}

PostComments.propTypes = {
	comments: PropTypes.array,
	UI: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	deleteComment: PropTypes.func.isRequired,
	getOnlyPostComments: PropTypes.func.isRequired,
	fetchUserLikedData: PropTypes.func.isRequired,
	resetUserLikedData: PropTypes.func.isRequired,
	getMoreComments: PropTypes.func.isRequired,
	getMoreCommentsAdmin: PropTypes.func.isRequired,
	fetchOnlyUserComments: PropTypes.func.isRequired,
	fetchMoreOnlyUserCommentsAdmin: PropTypes.func.isRequired,
	clearLoadingCommentsErrors: PropTypes.func.isRequired,
	clearLoadingPostCommentsErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	UI: state.UI,
	user: state.user,
	data: state.data,
	deleteComment: PropTypes.func.isRequired,
	getMoreComments: PropTypes.func.isRequired,
	fetchOnlyUserComments: PropTypes.func.isRequired,
	fetchUserLikedData: PropTypes.func.isRequired,
	resetUserLikedData: PropTypes.func.isRequired,
	getMoreCommentsAdmin: PropTypes.func.isRequired,
	fetchMoreOnlyUserCommentsAdmin: PropTypes.func.isRequired,
	getOnlyPostComments: PropTypes.func.isRequired,
	clearLoadingCommentsErrors: PropTypes.func.isRequired,
	getCommentReplies: PropTypes.func.isRequired,
	getCommentRepliesAdmin: PropTypes.func.isRequired,
	clearLoadingPostCommentsErrors: PropTypes.func.isRequired,
	clearLoadingCommentRepliesErrors: PropTypes.func.isRequired,
});
const mapActionsToProps = {
	deleteComment,
	getMoreComments,
	getMoreCommentsAdmin,
	fetchOnlyUserComments,
	fetchMoreOnlyUserCommentsAdmin,
	getOnlyPostComments,
	clearLoadingCommentsErrors,
	clearLoadingPostCommentsErrors,
	clearLoadingCommentRepliesErrors,
	getCommentReplies,
	getCommentRepliesAdmin,
	fetchUserLikedData,
	resetUserLikedData,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(PostComments));
