import PropTypes from 'prop-types';
// import { Grid, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	submitComment,
	queryUsers,
	clearQueriedUsers,
	clearQueriedUsersErrors,
	queryHashtags,
	setHashtags,
	loadSearchbar,
	loadHashtags,
	numberFormatter,
	dim,
} from '../../redux/actions/dataActions';
import { v4 as uuidv4 } from 'uuid';
import CustomButton from './CustomButton';
import { useCallback, useEffect, useState } from 'react';
import Mentions from 'rc-mentions';
import ImgSrc from '../SocialComponents/ImgSrc';
import { CircularProgress } from '@mui/material';
import CustomAlerts from './CustomAlerts';
import Media from 'react-media';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from 'react-i18next';

const { Option } = Mentions;

function CommentForm({
	postId,
	commentId,
	livePost,
	reply,
	repliedId,
	replyTo,
	replyToId,
	closeReply,
	canMention,
	canHash,
	makePostFocus,
	makePostFunctionality,
	postBodyChange,
	setPostMentions,
	postMentions,
	postHashtags,
	postBody,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const authenticated = useSelector((state) => state.user.authenticated);
	const credentials = useSelector((state) => state.user.credentials);
	const loadingSearchbar = useSelector((state) => state.data.loadingSearchbar);
	const loadingHashtags = useSelector((state) => state.data.loadingHashtags);
	const errorQueryingUsers = useSelector(
		(state) => state.data.errorQueryingUsers,
	);
	const errorQueryingHashtags = useSelector(
		(state) => state.data.errorQueryingHashtags,
	);

	const hashtags = useSelector((state) => state.data.hashtags);
	const queriedUsers = useSelector((state) => state.data.queriedUsers);
	const tagFollowers = useSelector((state) => state.data.tagFollowers);
	const previewHashtags = useSelector((state) => state.data.previewHashtags);

	const [prefix, setPrefix] = useState('@');
	const [mentionPrefix, setMentionPrefix] = useState(['@']);
	const [search, setSearch] = useState('');
	const [body, setBody] = useState('');
	const [errors, setErrors] = useState({});
	const [mentions, setMentions] = useState([]);
	const [somePreviewHashtags, setPreviewHashtags] = useState([]);
	const [taggeableFollowers, setTagFollowers] = useState([]);
	const mode = useSelector((state) => state.UI.mode);
	const darkMode = mode === 'dark';
	const textAreaId =
		makePostFunctionality && darkMode
			? 'darkTextArea_MP'
			: makePostFunctionality
			? 'textArea_MP'
			: darkMode
			? 'darkCommentInput'
			: 'commentInput';

	useEffect(() => {
		let textAreas = document.getElementsByClassName('rc-textarea');
		for (var i = 0; i < textAreas.length; i++) {
			textAreas.item(i).setAttribute('placeholder', `${t('type_here')}`);
			// makePostFunctionality
			// 	? 'Type here'
			// 	: i > 0
			// 	? 'Type here' //reply
			// 	: 'Type here' //comment
			textAreas
				.item(i)
				.setAttribute('maxLength', makePostFunctionality ? 5000 : 1000);
		}

		if (postBody) {
			setBody(postBody);
		}
		if (postMentions) {
			setMentions(postMentions);
		}

		if (replyTo && repliedId) {
			setBody(`@${replyTo} `);
		}

		if (replyTo && replyToId) {
			setMentions([
				{
					handle: replyTo,
					userId: replyToId,
				},
			]);
		}
		if (tagFollowers.length > 0) {
			setTagFollowers(tagFollowers);
		}
		if (previewHashtags.length > 0) {
			setPreviewHashtags(previewHashtags);
		}
		if (canHash) {
			setMentionPrefix(['@', '#']);
		}
		if (!canMention && canHash) {
			setMentionPrefix(['#']);
		}

		return () => {
			if (errorQueryingUsers !== null) {
				dispatch(clearQueriedUsersErrors());
			}
		};
	}, [
		tagFollowers,
		repliedId,
		replyTo,
		replyToId,
		canHash,
		makePostFunctionality,
		// postBody,
		postHashtags,
		postMentions,
		errorQueryingUsers,
		hashtags,
		// dispatch,
	]);

	const addMentions = (obj) => {
		if (
			(makePostFunctionality && mentions.length < 15) ||
			mentions.length < 10
		) {
			if (mentions.findIndex((e) => e.userId === obj.userId) === -1) {
				setMentions(mentions.concat(obj));
				if (setPostMentions) {
					setPostMentions(mentions.concat(obj));
				}
			}
		} else {
			setErrors({
				comment: t('only_tag_users', {
					number: makePostFunctionality ? 15 : 10,
				}),
			});
		}
	};

	const handleSubmit = (textAreaId) => {
		if (body.trim() === '') {
			setErrors({
				comment: t('type_comment_first'),
			});
			document.getElementById(textAreaId).focus();
		} else {
			let formData = {
				postId: postId,
				body: body.trim(),
				postCollection: livePost === false ? 'unreleasedPosts' : 'posts',
			};

			if (commentId) {
				formData.commentId = repliedId ? repliedId : commentId;
			}

			if (mentions.length > 0) {
				var mentionPattern = /\B@[a-z0-9_-]+/gi;
				let matches = body.match(mentionPattern);
				if (matches) {
					let stateMentions = mentions.filter((mention) =>
						matches.includes(`@${mention.handle}`),
					);
					formData.mentions = stateMentions;
					setMentions(stateMentions);
					if (setPostMentions) {
						setPostMentions(stateMentions);
					}
				}
			}

			if (canHash) {
				const hashtagPattern = /(#(?:[^\x00-\x7F]|\w)+)/g;
				let matches = body.match(hashtagPattern);
				if (matches) {
					let tags = [];
					matches.forEach((tag) => {
						if (!tags.includes(tag)) {
							return tags.push({ hashtag: tag.toLowerCase(), count: '' });
						}
					});

					formData.hashtags = tags;
					dispatch(setHashtags(tags));
				}
			}

			if (formData.mentions && formData.mentions.length > 10) {
				setErrors({
					comment: t('only_tag_users', {
						number: 10,
					}),
				});
			} else if (formData.hashtags && formData.hashtags.length > 15) {
				setErrors({
					comment: t('only_15_hashtags'),
				});
			} else {
				dispatch(
					submitComment(formData, {
						createdAt: Date.now(),
						userImage: credentials.imageUrl,
						userImageColor: credentials.imageColor,
						userHandle: credentials.handle,
						userId: credentials.userId,
						commentId: uuidv4(),
					}),
				);
				cancel('showReplies');
			}
		}
	};

	const cancel = (action) => {
		setBody('');
		setErrors({});

		if (closeReply) {
			closeReply(action);
		}
	};

	const setFocus = (bool) => {
		if (bool === false && Object.keys(errors).length > 0) {
			setErrors({
				errors: {},
			});
		}
	};

	const debounce = (callback, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => callback(...args), delay);
		};
	};

	const debouncedLog = useCallback(
		debounce((value) => userQuery(value), 600),
		[],
	);
	const debouncedLogHashtags = useCallback(
		debounce((value) => hashtagQuery(value), 600),
		[],
	);

	const userQuery = (value) => {
		var queryData = {
			search: value,
			collection: 'usernames',
		};

		dispatch(queryUsers(queryData));
	};

	const hashtagQuery = (value) => {
		var queryData = {
			search: value,
		};
		dispatch(queryHashtags(queryData));
	};

	const onChange = (e) => {
		if (postBodyChange) {
			postBodyChange(e);
		}
		setBody(e);
		if (errors.comment) {
			setErrors({});
		}

		if (e === '' && loadingSearchbar) {
			dispatch(clearQueriedUsers());
		}
	};

	const onSearch = (searchVal, prefix) => {
		searchVal = searchVal.toLowerCase();
		if (searchVal === '' && search) {
			setSearch('');
		}
		setPrefix(prefix);
		if (errorQueryingUsers !== null) {
			clearQueriedUsersErrors();
		}
		if (prefix === '@') {
			let regex = /^[A-Za-z0-9]*$/;
			if (mentions.length > 0) {
				const filteredMentions = mentions.filter(
					(user) => user.handle !== searchVal,
				);
				setMentions(filteredMentions);
				if (setPostMentions) {
					setPostMentions(filteredMentions);
				}
			}
			if (searchVal !== '' && regex.test(searchVal)) {
				if (!loadingSearchbar) {
					dispatch(loadSearchbar());
				}

				setSearch(searchVal);
				debouncedLog(searchVal);
			} else {
				clearQueriedUsers();
			}
		}
		if (prefix === '#') {
			if (hashtags.findIndex((tag) => tag.hashtag === searchVal) !== -1) {
				//removing hashtag when retyping!
				let filteredHashtags = hashtags.filter(
					(tag) => tag.hashtag !== searchVal,
				);
				dispatch(setHashtags(filteredHashtags));
			}
			if (searchVal !== '') {
				dispatch(loadHashtags(true));
				setSearch(searchVal);
				debouncedLogHashtags(searchVal);
			}
		}
	};

	const onSelect = (option, prefix) => {
		if (option.value) {
			if (prefix === '@' && option.id !== undefined) {
				addMentions({ handle: option.value, userId: option.id });
				// let cursor = document.getElementById(textAreaId).selectionStart;
				// console.log('cursor:', cursor);
				// const bodyBeforeCursor = body.slice(0, cursor);
				// const bodyAfterCursor = body.slice(cursor);
				// setBody(bodyBeforeCursor + option.value + bodyAfterCursor + ' ');
			}
			if (prefix === '#') {
				// const prohibitedHashtags = [
				// 	'rape',
				// ];
				// if (prohibitedHashtags.includes(option.value.toLowerCase())) {
				// 	console.log('prohibited');
				// } else

				if (hashtags.findIndex((tag) => tag.hashtag === option.value) === -1) {
					// Handle the extra space issue

					dispatch(
						setHashtags([
							...hashtags,
							{
								hashtag: option.value.toLowerCase(),
								count: option.count ? option.count : '',
							},
						]),
					);
				}
			}
			//add cursor logic here.

			setSearch('');
		}
	};

	const disabled =
		(makePostFunctionality && credentials.postsMadeToday >= 30) ||
		(!makePostFunctionality &&
			!reply &&
			credentials.commentsMadeToday >= 200) ||
		(!makePostFunctionality && reply && credentials.repliesMadeToday >= 200);
	let mentionsComponent = (
		<Mentions
			onChange={onChange}
			onSearch={onSearch}
			style={{ width: '100%' }}
			onSelect={onSelect}
			autoSize={true}
			onFocus={() => setFocus(true)}
			autoFocus={reply || makePostFocus}
			prefix={mentionPrefix}
			placement='bottom'
			// direction='rtl'
			value={body}
			id={textAreaId}
			transitionName='motion-zoom'
			disabled={disabled}
		>
			{!search && (
				<Option value={''} key='empty'>
					<p className={`followList  mentionsPadding dark center mg0 `}>
						{prefix === '@' ? t('type_username') : t('type_hashtag')}
					</p>
				</Option>
			)}
			{errorQueryingUsers && prefix === '@' && search !== '' && (
				<Option value={search} key='notFound'>
					<p className={`followList  mentionsPadding dark center mg0 `}>
						"{search}" {t('was_not_found')}
					</p>
				</Option>
			)}
			{canMention &&
				((prefix === '@' && loadingSearchbar) ||
					(prefix === '#' && loadingHashtags)) && (
					<Option value={search ? search : ''} key='loadingUsers'>
						<div className={`followList mentionsPadding dark pd center `}>
							<CircularProgress
								size={20}
								thickness={3}
								sx={{ margin: '5px 0' }}
							/>
						</div>
					</Option>
				)}
			{canMention &&
				prefix === '@' &&
				queriedUsers.map((user, index) => (
					<Option value={user.handle} id={user.userId} key={index}>
						<div className='followList spaceBetween mentionsPadding dark'>
							<div
								className={`flex alignItemsCenter`}
								style={{ width: '100%', margin: '0.5rem 0.5rem' }}
							>
								<ImgSrc
									handle={user.handle}
									img={user.imageUrl}
									imgColor={user.imageColor}
									thumbnail={user.thumbnail}
									css='listCommentImg'
									fontSize='20px'
								/>
								<div>
									<div className='flex alignItemsCenter'>
										<span className='listItemSpan mg0'>{user.handle}</span>
										{user.admin && <VerifiedUserIcon fontSize='small' />}
									</div>

									{user.name && <p className='mg0 capitalize'>{user.name}</p>}
								</div>
							</div>
						</div>
					</Option>
				))}
			{canMention &&
				prefix === '@' &&
				queriedUsers.length === 0 &&
				taggeableFollowers.length > 0 &&
				taggeableFollowers.map((user, index) => (
					<Option value={user.handle} key={index} id={user.userId}>
						<div className='followList spaceBetween mentionsPadding dark'>
							<div
								className='flex alignItemsCenter'
								style={{ width: '100%', margin: '0.5rem 0.5rem' }}
							>
								<ImgSrc
									handle={user.handle}
									img={user.imageUrl}
									imgColor={user.imageColor}
									thumbnail={user.thumbnail}
									css='listCommentImg'
									fontSize='20px'
								/>
								<div className='mg0'>
									<div className='flex alignItemsCenter'>
										<span className='listItemSpan mg0'>{user.handle}</span>
										{user.admin && <VerifiedUserIcon fontSize='small' />}
									</div>
									{user.name && <p className='mg0 capitalize'>{user.name}</p>}
								</div>
							</div>
						</div>
					</Option>
				))}
			{canHash &&
				prefix === '#' &&
				search !== '' &&
				somePreviewHashtags.findIndex((tag) => tag.hashtag === search) === -1 &&
				hashtags.findIndex((tag) => tag.hashtag === search) === -1 && (
					<Option value={search} key='customHash'>
						<div className='followList spaceBetween mentionsPadding dark'>
							<p>#{search}</p>
							{(errorQueryingHashtags === 'hash_404' || !loadingHashtags) && (
								<span>{t('create')}</span>
							)}
						</div>
					</Option>
				)}
			{canHash &&
				prefix === '#' &&
				hashtags.length === 0 &&
				somePreviewHashtags.length > 0 &&
				somePreviewHashtags.map((tag, index) => (
					<Option value={tag.hashtag} id={tag.hashtag} key={index}>
						<div className='followList spaceBetween mentionsPadding dark'>
							<p>#{tag.hashtag}</p>

							<span>
								{tag.count > 0 ? `${numberFormatter(tag.count, 1)} ` : ''}
								{tag.count === 0
									? t('create')
									: tag.count > 1
									? t('posts')
									: tag.count === 1
									? t('post')
									: ''}
							</span>
						</div>
					</Option>
				))}
			{canHash &&
				prefix === '#' &&
				hashtags.length > 0 &&
				hashtags.map((tag, index) => (
					<Option value={tag.hashtag} id={tag.hashtag} key={index}>
						<div className='followList spaceBetween mentionsPadding dark'>
							<p>#{tag.hashtag}</p>
							<span>
								{tag.count > 0 && numberFormatter(tag.count, 1)}{' '}
								{tag.count === 0
									? t('create')
									: tag.count > 1
									? t('posts')
									: tag.count === 1
									? t('post')
									: ''}
							</span>
						</div>
					</Option>
				))}
		</Mentions>
	);

	return authenticated ? (
		<Media
			queries={{
				isMobile: '(max-width: 1064px)',
			}}
		>
			{(matches) => (
				<div>
					<CustomAlerts
						info={errors.comment}
						close
						noMargin
						onClose={() => setErrors({})}
					/>
					<div style={dim(disabled)}>
						{makePostFunctionality ? (
							<div className='center pdCommentForm'>
								{mentionsComponent}
								{body.length > 2499 && (
									<span style={{ float: 'right', marginBottom: '8px' }}>
										{body.length}/5000
									</span>
								)}
							</div>
						) : (
							<div>
								<div
									className={`flex mgrExcept alignItemsCenter ${
										commentId && !repliedId ? 'pdl' : ''
									} `}
								>
									<ImgSrc
										img={credentials.imageUrl}
										imgColor={credentials.imageColor}
										thumbnail={credentials.thumbnail}
										handle={credentials.handle}
										fontSize='20px'
										css={commentId ? 'listReplyImg' : 'listCommentImg'}
									/>

									<div
										className='flex'
										style={{
											width: 'calc(100% - 50px)',
											alignItems: 'center',
										}}
									>
										{mentionsComponent}

										<CustomButton
											onClick={() => handleSubmit(textAreaId)}
											iconButton
											startIcon={<SendIcon sx={{ fontSize: '1.6rem' }} />}
											padding='6px'
											margin='0 2px 0 4px'
											color='inherit'
											disabled={
												body === '' ||
												(makePostFunctionality &&
													credentials.postsMadeToday >= 30) ||
												(!makePostFunctionality &&
													!reply &&
													credentials.commentsMadeToday >= 200) ||
												(!makePostFunctionality &&
													reply &&
													credentials.repliesMadeToday >= 200)
											}
											tooltip={reply ? t('reply') : t('comment')}
										/>
									</div>
								</div>
								{body.length > 499 && (
									<span style={{ float: 'right', margin: '0 40px 0 0' }}>
										{body.length}/1000
									</span>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</Media>
	) : null;
}

CommentForm.propTypes = {
	loadSearchbar: PropTypes.func.isRequired,
	loadHashtags: PropTypes.func.isRequired,
	submitComment: PropTypes.func.isRequired,
	queryUsers: PropTypes.func.isRequired,
	clearQueriedUsersErrors: PropTypes.func.isRequired,
	queryHashtags: PropTypes.func.isRequired,
	setHashtags: PropTypes.func.isRequired,
	UI: PropTypes.object.isRequired,
	credentials: PropTypes.object.isRequired,
	authenticated: PropTypes.bool.isRequired,
};

const mapActionsToProps = {
	submitComment,
	queryUsers,
	clearQueriedUsers,
	clearQueriedUsersErrors,
	queryHashtags,
	setHashtags,
	loadSearchbar,
	loadHashtags,
};
const mapStateToProps = (state) => ({
	UI: state.UI,
	authenticated: state.user.authenticated,
	credentials: state.user.credentials,
	data: state.data,
});

export default connect(mapStateToProps, mapActionsToProps)(CommentForm);
