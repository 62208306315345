import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RefundPolicy() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	return (
		<div>
			<Helmet>
				<title>Refund Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>{t('refund_policy')}</h1>
					<h2>{t('overview')}</h2>
					<b>Effective April 18, 2021</b>
					<p>
						<b>{t('purpose_donations')}</b> {t('purpose_donations_desc')}
					</p>
					<p>
						<b>{t('voluntary_donations')}</b> {t('voluntary_donations_desc')}
					</p>
					<p>{t('refund_thanks')}</p>

					<h2>{t('refund_eligibility')}</h2>
					<p>{t('for_refund_provide')}</p>

					<ol className='text'>
						<li className='bold mgb'>{t('provide_email')}</li>
						<li className='bold mgb'>{t('provide_paypal')}</li>
						<li className='bold mgb'>{t('donation_amount')}</li>
					</ol>
					<h2>{t('refund_request_process')}</h2>

					<ol className='text'>
						<li className='mgb'>
							<b>{t('submit_refund_request')}</b>{' '}
							{t('submit_refund_request_desc')}{' '}
							<Link to='/contact' id='link' className='bold'>
								{t('contact_page')}
							</Link>{' '}
							{t('submit_refund_request_desc_2')}
						</li>
						<li className='mgb'>
							<b>{t('processing_time')}</b> {t('processing_time_desc')}
						</li>
						<li className='mgb'>
							<b>{t('refund_conditions')}</b> {t('refund_conditions_desc')}
						</li>
					</ol>

					<h2>{t('contact_us')}</h2>
					<p>
						{t('contact_us_desc')}{' '}
						<Link to='/contact' id='link' className='bold'>
							{t('contact_page')}
						</Link>
						.
					</p>
					<p>
						<b>{t('note')}</b> {t('note_desc')}
					</p>
					<p>{t('gratitude_coop')}</p>
					<p className='mg0 bold'>
						{t('read_paypal_policy')}{' '}
						<a
							href='https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-refund-policy'
							target='_blank'
							rel='noopener noreferrer'
							className='link underlineHover bold breakWord'
						>
							https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-refund-policy
						</a>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default RefundPolicy;
