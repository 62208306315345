import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';
import { loginUser } from '../../firebase/firebaseInit';
import { withRouter } from '../../redux/withRouter';
import {
	clearNewEmailData,
	clearAccountActionTypes,
	clearDisabledUser,
	getUserData,
	clearAuthErrors,
	clearAuthMessages,
} from '../../redux/actions/userActions';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SecurityIcon from '@mui/icons-material/Security';
import Confetti from '../../components/SocialComponents/Confetti';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import Footer from '../../components/Footer/Footer';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import { isEmail, scrollToTopFunc } from '../../redux/actions/dataActions';
import Maintainance from '../../components/SocialComponents/Maintainance';
import LoginIcon from '@mui/icons-material/Login';
import { t } from 'i18next';
class Login extends Component {
	state = {
		email: '',
		password: '',
		emailErrors: {},
		passwordErrors: {},
		verifiedOobCode: false,
		loading: false,
		signInSet: false,
	};

	componentDidMount() {
		if (document.body.style.overflow) {
			document.body.style.removeProperty('overflow');
		}
		if (
			this.props.user.authenticated &&
			this.props.user.refreshTab &&
			!this.props.user.loadingLogout
		) {
			this.props.getUserData('loadUser');
		}
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (
			nextProps.user.signInEmail &&
			state.email === '' &&
			state.signInSet === false
		) {
			return {
				email: nextProps.user.signInEmail,
				signInSet: true,
			};
		}
		return null;
	}
	componentWillUnmount() {
		if (this.props.user.authErrors) {
			this.props.clearAuthErrors();
		}

		if (
			this.props.user.deletedUserSuccess ||
			this.props.user.deletedUserSuccess ||
			this.props.user.errorVerifyingEmail ||
			this.props.user.verifiedEmailSuccess ||
			this.props.user.signInEmail
		) {
			this.props.clearAuthMessages();
		}
	}
	onRecaptchaResolved = (value) => {
		this.recaptcha.reset();
		let userEmailOrHandle;
		let isEmailBool = isEmail(this.state.email);

		if (!isEmailBool && !this.props.user.currentEmailForSignIn) {
			userEmailOrHandle = this.state.email.toLowerCase();
			isEmailBool = false;
		} else {
			userEmailOrHandle = this.state.email;
			isEmailBool = true;
		}
		const userData = {
			email: userEmailOrHandle,
			password: this.state.password,
			responseToken: value,
			isEmail: isEmailBool,
		};
		if (this.props.user.newEmailForSignIn) {
			userData.email = this.props.user.currentEmailForSignIn;
			userData.newEmailForSignIn = this.props.user.newEmailForSignIn;
			userData.currentEmailForSignIn = this.props.user.currentEmailForSignIn;
		}
		if (this.props.user.deleteAccount) {
			userData.deleteAccount = this.props.user.deleteAccount;
		}
		this.props.loginUser(userData);
		this.setState({ loading: false });
	};

	handleSubmit = (event) => {
		this.recaptcha.reset();
		event.preventDefault();
		if (this.props.user.authErrors) {
			this.props.clearAuthErrors();
		}
		if (this.testEmail() && this.testPass()) {
			scrollToTopFunc();
			this.setState({ loading: true });
			this.recaptcha.execute();
			setTimeout(() => this.setState({ loading: false }), 2000);
		}
	};

	handleChangeUserAndMail = (event) => {
		this.setState({
			[event.target.name]: event.target.value.trim(),
		});
	};
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	testEmail = () => {
		if (
			this.state.email.trim() === '' &&
			!this.props.user.currentEmailForSignIn
		) {
			this.setState({
				emailErrors: {
					email: t('email_error_enter_address'),
				},
			});
		} else if (
			!isEmail(this.state.email) &&
			!this.props.user.currentEmailForSignIn
		) {
			this.setState({
				emailErrors: {
					email: t('email_error_invalid_address'),
				},
			});
		} else {
			this.setState({
				emailErrors: { email: '' },
			});
			return true;
		}
	};
	testPass = () => {
		if (this.state.password.trim() === '') {
			this.setState({
				passwordErrors: {
					password: t('enter_password'),
				},
			});
		} else {
			this.setState({
				passwordErrors: { password: '' },
			});
			return true;
		}
	};
	testClearPassError = () => {
		this.setState({
			passwordErrors: { password: '' },
		});
	};

	render() {
		const {
			user: {
				authenticated,
				emailVerified,
				loadingLogin,
				loadingUser,
				disabledUser: { disabledMessage, appeal, appealable, disabledFor },
				authErrors,
				verifyingEmail,
				verifiedEmailSuccess,
				errorVerifyingEmail,
				sendingResetPasswordSuccess,
				newEmailForSignIn,
				deleteAccount,
				deletingUserData,
				errorDeletingUserData,
				deletedUserSuccess,
				signInEmail,
				emailUpdatedSuccess,
				loadingLogout,
				currentEmailForSignIn,
			},
			data: {
				submittingAppealSuccess,
				logoutSuccessMessage,
				logoutInfoMessage,
				logoutWarningMessage,
				logoutErrorMessage,
			},
			UI: { onlineStatus, mode },
		} = this.props;
		const { loading, email, emailErrors, passwordErrors, password } =
			this.state;
		return (
			<div>
				<Helmet>
					<title>Login - Goodbye App</title>
				</Helmet>
				{false ? (
					<Maintainance />
				) : (
					<div>
						{!loadingLogin &&
						!loadingUser &&
						!loadingLogout &&
						authenticated &&
						emailVerified ? (
							<Navigate to='/feed' />
						) : (
							<div>
								<ReCAPTCHA
									className='g-recaptcha'
									sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
									ref={(ref) => (this.recaptcha = ref)}
									theme='dark'
									onChange={this.onRecaptchaResolved}
									size='invisible'
								/>
								<div className='skyBackground pd2Media'>
									<div
										className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}
									>
										{/* <div className='flexColumn alignItemsCenter pdt08OnlyMedia'>
											<div className='backgroundLogo'>
												<img className='logo ' src={logo} alt='Logo' />
											</div>
										</div> */}
										<Avatar id='cardMainIcon' className='flexAuto'>
											<LoginIcon id='cardMainIconSize' />
										</Avatar>
										<h1 className='title center mg0'>{t('login')}</h1>
										<h3 className='subtitle center mg0'>
											{t('login_subtitle')}
										</h3>
										<form noValidate onSubmit={this.handleSubmit}>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={newEmailForSignIn}
												message={t('update_email_login', {
													newEmailForSignIn: newEmailForSignIn,
												})}
												// 				message={`For security, please login one more time with your current email
												// address in order to receive a verification link to update it to "${newEmailForSignIn}"`}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={newEmailForSignIn}
												severity='warning'
												message={
													<div className='text'>
														{t('cancel_by_clicking')}{' '}
														<span
															className='underline pointer'
															onClick={() => this.props.clearNewEmailData()}
														>
															{t('here')}
														</span>
														.
													</div>
												}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												error={authErrors}
												close
												onClose={this.props.clearAuthErrors}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={!onlineStatus}
												message={t('check_your_internet')}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												error={disabledMessage}
												close
												icon={<AccountCircleRoundedIcon />}
												onClose={this.props.clearDisabledUser}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={disabledFor}
												close
												onClose={this.props.clearDisabledUser}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={submittingAppealSuccess}
												message={t('appeal_success')}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={appealable && !appeal}
												message={t('already_appealed')}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={appealable === false && appeal === false}
												message={t('denied_appealed')}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={verifiedEmailSuccess}
												error={errorVerifyingEmail}
												info={verifyingEmail && t('verifying_email')}
												close={verifiedEmailSuccess}
												onClose={() => this.props.clearAuthMessages()}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={deletingUserData}
												message={t('deleting_account')}
												icon={<AccountCircleRoundedIcon />}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												alertTitle={t('error_deleting_account')}
												error={errorDeletingUserData}
												noCenter
												icon={<AccountCircleRoundedIcon />}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={deletedUserSuccess}
												close
												onClose={() => this.props.clearAuthMessages()}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												icon={<AccountCircleRoundedIcon />}
												success={deleteAccount}
												message={
													<span>
														{t('prev_delete_account_message', {
															deleteAccount,
														})}
													</span>
												}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={deleteAccount}
												message={
													<span>
														<b>
															{t('delete_account_message', {
																deleteAccount,
															})}
														</b>
													</span>
												}
												severity='warning'
											/>
											{deleteAccount && (
												<CustomButton
													onClick={this.props.clearAccountActionTypes}
													variant='contained'
													id='customCancelButton'
													btnText={t('cancel')}
												/>
											)}
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={signInEmail && !deleteAccount}
												message={t('email_verified_success_and_sign_in')}
												severity='success'
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={emailUpdatedSuccess}
												message={t('email_verified_updated')}
												severity='success'
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={sendingResetPasswordSuccess}
												message={sendingResetPasswordSuccess}
												severity='info'
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												success={logoutSuccessMessage}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={logoutInfoMessage}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												warning={logoutWarningMessage}
												close
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												error={logoutErrorMessage}
												close
											/>

											{(logoutSuccessMessage ||
												emailUpdatedSuccess ||
												sendingResetPasswordSuccess ||
												verifiedEmailSuccess) && <Confetti duration={1} />}

											<CustomInput
												type='email'
												titleLabel={t('email')}
												variant='outlined'
												placeholder={t('type_here')}
												name='email'
												inputId='email'
												onChange={this.handleChangeUserAndMail}
												helperText={emailErrors.email}
												inputError={
													emailErrors.email || authErrors ? true : false
												}
												inputValue={
													currentEmailForSignIn ? currentEmailForSignIn : email
												}
												disabled={
													currentEmailForSignIn || loadingLogin || loading
														? true
														: false
												}
												inputAdornmentPosition='end'
												autoComplete='email'
												required
												fullWidth
												deletable={() => this.setState({ email: '' })}
												isEmail={isEmail(
													currentEmailForSignIn ? currentEmailForSignIn : email,
												)}
												className='mg0'
											/>

											<CustomInput
												variant='outlined'
												type='password'
												titleLabel={t('password')}
												placeholder={t('type_here')}
												name='password'
												inputId='password'
												onChange={this.handleChange}
												helperText={passwordErrors.password}
												inputError={
													passwordErrors.password || authErrors ? true : false
												}
												inputValue={password}
												inputAdornmentPosition='end'
												autoComplete='password'
												required
												fullWidth
												disabled={loadingLogin || loading}
											/>
											<div className='flex'>
												<CustomButton
													type='submit'
													variant='contained'
													disabled={!onlineStatus || loadingLogin}
													startIcon={<LoginIcon />}
													loading={loadingLogin || loading}
													id='customSuccessButton'
													btnText={t('login')}
													fullWidth
													margin='1.3rem auto 1rem auto'
												/>
											</div>
											<hr />
											<Grid container className='center'>
												<Grid item xs={12} sm={12} md={6}>
													<p className='authQuestion2'>
														{t('forgot_password')}
													</p>
													<Link
														to='/forgotPassword'
														id='link'
														className='text bold'
													>
														{t('recover')}
													</Link>
												</Grid>
												<Grid item xs={12} sm={12} md={6}>
													<p className='authQuestion2'>
														{t('dont_have_a_account')}
													</p>
													<Link
														to='/signup'
														id='link'
														className='text bold capitalize'
													>
														{t('signup')}
													</Link>
												</Grid>
											</Grid>
										</form>
									</div>
								</div>
								<CustomAlerts
									alertId='smallMuiAlert'
									info={true}
									message={
										<span>
											<p className='center mg0 small '>
												{t('protected_google')}{' '}
												<a
													href='https://policies.google.com/privacy'
													rel='noopener noreferrer'
													target='_blank'
													className='bold link underlineHover'
												>
													{t('privacy_policy')}
												</a>{' '}
												{t('and')}{' '}
												<a
													href='https://policies.google.com/terms'
													rel='noopener noreferrer'
													target='_blank'
													className='bold link underlineHover'
												>
													{t('terms_of_service')}
												</a>{' '}
												{t('apply')}.
											</p>
										</span>
									}
									margin='0'
									icon={<SecurityIcon />}
								/>
							</div>
						)}
					</div>
				)}
				<Footer />
			</div>
		);
	}
}
Login.propTypes = {
	loginUser: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	clearAuthErrors: PropTypes.func.isRequired,
	clearAuthMessages: PropTypes.func.isRequired,
	clearNewEmailData: PropTypes.func.isRequired,
	clearAccountActionTypes: PropTypes.func.isRequired,
	clearDisabledUser: PropTypes.func.isRequired,
	getUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

const mapActionsToProps = {
	loginUser,
	clearAuthErrors,
	clearAuthMessages,
	clearNewEmailData,
	clearAccountActionTypes,
	clearDisabledUser,
	getUserData,
};
export default connect(mapStateToProps, mapActionsToProps)(withRouter(Login));
