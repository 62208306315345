import {
	LOADING_SIGNUP,
	SUCCESS_SIGNUP,
	ERRORS_SIGNUP,
	LOADING_LOGIN,
	STOP_LOADING_LOGIN,
	CLEAR_AUTH_MESSAGES,
	CLEAR_AUTH_ERRORS,
	SET_USER,
	UPLOADING_USER_DETAILS,
	SET_USER_DETAILS,
	ERROR_SET_USER_DETAILS,
	SET_VERIFIED_USER,
	LOADING_VERIFICATION_EMAIL,
	VERIFICATION_EMAIL_SUCCESS_MESSAGE,
	CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE,
	SENT_TOO_MANY_EMAILS,
	SET_AUTHENTICATED,
	ERRORS_LOGIN,
	SET_UNAUTHENTICATED,
	LOADING_USER,
	MARK_ONE_NOTIFICATION_READ,
	DELETE_NOTIFICATION,
	SET_MORE_NOTIFICATIONS,
	ALLOW_NOTIFICATIONS,
	ADD_NOTIFICATIONS,
	UPDATE_NOTIFICATION,
	NOTIFICATION_REDIRECT_URL,
	SET_DISABLED_USER,
	LOADING_NOTIFICATIONS,
	SET_NOTIFICATION_ERRORS,
	SET_RESETED_IMAGE,
	UPDATING_IMG,
	STOP_UPDATING_IMG,
	SENDING_FORGOT_PASSWORD_EMAIL,
	FORGOT_PASSWORD_EMAIL_SUCCESS,
	ERROR_SENDING_FORGOT_PASSWORD_EMAIL,
	DECREMENT_POST_COUNT,
	DECREMENT_UNRELEASED_POST_COUNT,
	VERIFYING_EMAIL_OOBCODE,
	SET_VERIFIED_EMAIL_OOBCODE,
	ERROR_VERIFYING_EMAIL_OOBCODE,
	ERROR_VERIFYING_RESET_PASSWORD_OOBCODE,
	SET_VERIFIED_RESET_PASSWORD_OOBCODE,
	VERIFYING_RESET_PASSWORD_OOBCODE,
	VERIFYING_RESET_EMAIL_OOBCODE,
	ERROR_SENDING_PASSWORD_RESET_EMAIL,
	SUCCESS_SENDING_PASSWORD_RESET_EMAIL,
	ERROR_RESET_EMAIL_OOBCODE,
	SET_VERIFIED_RESET_EMAIL_OOBCODE,
	SENDING_LOG_IN_EMAIL_SENT,
	SIGN_IN_EMAIL_SENT,
	ERROR_SENDING_LOG_IN_EMAIL_SENT,
	EMAIL_SUCCESFULLY_UPDATED,
	NEW_EMAIL_UPDATE_LINK_SENT,
	UPDATE_EMAIL_ACCOUNT_ACTION,
	UPDATE_PASSWORD_ACCOUNT_ACTION,
	UPDATE_USERNAME_ACCOUNT_ACTION,
	USERNAME_UPDATED_SUCCESFULLY,
	UPDATING_USERNAME_ACCOUNT_ACTION,
	ERROR_UPDATING_USERNAME_ACCOUNT_ACTION,
	CHECKING_USERNAME_AVAILABILTY,
	USERNAME_IS_AVAILABLE,
	ERROR_CHECKING_USERNAME_AVAILABILTY,
	CLEAR_CONFIG_SUCCESS,
	ALLOW_USERNAME_CHANGE,
	CLEAR_ACCOUNT_ACTION_TYPES,
	FOLLOW_USER,
	UNFOLLOW_USER,
	SET_SIGN_IN_EMAIL,
	DELETE_ACCOUNT_ACTION,
	DELETING_USER_DATA,
	DELETED_USER_DATA_SUCCESFULLY,
	ERROR_DELETING_USER_DATA,
	REPORTING_ISSUE,
	REPORTING_ISSUE_SUCCESS,
	ERROR_REPORTING_ISSUE,
	CLEAR_REPORT,
	SET_ADMIN_DATA,
	LOADING_ADMIN_DATA,
	LOADING_ACCOUNT_ACTION,
	SET_ACCOUNT_ACTION,
	SET_ADMIN_PANEL_DATA,
	ERROR_SET_ACCOUNT_ACTION,
	CLEAR_ACCOUNT_RESTRICTION,
	SET_USER_ID_LISTENER,
	REFRESH_TAB,
	UPDATE_PROFILE_DATA,
	SET_NEW_EMAIL_ACTIONS,
	CLEAR_NEW_EMAIL_ACTIONS,
	ON_MESSAGE_LISTENER,
	SENDING_MESSAGE,
	SENDING_MESSAGE_SUCCESS,
	ERROR_SENDING_MESSAGE,
	CLEAR_MESSAGE_ACTIONS,
	DELETE_INFRINGEMENT_NOTIFICATION,
	LOADING_UNSENT_EMAILS,
	ERROR_LOADING_UNSENT_EMAILS,
	SET_UNSENT_EMAILS_SUCCESS,
	LOADING_LOGOUT,
	ERROR_LOADING_LOGOUT,
	LOADING_FILTERED_NOTIFICATIONS,
	SET_MORE_FILTERED_NOTIFICATIONS,
	SET_FILTERED_NOTIFICATIONS,
	LOADING_MORE_FILTERED_NOTIFICATIONS,
	UPDATE_UNREAD_NOTIFICATIONS_COUNT,
	LOADING_FOLLOWER_ACTION_ID,
	ERROR_FOLLOWER_ACTION,
	FCM_DATA,
	LOADING_NEW_NOTIFICATION_PREFERENCE,
	REPORTING_FEATURE,
	REPORTING_FEATURE_SUCCESS,
	ERROR_REPORTING_FEATURE,
	SETTING_INACTIVITY_INFO,
	LOGGING_OUT_ALL_USER_SESSIONS,
	LOADING_AUDIO_SENDER_DETAILS,
	SET_AUDIO_SENDER_DETAILS,
	ERROR_LOADING_AUDIO_SENDER_DETAILS,
	SET_PROFILE_IMAGE,
	SENDING_PASSWORD_RESET_EMAIL,
	REPORTING_SUPPORT,
	REPORTING_SUPPORT_SUCCESS,
	ERROR_REPORTING_SUPPORT,
	UPDATE_ADMIN_DOC_COUNT,
	VERIFY_EMAIL_FIRST,
	DELETE_POST_NOTIFICATIONS,
	DELETE_COMMENT_NOTIFICATIONS,
	SET_MICROPHONE_ACCESS,
	SET_CAMERA_ACCESS,
	DENY_MESSAGING,
	SETTING_LANG,
} from '../types';

const initialState = {
	credentials: {},
	fcmData: {},
	loadingNewNotificationPreference: false,
	notificationUrlToRedirect: {},
	notifications: [],
	audioUserDetails: {},
	loadingAudioUserDetails: false,
	errorLoadingAudioUserDetails: null,
	unreadNotificationsCount: 0,
	filteredNotifications: [],
	disabledUser: {},
	userIdListener: '',
	loadingUser: null,
	loadingLogout: false,
	errorLogingOut: null,
	allowNotifications: false,
	loadingNotifications: null,
	loadingFilteredNotifications: null,
	loadingSignUp: null,
	successSignup: null,
	errorSignUp: null,
	authErrors: null,
	loadingLogin: null,
	errorSendingForgotPasswordEmail: null,
	sendingForgotPasswordEmail: null,
	loadingVerificationEmail: null,
	verificationEmailMessage: null,
	errorVerificationEmail: null,
	uploadingUserDetails: null,
	forgotPasswordEmailSuccess: null,
	updatingProfileImg: false,
	authenticated: false,
	emailVerified: false,
	verifyingEmail: false,
	verifiedEmailSuccess: null,
	errorVerifyingEmail: null,
	verifyingPassword: false,
	verifiedPasswordSuccess: null,
	errorVerifyingPassword: null,
	verifyingResetEmail: false,
	verifiedResetEmailSuccess: null,
	errorVerifyingResetEmail: null,
	sendingResetPasswordSuccess: null,
	sendingResetPassword: null,
	errorVerifyingResetPassword: null,
	sendingSignInEmail: false,
	signedEmailSentSuccess: null,
	errorSendingSignInEmail: false,
	newEmailUpdateLinkSentSuccess: null,
	handleUpdateEmailAddress: false,
	handleUpdatePassword: '',
	handleUpdateUsername: '',
	deleteAccount: '',
	emailUpdatedSuccess: null,
	updatingNewUsername: false,
	newUsernameUpdatedSuccess: null,
	errorUpdatingNewUsername: false,
	usernameAvailable: false,
	checkingUsernameAvailability: false,
	errorCheckingUsernameAvailability: null,
	allowUsernameChange: false,
	signInEmail: '',
	deletingUserData: false,
	deletedUserSuccess: null,
	errorDeletingUserData: null,
	errorLoadingNotifications: null,
	submittingReport: false,
	reportingIssue: null,
	reportingIssueSuccess: '',
	errorReportingIssue: null,
	reportingSupport: null,
	reportingSupportSuccess: '',
	errorReportingSupport: null,
	reportingFeature: null,
	reportingFeatureSuccess: '',
	errorReportingFeature: null,
	adminDoc: {},
	admins: [],
	loadingAdminData: false,
	adminPanel: {
		adminPanelActive: false,
		panel: '',
	},
	loadingAccountRestriction: false,
	accountRestriction: '',
	errorAccountRestriction: null,
	refreshTab: false,
	newEmailForSignIn: '',
	currentEmailForSignIn: '',
	notificationListener: {},
	sendingMessage: false,
	sendingMessageSuccess: '',
	errorSendingMessage: null,
	loadingUnsentEmails: false,
	errorLoadingUnsentEmails: null,
	unsentEmailsSuccess: '',
	loadingFollowActionIds: [],
	errorLoadingFollowActionIds: [],
	logginOutAllUserSessions: false,
	verifyEmailFirst: '',
	settingInactivity: false,
	microphoneAccess: false,
	cameraAccess: false,
	denyMessaging: false,
	settingLang: false,
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESH_TAB:
			return {
				...state,
				refreshTab: action.payload,
			};
		case SET_ADMIN_DATA:
			return {
				...state,
				adminDoc: action.payload,
				admins: action.payload.admins,
				loadingAdminData: false,
			};
		case SET_ADMIN_PANEL_DATA:
			return {
				...state,
				adminPanel: {
					...state.adminPanel,
					...action.payload,
				},
			};
		case UPDATE_PROFILE_DATA:
			return {
				...state,
				credentials: { ...state.credentials, ...action.payload.user },
			};
		case LOADING_ADMIN_DATA:
			return {
				...state,
				loadingAdminData: true,
			};
		case LOADING_SIGNUP:
			return {
				...state,
				loadingSignUp: true,
			};
		case SUCCESS_SIGNUP:
			return {
				...state,
				loadingSignUp: false,
				successSignup: true,
			};
		case ERRORS_SIGNUP:
			return {
				...state,
				errorSignUp: action.payload,
				loadingSignUp: false,
			};
		case LOADING_LOGIN:
			return {
				...state,
				loadingLogin: true,
				errorVerificationEmail: null,
				deletedUserSuccess: null,
				errorDeletingUserData: null,
				signInEmail: '',
			};
		case STOP_LOADING_LOGIN:
			return {
				...state,
				loadingLogin: false,
			};

		case SET_VERIFIED_USER:
			return {
				...state,
				emailVerified: true,
				loadingLogin: false,
			};
		case LOADING_VERIFICATION_EMAIL:
			return {
				...state,
				loadingVerificationEmail: true,
				errorVerificationEmail: null,
			};
		case VERIFICATION_EMAIL_SUCCESS_MESSAGE:
			return {
				...state,
				loadingVerificationEmail: false,
				verificationEmailMessage: action.payload,
				errorVerificationEmail: null,
				loadingLogin: false,
			};
		case CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE:
			return {
				...state,
				verificationEmailMessage: null,
			};
		case SENT_TOO_MANY_EMAILS:
			return {
				...state,
				loadingVerificationEmail: false,
				verificationEmailMessage: false,
				sendingResetPassword: false,

				errorVerificationEmail: action.payload,
			};

		case SET_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
			};
		case ERRORS_LOGIN:
			return {
				...state,
				authErrors: action.payload,
				loadingLogin: false,
			};
		case CLEAR_AUTH_ERRORS:
			return {
				...state,
				authErrors: null,
				loadingLogin: false,
				errorSendingForgotPasswordEmail: null,
				errorSignUp: null,
			};
		case CLEAR_AUTH_MESSAGES:
			return {
				...state,
				deletedUserSuccess: null,
				verifiedEmailSuccess: null,
				errorVerifyingEmail: null,
				signInEmail: '',
			};
		case SET_UNAUTHENTICATED:
			return initialState;

		case SENDING_FORGOT_PASSWORD_EMAIL:
			return {
				...state,
				sendingForgotPasswordEmail: true,
				forgotPasswordEmailSuccess: '',
			};
		case FORGOT_PASSWORD_EMAIL_SUCCESS:
			return {
				...state,
				sendingForgotPasswordEmail: false,
				forgotPasswordEmailSuccess: action.payload.res,
				errorSendingForgotPasswordEmail: null,
			};
		case ERROR_SENDING_FORGOT_PASSWORD_EMAIL:
			return {
				...state,
				sendingForgotPasswordEmail: false,
				errorSendingForgotPasswordEmail: action.payload,
				forgotPasswordEmailSuccess: '',
			};
		case SET_USER:
			return {
				...state,
				authenticated: true,
				credentials: action.payload.credentials,
				loadingUser: false,
				// loadingLogin: false,
				loadingSignUp: false,
			};

		case LOADING_USER:
			return {
				...state,
				loadingUser: action.payload,
			};
		case LOADING_LOGOUT:
			return {
				...state,
				loadingLogout: true,
			};
		case ERROR_LOADING_LOGOUT:
			return {
				...state,
				loadingLogout: false,
				errorLogingOut: action.payload,
			};
		case SET_DISABLED_USER:
			return {
				...state,
				loadingUser: false,
				loadingLogin: false,
				disabledUser: action.payload,
			};

		case UPLOADING_USER_DETAILS:
			return {
				...state,
				uploadingUserDetails: true,
			};
		case SET_USER_DETAILS:
			return {
				...state,
				uploadingUserDetails: null,
			};
		case ERROR_SET_USER_DETAILS:
			return {
				...state,
				uploadingUserDetails: false,
			};

		case MARK_ONE_NOTIFICATION_READ:
			return {
				...state,
				notifications: state.notifications.map((not, index) => {
					return action.payload === not.notificationId
						? {
								...not,
								read: true,
						  }
						: not;
				}),
			};
		case DELETE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications
					.map((not) => {
						if (
							not.allNotificationIds &&
							not.allNotificationIds.length > 1 &&
							not.allNotificationIds.includes(action.payload.notificationId)
						) {
							let modified = not;
							modified.allNotificationIds = modified.allNotificationIds.filter(
								(id) => id !== action.payload.notificationId,
							);
							if (not.notificationId === action.payload.notificationId) {
								modified.notificationId = modified.allNotificationIds[0];
							}
							if (modified.senders) {
								modified.senders = modified.senders.filter(
									(senderId) => senderId !== action.payload.userId,
								);
							}

							// Remove sender info at the same index
							if (modified.sendersInfo) {
								const senderIndex = modified.sendersInfo.findIndex(
									(info) => info.senderId === action.payload.userId,
								);
								if (senderIndex !== -1) {
									modified.sendersInfo.splice(senderIndex, 1);
								}
							}

							// Your logic to modify the notification
							// (similar to the logic in the previous response)
							return {
								...modified,
								allNotificationIds: not.allNotificationIds.filter(
									(id) => id !== action.payload.notificationId,
								),
							};
						} else if (
							not.notificationId &&
							not.notificationId === action.payload.notificationId
						) {
							// Do not include the notification if it matches the idToRemove
							return null;
						}
						return not;
					})
					.filter(Boolean),
				filteredNotifications: state.filteredNotifications
					.map((not) => {
						if (
							not.allNotificationIds &&
							not.allNotificationIds.length > 1 &&
							not.allNotificationIds.includes(action.payload.notificationId)
						) {
							let modified = not;
							modified.allNotificationIds = modified.allNotificationIds.filter(
								(id) => id !== action.payload.notificationId,
							);
							if (not.notificationId === action.payload.notificationId) {
								modified.notificationId = modified.allNotificationIds[0];
							}
							if (modified.senders) {
								modified.senders = modified.senders.filter(
									(senderId) => senderId !== action.payload.userId,
								);
							}

							// Remove sender info at the same index
							if (modified.sendersInfo) {
								const senderIndex = modified.sendersInfo.findIndex(
									(info) => info.senderId === action.payload.userId,
								);
								if (senderIndex !== -1) {
									modified.sendersInfo.splice(senderIndex, 1);
								}
							}

							// Your logic to modify the notification
							// (similar to the logic in the previous response)
							return {
								...modified,
								allNotificationIds: not.allNotificationIds.filter(
									(id) => id !== action.payload.notificationId,
								),
							};
						} else if (
							not.notificationId &&
							not.notificationId === action.payload.notificationId
						) {
							// Do not include the notification if it matches the idToRemove
							return null;
						}
						return not;
					})
					.filter(Boolean),
			};

		case SET_MORE_NOTIFICATIONS:
			return {
				...state,
				notifications: state.notifications.concat(action.payload),
				loadingNotifications: false,
			};
		case SET_MORE_FILTERED_NOTIFICATIONS:
			return {
				...state,
				filteredNotifications: state.filteredNotifications.concat(
					action.payload,
				),
				loadingFilteredNotifications: false,
			};
		case SET_FILTERED_NOTIFICATIONS:
			return {
				...state,
				filteredNotifications: action.payload,
				loadingFilteredNotifications: false,
			};
		case SET_NOTIFICATION_ERRORS:
			return {
				...state,
				loadingNotifications: false,
				errorLoadingNotifications: action.payload,
				loadingFilteredNotifications: false,
			};

		case UPDATE_UNREAD_NOTIFICATIONS_COUNT:
			let count;
			if (action.payload.reduce) {
				let math = state.unreadNotificationsCount - action.payload.reduce;
				count = math >= 0 ? math : 0;
			} else {
				count = state.unreadNotificationsCount + action.payload.unreadCount;
			}

			return {
				...state,
				unreadNotificationsCount: count,
			};
		case ADD_NOTIFICATIONS:
			let filteredRead = state.notifications.filter((not) => not.read === true);
			let filteredUnread = state.notifications.filter(
				(not) => not.read !== true,
			);

			return {
				...state,
				notifications: action.payload
					.concat(filteredUnread)
					.concat(filteredRead),
			};

		case UPDATE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.map((not) => {
					return not.notificationId === action.payload.notificationId
						? { ...not, ...action.payload }
						: not;
				}),
			};
		case DELETE_INFRINGEMENT_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.filter(
					(not) =>
						not.notificationId !== action.payload.notificationId ||
						not.type !== action.payload.type,
				),
			};

		case NOTIFICATION_REDIRECT_URL:
			return {
				...state,
				notificationUrlToRedirect: action.payload,
			};

		case LOADING_NOTIFICATIONS:
			return {
				...state,
				loadingNotifications: true,
				errorLoadingNotifications: null,
			};
		case LOADING_FILTERED_NOTIFICATIONS:
			return {
				...state,
				loadingFilteredNotifications: true,
				filteredNotifications: [],
				errorLoadingNotifications: null,
			};
		case LOADING_MORE_FILTERED_NOTIFICATIONS:
			return {
				...state,
				loadingFilteredNotifications: true,
				errorLoadingNotifications: null,
			};

		case SET_RESETED_IMAGE: {
			return {
				...state,
				credentials: {
					...state.credentials,
					imageUrl: action.payload,
				},
			};
		}

		case UPDATING_IMG:
			return {
				...state,
				updatingProfileImg: true,
			};
		case STOP_UPDATING_IMG:
			return {
				...state,
				updatingProfileImg: false,
			};

		case DECREMENT_POST_COUNT: {
			return {
				...state,
				credentials: {
					...state.credentials,
					postCount:
						state.credentials.postCount > 0
							? state.credentials.postCount - 1
							: 0,
				},
			};
		}
		case DECREMENT_UNRELEASED_POST_COUNT: {
			return {
				...state,
				credentials: {
					...state.credentials,
					unreleasedPostCount:
						state.credentials.unreleasedPostCount > 0
							? state.credentials.unreleasedPostCount - 1
							: 0,
				},
			};
		}
		case VERIFYING_EMAIL_OOBCODE: {
			return {
				...state,
				verifyingEmail: true,
			};
		}
		case SET_VERIFIED_EMAIL_OOBCODE: {
			return {
				...state,
				emailVerified: true,
				verifyingEmail: false,
				verifiedEmailSuccess: action.payload,
				errorVerifyingEmail: null,
			};
		}

		case ERROR_VERIFYING_EMAIL_OOBCODE: {
			return {
				...state,
				verifyingEmail: false,
				verifiedEmailSuccess: null,
				errorVerifyingEmail: action.payload,
			};
		}

		case VERIFYING_RESET_PASSWORD_OOBCODE: {
			return {
				...state,
				verifyingPassword: true,
			};
		}
		case SET_VERIFIED_RESET_PASSWORD_OOBCODE: {
			return {
				...state,
				verifyingPassword: false,
				verifiedPasswordSuccess: action.payload,
				errorVerifyingPassword: null,
			};
		}
		case ERROR_VERIFYING_RESET_PASSWORD_OOBCODE: {
			return {
				...state,
				verifyingPassword: false,
				verifiedPasswordSuccess: null,
				errorVerifyingPassword: action.payload,
			};
		}
		case VERIFYING_RESET_EMAIL_OOBCODE: {
			return {
				...state,
				verifyingResetEmail: true,
			};
		}

		case SET_VERIFIED_RESET_EMAIL_OOBCODE: {
			return {
				...state,
				verifyingResetEmail: false,
				verifiedResetEmailSuccess: action.payload,
				errorVerifyingResetEmail: null,
			};
		}

		case ERROR_RESET_EMAIL_OOBCODE: {
			return {
				...state,
				verifyingResetEmail: false,
				verifiedResetEmailSuccess: null,
				errorVerifyingResetEmail: action.payload,
			};
		}

		case SENDING_PASSWORD_RESET_EMAIL: {
			return {
				...state,
				sendingResetPassword: true,
				sendingResetPasswordSuccess: null,
			};
		}
		case SUCCESS_SENDING_PASSWORD_RESET_EMAIL: {
			return {
				...state,
				sendingResetPasswordSuccess: action.payload,
				sendingResetPassword: false,
			};
		}
		case ERROR_SENDING_PASSWORD_RESET_EMAIL: {
			return {
				...state,
				sendingResetPasswordSuccess: false,
				sendingResetPassword: false,
				errorVerifyingResetPassword: action.payload,
			};
		}
		case SENDING_LOG_IN_EMAIL_SENT: {
			return {
				...state,
				sendingSignInEmail: true,
			};
		}
		case SIGN_IN_EMAIL_SENT: {
			return {
				...state,
				signedEmailSentSuccess: action.payload,
				sendingSignInEmail: false,
				errorSendingSignInEmail: null,
			};
		}
		case ERROR_SENDING_LOG_IN_EMAIL_SENT: {
			return {
				...state,
				errorSendingSignInEmail: action.payload,
				sendingSignInEmail: false,
			};
		}

		case EMAIL_SUCCESFULLY_UPDATED: {
			return {
				...state,
				verifyingResetEmail: false,
				emailUpdatedSuccess: action.payload.message,
				credentials: {
					...state.credentials,
					email: action.payload.email,
				},
			};
		}
		case NEW_EMAIL_UPDATE_LINK_SENT: {
			return {
				...state,
				newEmailUpdateLinkSentSuccess: action.payload,
				loadingLogin: false,
			};
		}
		case UPDATE_EMAIL_ACCOUNT_ACTION: {
			return {
				...state,
				handleUpdateEmailAddress: true,
			};
		}
		case UPDATE_PASSWORD_ACCOUNT_ACTION: {
			return {
				...state,
				handleUpdatePassword: action.payload,
			};
		}
		case UPDATE_USERNAME_ACCOUNT_ACTION: {
			return {
				...state,
				handleUpdateUsername: action.payload,
			};
		}
		case UPDATING_USERNAME_ACCOUNT_ACTION: {
			return {
				...state,
				updatingNewUsername: action.payload,
			};
		}
		case USERNAME_UPDATED_SUCCESFULLY: {
			return {
				...state,
				updatingNewUsername: false,
				newUsernameUpdatedSuccess: action.payload.message,
				credentials: {
					...state.credentials,
					handle: action.payload.handle,
					nextUsernameChange: action.payload.nextUsernameChange,
				},
				allowUsernameChange: false,
			};
		}
		case ERROR_UPDATING_USERNAME_ACCOUNT_ACTION: {
			return {
				...state,
				errorUpdatingNewUsername: action.payload,
				updatingNewUsername: false,
				usernameAvailable: false,
			};
		}

		case CHECKING_USERNAME_AVAILABILTY: {
			return {
				...state,
				checkingUsernameAvailability: true,
			};
		}
		case USERNAME_IS_AVAILABLE: {
			return {
				...state,
				checkingUsernameAvailability: false,
				usernameAvailable: action.payload,
				errorCheckingUsernameAvailability: null,
			};
		}
		case ERROR_CHECKING_USERNAME_AVAILABILTY: {
			return {
				...state,
				checkingUsernameAvailability: false,
				errorCheckingUsernameAvailability: action.payload,
				usernameAvailable: false,
			};
		}
		case CLEAR_CONFIG_SUCCESS: {
			return {
				...state,
				emailUpdatedSuccess: null,
				newUsernameUpdatedSuccess: null,
				usernameAvailable: false,
			};
		}
		case ALLOW_USERNAME_CHANGE: {
			return {
				...state,
				allowUsernameChange: action.payload,
			};
		}

		case LOADING_FOLLOWER_ACTION_ID:
			return {
				...state,
				loadingFollowActionIds: state.loadingFollowActionIds.concat(
					action.payload,
				),
			};
		case ERROR_FOLLOWER_ACTION:
			return {
				...state,
				loadingFollowActionIds: state.loadingFollowActionIds.filter(
					(id) => id !== action.payload,
				),
				errorLoadingFollowActionIds: state.errorLoadingFollowActionIds.concat(
					action.payload,
				),
			};
		case FOLLOW_USER:
			return {
				...state,
				credentials: {
					...state.credentials,
					followingCount: state.credentials.followingCount + 1,
					followingUserIds: state.credentials.followingUserIds.concat(
						action.payload.userId,
					),
				},
				loadingFollowActionIds: state.loadingFollowActionIds.filter(
					(id) => id !== action.payload.userId,
				),
			};
		case UNFOLLOW_USER:
			return {
				...state,
				credentials: {
					...state.credentials,
					followingCount: state.credentials.followingCount - 1,
					followingUserIds: state.credentials.followingUserIds.filter(
						(userId) => userId !== action.payload.userId,
					),
				},
				loadingFollowActionIds: state.loadingFollowActionIds.filter(
					(id) => id !== action.payload.userId,
				),
			};

		case SET_SIGN_IN_EMAIL:
			return {
				...state,
				signInEmail: action.payload,
			};

		case DELETE_ACCOUNT_ACTION:
			return {
				...state,
				deleteAccount: action.payload,
			};

		case DELETING_USER_DATA:
			return {
				...state,
				deletingUserData: true,
			};
		case DELETED_USER_DATA_SUCCESFULLY:
			return {
				...state,
				deletedUserSuccess: action.payload,
				deletingUserData: false,
				emailVerified: false,
				authenticated: false,
			};
		case ERROR_DELETING_USER_DATA:
			return {
				...state,
				errorDeletingUserData: action.payload,
				deletingUserData: false,
			};

		case CLEAR_REPORT:
			return {
				...state,
				reportingIssueSuccess: '',
				reportingFeatureSuccess: '',
				reportingSupportSuccess: '',
				errorReportingIssue: null,
				errorReportingSupport: null,
				errorReportingFeature: null,
			};
		case REPORTING_ISSUE:
			return {
				...state,
				reportingIssue: true,
				reportingIssueSuccess: '',
				errorReportingIssue: null,
			};
		case REPORTING_ISSUE_SUCCESS:
			return {
				...state,
				reportingIssue: false,
				reportingIssueSuccess: action.payload,
			};
		case ERROR_REPORTING_ISSUE:
			return {
				...state,
				reportingIssue: false,
				errorReportingIssue: action.payload,
			};
		case REPORTING_SUPPORT:
			return {
				...state,
				reportingSupport: true,
				reportingSupportSuccess: '',
				errorReportingSupport: null,
			};
		case REPORTING_SUPPORT_SUCCESS:
			return {
				...state,
				reportingSupport: false,
				reportingSupportSuccess: action.payload,
			};
		case ERROR_REPORTING_SUPPORT:
			return {
				...state,
				reportingSupport: false,
				errorReportingSupport: action.payload,
			};
		case REPORTING_FEATURE:
			return {
				...state,
				reportingFeature: true,
				reportingFeatureSuccess: '',
				errorReportingFeature: null,
			};
		case REPORTING_FEATURE_SUCCESS:
			return {
				...state,
				reportingFeature: false,
				reportingFeatureSuccess: action.payload,
			};
		case ERROR_REPORTING_FEATURE:
			return {
				...state,
				reportingFeature: false,
				errorReportingFeature: action.payload,
			};

		case UPDATE_ADMIN_DOC_COUNT:
			return {
				...state,
				adminDoc: { ...state.adminDoc, ...action.payload },
			};

		case LOADING_ACCOUNT_ACTION:
			return {
				...state,
				loadingAccountRestriction: true,
				accountRestriction: '',
				errorAccountRestriction: null,
			};
		case SET_ACCOUNT_ACTION:
			return {
				...state,
				loadingAccountRestriction: false,
				accountRestriction: action.payload.message,
			};
		case ERROR_SET_ACCOUNT_ACTION:
			return {
				...state,
				loadingAccountRestriction: false,
				errorAccountRestriction: action.payload,
			};
		case CLEAR_ACCOUNT_RESTRICTION:
			return {
				...state,
				loadingAccountRestriction: false,
				accountRestriction: '',
				errorAccountRestriction: null,
			};
		case CLEAR_ACCOUNT_ACTION_TYPES: {
			return {
				...state,
				handleUpdateEmailAddress: false,
				handleUpdatePassword: '',
				handleUpdateUsername: '',
				verifiedPasswordSuccess: null,
				authErrors: null,
				errorVerifyingPassword: null,
				errorVerifyingResetEmail: null,
				errorVerifyingResetPassword: null,
				errorCheckingUsernameAvailability: null,
				errorUpdatingNewUsername: false,
				deleteAccount: '',
				signInEmail: '',
			};
		}
		case SET_USER_ID_LISTENER:
			return {
				...state,
				userIdListener: action.payload,
			};

		case SET_NEW_EMAIL_ACTIONS:
			return {
				...state,
				newEmailForSignIn: action.payload.newEmailForSignIn,
				currentEmailForSignIn: action.payload.currentEmailForSignIn,
			};
		case CLEAR_NEW_EMAIL_ACTIONS:
			return {
				...state,
				newEmailForSignIn: '',
				currentEmailForSignIn: '',
			};
		case ON_MESSAGE_LISTENER:
			return {
				...state,
				notificationListener: action.payload,
			};

		case SENDING_MESSAGE:
			return {
				...state,
				sendingMessage: true,
				errorSendingMessage: null,
				sendingMessageSuccess: '',
			};
		case SENDING_MESSAGE_SUCCESS:
			return {
				...state,
				sendingMessage: false,
				sendingMessageSuccess: action.payload,
			};
		case ERROR_SENDING_MESSAGE:
			return {
				...state,
				sendingMessage: false,
				errorSendingMessage: action.payload,
			};
		case CLEAR_MESSAGE_ACTIONS: {
			return {
				...state,
				sendingMessage: false,
				errorSendingMessage: null,
				sendingMessageSuccess: '',
			};
		}
		case LOADING_UNSENT_EMAILS: {
			return {
				...state,
				loadingUnsentEmails: true,
				successUnsentEmails: '',
				errorLoadingUnsentEmails: null,
			};
		}
		case SET_UNSENT_EMAILS_SUCCESS: {
			return {
				...state,
				loadingUnsentEmails: false,
				unsentEmailsSuccess: action.payload,
				errorLoadingUnsentEmails: null,
			};
		}
		case ERROR_LOADING_UNSENT_EMAILS: {
			return {
				...state,
				loadingUnsentEmails: false,
				errorLoadingUnsentEmails: action.payload,
			};
		}
		case FCM_DATA: {
			return {
				...state,
				fcmData: { ...state.fcmData, ...action.payload },
				loadingNewNotificationPreference: false,
			};
		}
		case LOADING_NEW_NOTIFICATION_PREFERENCE: {
			return { ...state, loadingNewNotificationPreference: action.payload };
		}
		case SETTING_INACTIVITY_INFO: {
			return {
				...state,
				settingInactivity: action.payload,
			};
		}
		case LOGGING_OUT_ALL_USER_SESSIONS: {
			return {
				...state,
				logginOutAllUserSessions: action.payload,
			};
		}

		case LOADING_AUDIO_SENDER_DETAILS: {
			return {
				...state,
				loadingAudioUserDetails: true,
				errorLoadingAudioUserDetails: null,
				audioUserDetails: {},
			};
		}
		case SET_AUDIO_SENDER_DETAILS: {
			return {
				...state,
				loadingAudioUserDetails: false,
				audioUserDetails: action.payload,
			};
		}
		case ERROR_LOADING_AUDIO_SENDER_DETAILS: {
			return {
				...state,
				loadingAudioUserDetails: false,
				errorLoadingAudioUserDetails: action.payload,
			};
		}
		case SET_PROFILE_IMAGE: {
			return {
				...state,
				credentials: {
					...state.credentials,
					imageUrl: action.payload,
				},
			};
		}

		case VERIFY_EMAIL_FIRST: {
			return {
				...state,
				verifyEmailFirst: action.payload,
			};
		}

		case DELETE_POST_NOTIFICATIONS: {
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) => notification.postId !== action.payload,
				),
				filteredNotifications: state.notifications.filter(
					(notification) => notification.postId !== action.payload,
				),
			};
		}
		case DELETE_COMMENT_NOTIFICATIONS: {
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) =>
						notification.commentId !== action.payload.deletedCommentId &&
						notification.repliedId !== action.payload.deletedCommentId,
				),
				filteredNotifications: state.filteredNotifications.filter(
					(notification) =>
						notification.commentId !== action.payload.deletedCommentId &&
						notification.repliedId !== action.payload.deletedCommentId,
				),
			};
		}
		case ALLOW_NOTIFICATIONS:
			return {
				...state,
				allowNotifications: action.payload,
			};
		case SET_MICROPHONE_ACCESS: {
			return {
				...state,
				microphoneAccess: action.payload,
			};
		}
		case SET_CAMERA_ACCESS: {
			return {
				...state,
				cameraAccess: action.payload,
			};
		}
		case DENY_MESSAGING:
			return {
				...state,
				denyMessaging: action.payload,
			};
		case SETTING_LANG:
			return {
				...state,
				settingLang: action.payload,
			};
		default:
			return state;
	}
}
