import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { withRouter } from '../../redux/withRouter';
import {
	editPost,
	deleteRecipients,
	clearQueriedUsers,
	setPostToEdit,
	clearAudioFile,
	clearPostUploadError,
	fetchRecipientsData,
	setHashtags,
	setDateTimer,
	setOpenedDialog,
	uploadPost,
	uploadFile,
	resetImageFile,
	setAllEmailRecipients,
	addRecipients,
	isSameDay,
	dim,
	isEmail,
	trimFileName,
	scrollToTopFunc,
} from '../../redux/actions/dataActions';
import dayjs from 'dayjs';
import {
	Avatar,
	Button,
	Chip,
	Dialog,
	DialogContent,
	ListItem,
} from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ImageIcon from '@mui/icons-material/Image';
import Groups3Icon from '@mui/icons-material/Groups3';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import NotesIcon from '@mui/icons-material/Notes';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import SecurityIcon from '@mui/icons-material/Security';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import {
	Collapse,
	Divider,
	Grow,
	Paper,
	Radio,
	RadioGroup,
	Slide,
	Toolbar,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Searchbar from './Searchbar';
import Post from '../Post/Post';
import Recipients from './Recipients';
import RefreshSite from './RefreshSite';
import FollowerCount from './FollowerCount';
import FollowingCount from './FollowingCount';
import ConfirmationDialog from './ConfirmationDialog';
import { ImageEditor } from '../ImageEditor/ImageEditor';
import { FileDrop } from 'react-file-drop';
import CustomAlerts from './CustomAlerts';
import CustomButton from './CustomButton';
import CommentForm from './CommentForm';
import AutocompleteHashtag from './AutocompleteHashtag';
import DateAndTimePicker from './DateAndTimePicker';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import VideoPlayer from '../MediaPlayers/VideoPlayer';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import AudioRecorder from '../Audio/AudioRecorder';
import Media from 'react-media';
import heic2any from 'heic2any';
import BackdropUi from './BackdropUi';
import CustomInput from './CustomInput';
import { toast } from 'react-toastify';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TagIcon from '@mui/icons-material/Tag';
import CameraPermission from './CameraPermission';
import { t } from 'i18next';

class MakePost extends Component {
	constructor(props) {
		super(props);
		this.containerRefStep1 = React.createRef(null);
		this.containerRefStep2 = React.createRef(null);
		this.containerRefStep3 = React.createRef(null);
	}

	state = {
		open: false,
		activeStep: 0,
		body: '',
		fileFormData: '',
		fileFormData2: '',
		postFormData: {},
		fileName: '',
		fileName2: '',
		stateFileToUpload: null,
		stateFileToUpload2: null,
		publicPost: true,
		releaseNow: true,
		notify: false,
		fileType: 'text',
		fileType2: '',
		mimetype: null,
		step2Errors: false,
		step3Errors: false,
		step4Errors: false,
		showUsers: true,
		showFollowers: false,
		showFollowing: false,
		queryField: 'Users',
		openCloseConfirmation: false,
		editing: false,
		setUndoDeletedFile: false,
		setUndoDeletedFile2: false,
		postCreatedAt: '',
		postId: '',
		postUserHandle: '',
		editedReleaseDate: '',
		editPostText: false,
		postToEditLivePost: false,
		saveImage: false,
		exceededFileSize: '',
		mentions: [],
		mentionsError: '',
		hashtagsError: '',
		loadingHeic: false,
		fileErrors: '',
		deletedFileFormData: '',
		deletedFileName: '',
		deletedStateFileToUpload: '',
		deletedFileType: '',
		deletedFileFormData2: '',
		deletedFileName2: '',
		deletedStateFileToUpload2: '',
		deletedFileType2: '',
		recipientType: 'user',
		emailErrors: {},
		recipientEmail: '',
	};

	handleOpen = () => {
		this.setState({ open: true });
		if (this.props.data.errorUploadingPost) {
			this.props.clearPostUploadError();
		}
		if (this.props.data.deletedAudioFile) {
			this.props.clearAudioFile();
		}
		this.props.setOpenedDialog(true);

		setTimeout(() => {
			this.setState({
				activeStep: 1,
			});
		}, 500);
	};

	componentWillReceiveProps(nextProps) {
		let state = this.state;
		if (
			nextProps.data.postRecipients.length > 0 &&
			state.activeStep === 3 &&
			state.publicPost === false &&
			state.step3Errors === true
		) {
			this.setState({
				step3Errors: false,
			});
		}

		let postToEdit = nextProps.data.postToEdit;
		if (
			Object.keys(postToEdit).length > 0 &&
			!state.editing &&
			!nextProps.data.editingPost.includes(postToEdit.postId)
		) {
			this.setState({
				open: true,
				body: postToEdit.body === undefined ? '' : postToEdit.body,
				postCreatedAt: postToEdit.createdAt,
				fileType: postToEdit.fileType,
				stateFileToUpload: postToEdit.fileUrl,
				fileType2: postToEdit.fileType2,
				stateFileToUpload2: postToEdit.fileUrl2,
				releaseNow: postToEdit.livePost,
				publicPost: postToEdit.publicPost,
				notify: postToEdit.publicPost ? false : true,
				postId: postToEdit.postId,
				editing: true,
				postUserHandle: postToEdit.userHandle,
				editedReleaseDate: postToEdit.releaseDate,
				postToEditLivePost: postToEdit.livePost,
				mentions: postToEdit.mentions ? postToEdit.mentions : [],
				activeStep: postToEdit.showVisibility ? 3 : 2,
				editPostText: true,
			});

			let meridiem = dayjs(postToEdit.releaseDate).format('A');
			this.props.setOpenedDialog(true);

			this.props.setDateTimer({
				dateValue: postToEdit.releaseDate,
				meridiem: meridiem,
				hourValue: parseInt(dayjs(postToEdit.releaseDate).format('h')),
			});

			if (postToEdit.emailRecipients && postToEdit.emailRecipients.length > 0) {
				this.props.setAllEmailRecipients(postToEdit.emailRecipients);
				this.setState({ notify: true, recipientType: 'email' });
			}
			if (postToEdit.recipients && postToEdit.recipients.length > 0) {
				this.props.fetchRecipientsData(postToEdit.recipients, 'post');
				this.setState({ notify: true });
			}

			if (postToEdit.hashtags && postToEdit.hashtags.length > 0) {
				let tags = [];
				postToEdit.hashtags.forEach((tag) => {
					return tags.push({ hashtag: tag.toLowerCase(), count: '' });
				});
				this.props.setHashtags(tags);
			}
		}

		if (!nextProps.data.openedDialog && nextProps.data.editedAudioFile) {
			this.props.clearAudioFile();
		}
	}
	handleChecked = (formLabel, value) => {
		let data = this.props.data;
		let state = this.state;
		if (formLabel === 'publicPost') {
			if (data.postRecipients.length > 0 || !value) {
				this.setState({
					publicPost: value,
					step3Errors: false,
					notify: true,
				});
			} else {
				this.setState({
					publicPost: value,
					step3Errors: false,
					notify: false,
				});
			}
		} else if (
			formLabel === 'recipientType' &&
			formLabel !== this.state.recipientType
		) {
			this.setState({ recipientType: value });
		} else if (formLabel === 'releaseNow') {
			if (value === true) {
				this.setState({ releaseNow: value, step4Errors: false });
			} else {
				this.setState({ releaseNow: value });
				if (state.editing && data.postToEdit.livePost) {
					this.props.setDateTimer({
						dateValue: null,
						meridiem: '',
						hourValue: '',
					});
				}
			}
		} else if (formLabel === 'notify') {
			this.setState({ notify: value });
		} else return;
	};

	clearDeletedFile = (type) => {
		if (
			(this.state.deletedFileType === 'image' && type === 'image') ||
			(this.state.deletedFileType === 'audio' && type === 'audio') ||
			type === 'video' ||
			type === 'text'
		) {
			this.setState({
				fileFormData: '',
				fileName: '',
				stateFileToUpload: null,
				fileType: 'text',
				deletedFileFormData: '',
				deletedFileName: '',
				deletedStateFileToUpload: '',
				deletedFileType: '',
				setUndoDeletedFile: false,
			});
			if (this.props.data.editedImageFile) {
				this.props.resetImageFile();
			}
		} else if (type === 'audio') {
			if (this.props.data.deletedAudioFile) {
				this.props.clearAudioFile();
				this.setState({
					setUndoDeletedFile2: false,
				});
			}

			if (this.state.fileType === 'audio' && this.state.fileType2 === '') {
				this.setState({
					fileType: 'text',
				});
			}

			if (this.state.deletedFileType2 === 'audio') {
				this.setState({
					fileFormData2: '',
					fileName2: '',
					stateFileToUpload2: null,
					fileType2: '',
					deletedFileFormData2: '',
					deletedFileName2: '',
					deletedStateFileToUpload2: '',
					deletedFileType2: '',
					setUndoDeletedFile2: false,
				});
			}
		}
	};

	handleDialog = (boolean) => {
		let data = this.props.data;
		let state = this.state;

		if (
			boolean === true ||
			(state.editing && state.body === data.postToEdit.body) ||
			(state.body === '' &&
				state.fileFormData === '' &&
				state.fileFormData2 === '' &&
				state.fileType === 'text') ||
			(state.fileType === 'audio' &&
				!this.props.data.editedAudioFile &&
				state.fileFormData === '')
		) {
			this.setInitialState();
			if (state.editing) {
				this.props.setPostToEdit({});
			}

			if (data.deletedAudioFile) {
				this.props.clearAudioFile();
			}
		} else if (state.openCloseConfirmation) {
			this.setState({ openCloseConfirmation: false });
		} else {
			this.setState({ openCloseConfirmation: true });
		}
	};
	setInitialState = () => {
		if (this.props.data.openedDialog) {
			this.props.setOpenedDialog(false);
		}
		this.props.setDateTimer({
			dateValue: null,
			meridiem: '',
			hourValue: '',
		});
		this.setState({
			open: false,
			activeStep: 0,
			body: '',
			fileFormData: '',
			fileFormData2: '',
			postFormData: {},
			fileName: '',
			fileName2: '',
			stateFileToUpload: null,
			stateFileToUpload2: null,
			publicPost: true,
			releaseNow: true,
			notify: false,
			fileType: 'text',
			fileType2: '',
			mimetype: null,
			step2Errors: false,
			step3Errors: false,
			step4Errors: false,
			showUsers: true,
			showFollowers: false,
			showFollowing: false,
			queryField: 'Users',
			openCloseConfirmation: false,
			editing: false,
			setUndoDeletedFile: false,
			setUndoDeletedFile2: false,
			postCreatedAt: '',
			postId: '',
			postUserHandle: '',
			editedReleaseDate: '',
			editPostText: false,
			postToEditLivePost: false,
			saveImage: false,
			exceededFileSize: '',
			mentions: [],
			mentionsError: '',
			hashtagsError: '',
			loadingHeic: false,
			fileErrors: '',
			deletedFileFormData: '',
			deletedFileName: '',
			deletedStateFileToUpload: '',
			deletedFileType: '',
			deletedFileFormData2: '',
			deletedFileName2: '',
			deletedStateFileToUpload2: '',
			deletedFileType2: '',
			recipientType: 'user',
			emailErrors: {},
			recipientEmail: '',
		});
	};
	handleChange = (e) => {
		if (this.state.step2Errors === true) {
			this.setState({ step2Errors: false });
		}
		this.setState({ body: e });
	};

	setPostMentions = (data) => {
		if (this.state.mentionsError && data.length <= 15) {
			this.setState({
				mentionsError: '',
			});
		}
		this.setState({
			mentions: data,
		});
	};

	handleNext = () => {
		let data = this.props.data;
		let state = this.state;
		let setMentions = [];
		let hashtags = [];

		if (this.state.mentionsError) {
			this.setState({
				mentionsError: '',
			});
		}

		if (this.state.hashtagsError) {
			this.setState({
				hashtagsError: '',
			});
		}

		if (state.activeStep <= 1) {
			if (state.fileType === 'image') {
				this.setState({ saveImage: true });
			}
		}

		if (
			state.notify === false &&
			(data.postRecipients.length > 0 || data.emailRecipients.length > 0)
		) {
			//delete recipients if I move forward through steps and I decide not to notify
			this.props.deleteRecipients({}, true);
		}

		if (state.activeStep === 2 && state.body.length > 0) {
			if (state.mentions.length > 0) {
				var mentionPattern = /\B@[a-z0-9_-]+/gi;
				let matches = state.body.match(mentionPattern);
				if (matches) {
					setMentions = state.mentions.filter((mention) =>
						matches.includes(`@${mention.handle}`),
					);

					this.setState({ mentions: setMentions });
				}
			}

			const hashtagPattern = /(#(?:[^\x00-\x7F]|\w)+)/g;
			let matches = state.body.match(hashtagPattern);
			if (matches) {
				matches.forEach((tag) => {
					if (
						this.props.data.hashtags.findIndex((obj) => obj.hashtag === tag) ===
						-1
					) {
						return hashtags.push({
							hashtag: tag.toLowerCase().substring(1),
							count: '',
						});
					}
				});

				this.props.setHashtags(hashtags);
			} else if (this.props.data.hashtags.length > 0) {
				this.props.setHashtags([]);
			}
		}

		const textAreaId =
			this.props.UI.mode === 'dark' ? 'darkTextArea_MP' : 'textArea_MP';

		if (setMentions.length > 15) {
			this.setState({
				mentionsError: t('only_tag_users', {
					number: 15,
				}),
			});
			this.focus(textAreaId);
		} else if (hashtags.length > 15) {
			this.setState({
				hashtagsError: t('only_15_hashtags'),
			});
			this.focus(textAreaId);
		} else if (
			state.activeStep === 2 &&
			state.fileType === 'text' &&
			state.body === '' &&
			data.editedAudioFile === null
		) {
			this.setState({ step2Errors: true });
			this.focus(textAreaId);
		} else if (state.activeStep === 3 && this.state.recipientEmail !== '') {
			this.addRecipientEmail(true);
		} else if (
			state.activeStep === 3 &&
			data.postRecipients.length === 0 &&
			data.emailRecipients.length === 0 &&
			state.publicPost === false
		) {
			toast.info(t('select_recipients'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'addRecipientEmail',
			});

			this.scrollToTop('smooth');
			this.setState({ step3Errors: true });
		} else if (
			state.activeStep === 4 &&
			this.props.data.dateTimer.dateValue === null &&
			state.releaseNow === false
		) {
			this.setState({ step4Errors: true });
		} else {
			this.scrollToTop('instant');
			this.setState((prevState) => ({
				activeStep: prevState.activeStep + 1,
			}));
		}
	};

	handleBack = () => {
		this.scrollToTop('instant');

		let state = this.state;
		if (state.activeStep === 2) {
			if (state.fileType === 'image') {
				this.setState({ saveImage: false });
			}
		}

		if (this.state.step2Errors === true) {
			this.setState({ step2Errors: false });
		}
		this.setState((prevState) => ({
			activeStep: prevState.activeStep - 1,
		}));
	};
	handleFileChangeAndSubmit = async (event, submit, fileList) => {
		if (event) {
			event.preventDefault();
		}
		let state = this.state;
		let data = this.props.data;
		let dateTimer = this.props.data.dateTimer;
		if (submit) {
			let livePost = state.releaseNow ? true : false;
			let recipients =
				data.postRecipients.length > 0 &&
				data.postRecipients.map((user) => user.userId);

			let releaseDate =
				(state.releaseNow && !state.editing) ||
				(state.releaseNow && state.editing && !state.postToEditLivePost)
					? 'now'
					: state.postToEditLivePost && state.releaseNow && state.editing
					? state.editedReleaseDate
					: new Date(dateTimer.dateValue).toISOString();
			let postData = {
				publicPost: state.publicPost,
				livePost: livePost,
				releaseDate: releaseDate,
				fileType: state.fileType,
				fileType2: state.fileType2,
			};
			if (state.postId) {
				postData.postId = state.postId;
			}
			if (recipients.length > 0 && this.state.notify) {
				postData.recipients = recipients;
			} else {
				postData.recipients = [];
			}
			if (this.props.data.emailRecipients.length > 0 && this.state.notify) {
				postData.emailRecipients = this.props.data.emailRecipients;
			} else {
				postData.emailRecipients = [];
			}

			postData.body = state.body.trim();

			if (state.mentions.length > 0 || state.editing) {
				const uniqueArray = state.mentions.filter((item, index, self) => {
					// Create a string representation of the userId and handle
					const key = `${item.userId}-${item.handle}`;
					// Find the first occurrence of this key
					return (
						self.findIndex((obj) => `${obj.userId}-${obj.handle}` === key) ===
						index
					);
				});
				postData.mentions = uniqueArray;
			}
			if (this.props.data.hashtags.length > 0 || state.editing) {
				const onlyHashtags = this.props.data.hashtags.map((obj) => obj.hashtag);
				postData.hashtags = [...new Set(onlyHashtags)];
			}

			let handle =
				this.props.params.handle === undefined
					? this.props.user.credentials.handle
					: this.props.params.handle;
			if (state.editing) {
				let postToEdit = data.postToEdit;
				let editedRecipients = false;
				let editedEmailRecipients = false;
				let editedMentions = false;
				let editedHashtags = false;

				if (!isEqual(postToEdit.mentions, postData.mentions)) {
					editedMentions = true;
				}

				if (!isEqual(postToEdit.hashtags, postData.hashtags)) {
					editedHashtags = true;
				}

				let postRecipients =
					data.postRecipients.length > 0 &&
					data.postRecipients.map((user) => user.userId);

				if (!isEqual(postToEdit.recipients, postRecipients)) {
					editedRecipients = true;
				}

				if (!isEqual(postToEdit.emailRecipients, postData.emailRecipients)) {
					editedEmailRecipients = true;
				}

				if (
					postToEdit.body !== postData.body ||
					postToEdit.fileType !== postData.fileType ||
					postToEdit.fileType2 !== postData.fileType2 ||
					postToEdit.livePost !== postData.livePost ||
					postToEdit.publicPost !== postData.publicPost ||
					postToEdit.releaseDate !==
						new Date(dateTimer.dateValue).toISOString() ||
					editedRecipients ||
					editedEmailRecipients ||
					editedMentions ||
					editedHashtags
				) {
					let editedLivePost = undefined;
					if (postToEdit.livePost !== postData.livePost) {
						editedLivePost = postData.livePost;
					}

					postData.postCollection = state.postToEditLivePost
						? 'posts'
						: 'unreleasedPosts';

					this.props.editPost({
						editedPost: postData,
						// history: this.props.history,
						handle,
						postId: state.postId,
						editedLivePost,
						location: this.props.params.handle,
						wasPublic: postToEdit.publicPost,
					});
				} else {
					this.props.setPostToEdit({});
				}
			} else {
				if (state.fileType === 'text' && state.fileType2 === '') {
					this.props.uploadPost(postData, this.props.user.credentials.handle);
				} else {
					let file;
					let file2;

					if (this.props.data.editedImageFileToUpload) {
						file = this.props.data.editedImageFileToUpload;
					} else if (
						state.fileType === 'audio' &&
						this.props.data.editedAudioFileToUpload
					) {
						file = this.props.data.editedAudioFileToUpload;
					} else if (state.fileType === 'audio' || state.fileType === 'video') {
						file = state.fileFormData;
					}

					if (
						(this.props.data.editedImageFileToUpload ||
							state.fileType === 'text') &&
						(this.props.data.editedAudioFileToUpload || state.fileFormData2)
					) {
						const audioFile = this.props.data.editedAudioFileToUpload
							? this.props.data.editedAudioFileToUpload
							: state.fileFormData2;
						if (state.fileType === 'text') {
							file = audioFile;
							postData.fileType = 'audio';
							postData.fileType2 = '';
						} else {
							file2 = audioFile;
						}
					}
					this.props.uploadFile({
						userId: this.props.user.credentials.userId,
						handle: this.props.user.credentials.handle,
						data: postData,
						file: file,
						file2: file2,
					});
				}
			}
			scrollToTopFunc('instant');
			this.setInitialState();
		} else if (event.target.name !== undefined || fileList) {
			let location;
			let file;
			if (event.target.name || fileList) {
				location = event.target.name ? event.target.files : fileList;
				file = location[0];
			}

			const fileName = trimFileName(file.name);

			const fileType = file.type.split('/').shift();
			const mimetype = file.type;

			if (fileType === 'image' && file.size >= '15,728,640') {
				this.setState({
					exceededFileSize: t('less_than_15_mb'),
					fileDragOver: false,
				});
			} else if (
				(fileType === 'audio' || fileType === 'video') &&
				file.size >= '536,870,912'
			) {
				this.setState({
					exceededFileSize: t('less_than_512_mb'),
					fileDragOver: false,
				});
			} else if (file.type === 'image/heic') {
				this.setState({ loadingHeic: true });
				try {
					// Convert HEIC to Blob
					const heicBlob = await heic2any({
						blob: file,
						toType: 'image/jpeg',
						// quality: 1, // number, between 0 and 1
					});
					const blobUrl = URL.createObjectURL(heicBlob);
					// const originalFileName =
					// 	file.name.slice(0, 8) +
					// 	'...' +
					// 	file.name.slice(file.name.lastIndexOf('.'));

					const newFileName = this.changeFileType(fileName, 'jpeg');
					if (fileType === 'image' && this.state.fileType === 'text') {
						this.setState({
							loadingHeic: false,
							stateFileToUpload: blobUrl,
							fileName: newFileName,
							fileType: 'image', // Treat as JPEG after conversion
							mimetype: 'image/jpeg',
							fileFormData: file,
							fileDragOver: false,
							exceededFileSize: '',
							fileErrors: '',
						});
					} else {
						this.setState({
							loadingHeic: false,
							stateFileToUpload2: blobUrl,
							fileName2: newFileName,
							fileType2: 'image', // Treat as JPEG after conversion
							mimetype: 'image/jpeg',
							fileFormData: file,
							fileDragOver: false,
							exceededFileSize: '',
							fileErrors: '',
						});
					}
				} catch (error) {
					// console.error('Error converting HEIC to JPEG:', error);
					this.setState({
						fileErrors: t('error_heic'),
						fileDragOver: false,
						loadingHeic: false,
					});
				}

				return;
			} else if (
				fileType === 'audio' ||
				fileType === 'video' ||
				mimetype === 'image/jpg' ||
				mimetype === 'image/jpeg' ||
				mimetype === 'image/png' ||
				mimetype === 'image/tiff' ||
				mimetype === 'image/webp' ||
				mimetype === 'image/gif' ||
				mimetype === 'image/avif'
			) {
				const blobUrl = URL.createObjectURL(file);

				if (fileType === 'image') {
					const testImage = new Image();
					testImage.src = blobUrl;
					// testImage.onload = () => {
					// };
					testImage.onerror = () => {
						this.setState({
							fileErrors: t('file_corrupted'),
							fileDragOver: false,
						});
					};
				}

				if (
					state.fileType === 'audio' &&
					(fileType === 'audio' || fileType === 'video')
				) {
					this.setState({
						fileErrors: t('only_add_image'),
						fileDragOver: false,
					});
				} else if (
					fileType === 'image' &&
					state.fileType === 'audio' &&
					(this.props.data.editedAudioFile !== null ||
						this.state.stateFileToUpload !== null)
				) {
					this.setState({
						stateFileToUpload: blobUrl,
						fileType: fileType,
						mimetype: mimetype,
						fileFormData: file,
						fileName: fileName,
						stateFileToUpload2: state.stateFileToUpload,
						fileType2: state.fileType,
						fileFormData2: state.fileFormData,
						fileName2: state.fileName,
						fileDragOver: false,
						exceededFileSize: '',
						fileErrors: '',
						deletedFileFormData: '',
						deletedFileName: '',
						deletedStateFileToUpload: '',
						deletedFileType: '',
						setUndoDeletedFile: false,
					});
				} else if (
					fileType === 'audio' &&
					state.fileType === 'image' &&
					this.state.stateFileToUpload !== null
				) {
					if (this.props.data.deletedAudioFile) {
						this.props.clearAudioFile();
					}
					this.setState({
						stateFileToUpload2: blobUrl,
						fileType2: fileType,
						fileFormData2: file,
						fileName2: fileName,
						fileDragOver: false,
						exceededFileSize: '',
						fileErrors: '',
						deletedFileFormData2: '',
						deletedFileName2: '',
						deletedStateFileToUpload2: '',
						deletedFileType2: '',
						setUndoDeletedFile2: false,
					});
				} else {
					const userAgent = window.navigator.userAgent;
					const fileErrors =
						mimetype === 'video/quicktime' &&
						userAgent.includes('Chrome') &&
						!userAgent.includes('Edg')
							? t('chrome_mov_error')
							: '';

					this.setState({
						stateFileToUpload: blobUrl,
						fileType: fileType,
						mimetype: mimetype,
						fileFormData: file,
						fileName: fileName,
						fileDragOver: false,
						exceededFileSize: '',
						fileErrors: fileErrors,
						deletedFileFormData: '',
						deletedFileName: '',
						deletedStateFileToUpload: '',
						deletedFileType: '',
						setUndoDeletedFile: false,
					});
				}

				event.target.value = '';
				URL.revokeObjectURL(location[0]);
			} else {
				this.setState({
					fileErrors: t('suported_files'),
					fileDragOver: false,
				});
			}
		}
	};
	changeFileType = (fileName, newExtension) => {
		const parts = fileName.split('.');
		parts.pop(); // Remove the current extension
		parts.push(newExtension); // Add the new extension
		return parts.join('.');
	};

	handleOpenFile = (e, id) => {
		e.preventDefault();
		const fileInput = document.getElementById(id);
		fileInput.click();
	};
	undoDeletedFile = (secondFile) => {
		let postToEdit = this.props.data.postToEdit;
		// deletedFileFormData

		if (secondFile === true) {
			let type = this.state.editing
				? postToEdit.fileType2
				: this.state.deletedFileType2;
			let file = this.state.editing
				? postToEdit.fileUrl2
				: this.state.deletedStateFileToUpload2;

			this.setState({
				setUndoDeletedFile2: false,
				stateFileToUpload2: file,
				fileType2: type,
				fileName2: this.state.deletedFileName2,
				fileFormData2: this.state.deletedFileFormData2,
			});
		} else {
			let type = this.state.editing
				? postToEdit.fileType
				: this.state.deletedFileType;
			let file = this.state.editing
				? postToEdit.fileUrl
				: this.state.deletedStateFileToUpload;
			this.setState({
				setUndoDeletedFile: false,
				stateFileToUpload: file,
				fileType: type,
				fileName: this.state.deletedFileName,
				fileFormData: this.state.deletedFileFormData,
			});
		}
	};
	handleDeleteFile = (secondFile) => {
		let state = this.state;
		// fileFormData2;
		if (secondFile === true) {
			this.setState({
				fileFormData2: '',
				fileName2: '',
				stateFileToUpload2: null,
				fileType2: '',
				deletedFileFormData2: state.fileFormData2,
				deletedFileName2: state.fileName2,
				deletedStateFileToUpload2: state.stateFileToUpload2,
				deletedFileType2: state.fileType2,
			});
			if (state.fileType2 === 'image' && this.props.data.editedImageFile) {
				this.props.resetImageFile();
			} else if (state.fileType === 'audio') {
				this.setState({
					fileType: 'text',
				});
			}
		} else if (state.fileType) {
			this.setState({
				fileFormData: '',
				fileName: '',
				stateFileToUpload: null,
				fileType: 'text',
				deletedFileFormData: state.fileFormData,
				deletedFileName: state.fileName,
				deletedStateFileToUpload: state.stateFileToUpload,
				deletedFileType: state.fileType,
			});
			if (state.fileType === 'image' && this.props.data.editedImageFile) {
				this.props.resetImageFile();
			}
		}

		// if (this.state.editing) {
		if (secondFile === true) {
			this.setState({ setUndoDeletedFile2: true });
		} else {
			this.setState({ setUndoDeletedFile: true });
		}
	};

	setFileType = (type, restart) => {
		if (this.state.fileType === 'text') {
			this.setState({
				fileType: type ? type : 'text',
				deletedFileFormData: '',
				deletedFileName: '',
				deletedStateFileToUpload: '',
				deletedFileType: '',
			});
			if (restart) {
				this.setState({
					setUndoDeletedFile: false,
				});
			}
		} else {
			this.setState({
				fileType2: type,
				deletedFileFormData2: '',
				deletedFileName2: '',
				deletedStateFileToUpload2: '',
				deletedFileType2: '',
			});
			if (restart) {
				this.setState({
					setUndoDeletedFile2: false,
				});
			}
		}
		if (
			this.state.deletedFileType === 'audio' ||
			this.state.deletedFileType === 'video'
		) {
			this.setState({
				setUndoDeletedFile: false,
			});
		}
	};

	setDragOver = (bool) => {
		if (this.state.fileDragOver !== bool) {
			this.setState({ fileDragOver: bool });
		}
	};
	delRecipient = (recipient) => {
		if (this.state.step3Errors) {
			this.setState({ step3Errors: false });
		}
		if (
			((this.props.data.postRecipients.length === 1 &&
				this.props.data.emailRecipients.length === 0) ||
				(this.props.data.postRecipients.length === 0 &&
					this.props.data.emailRecipients.length === 1)) &&
			this.state.activeStep === 5 &&
			!this.state.publicPost
		) {
			toast.info(t('select_recipients'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'addRecipientEmail',
			});
			this.scrollToTop('smooth');

			this.setState({
				activeStep: 3,
				step3Errors: true,
			});
		}
		this.props.deleteRecipients(recipient);
	};

	openDateTimePicker = (event) => {
		event.preventDefault();
		const dateInput = document.getElementById('dateInputClicker');
		dateInput.click();
	};

	radioChange = (event) => {
		let queryField = event.target.value;
		if (this.props.data.queriedUsers.length > 0) {
			this.props.clearQueriedUsers();
		}
		if (queryField === 'Users') {
			this.setState({
				showUsers: true,
				showFollowers: false,
				showFollowing: false,
				queryField: queryField,
			});
		} else if (queryField === 'Followers') {
			this.setState({
				showUsers: false,
				showFollowers: true,
				showFollowing: false,
				queryField: queryField,
			});
		} else if (queryField === 'Following') {
			this.setState({
				showUsers: false,
				showFollowers: false,
				showFollowing: true,
				queryField: queryField,
			});
		}
	};

	scrollToBottom = () => {
		setTimeout(() => {
			this.dialogContent.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}, 33);
	};
	scrollToTop = (behaviour) => {
		setTimeout(() => {
			this.dialogContent.scrollIntoView({
				behavior: behaviour,
				block: 'start',
			});
		}, 33);
	};
	clearFileErrors = () => {
		this.setState({
			fileErrors: '',
		});
	};

	handleChangeEmailRecipient = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

		if (this.state.emailErrors.error !== '') {
			this.setState({
				emailErrors: {},
			});
		}
	};

	focus = (target) => {
		document.getElementById(target).focus();
	};

	addRecipientEmail = (next) => {
		this.setState({
			recipientType: 'email',
		});

		if (!isEmail(this.state.recipientEmail)) {
			this.setState({
				emailErrors: { email: t('email_error_invalid_address') },
			});
			setTimeout(() => {
				this.focus('recipientEmail');
			}, 300);
		} else if (
			this.state.recipientEmail.toLowerCase() ===
			this.props.user.credentials.email
		) {
			toast.info(t('you_will_be_notified'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'addRecipientEmail',
			});
		} else if (this.props.data.emailRecipients.length < 15) {
			this.setState({ recipientEmail: '', step3Errors: false });
			this.props.addRecipients({ email: this.state.recipientEmail }, 'email');
			if (next === true) {
				this.setState((prevState) => ({
					activeStep: prevState.activeStep + 1,
				}));
			}
		} else {
			toast.info(t('only_15_email_recipients'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'recipientsExceeded',
			});
		}
	};

	render() {
		const {
			data: {
				uploadingPost,
				errorUploadingPost,
				postRecipients,
				emailRecipients,
				editedImageFile,
				editedAudioFile,
				deletedAudioFile,
				deletedEditedAudioFileToUpload,
				loadingPostRecipientsData,
				errorLoadingPostRecipientsData,
				postToEdit,
				tagFollowers,
				hashtags,
				editedAudioFileToUpload,
				dateTimer: { dateValue },
			},
			user: {
				credentials: { userId, followerCount, followingCount, postsMadeToday },
			},
			UI: { mode },
			feed,
		} = this.props;

		const {
			fileErrors,
			open,
			activeStep,
			fileName,
			fileName2,
			deletedFileName,
			deletedFileName2,
			stateFileToUpload,
			stateFileToUpload2,
			deletedStateFileToUpload2,
			publicPost,
			notify,
			step2Errors,
			step3Errors,
			step4Errors,
			body,
			releaseNow,
			showUsers,
			showFollowers,
			showFollowing,
			queryField,
			fileType,
			fileType2,
			deletedFileType,
			deletedFileType2,
			mimetype,
			openCloseConfirmation,
			postCreatedAt,
			editing,
			editedReleaseDate,
			setUndoDeletedFile,
			setUndoDeletedFile2,
			editPostText,
			saveImage,
			fileDragOver,
			exceededFileSize,
			mentions,
			mentionsError,
			hashtagsError,
			loadingHeic,
			recipientType,
			emailErrors,
			recipientEmail,
		} = this.state;

		const fileAlert = (
			<Grow in={true}>
				<div>
					<CustomAlerts
						info={true}
						severity={setUndoDeletedFile ? 'warning' : 'success'}
						icon={
							fileType === 'image' ||
							deletedFileType === 'image' ||
							(editing && postToEdit.fileType === 'image') ? (
								<ImageIcon />
							) : fileType === 'audio' ||
							  deletedFileType === 'audio' ||
							  (editing && postToEdit.fileType === 'audio') ? (
								<GraphicEqIcon />
							) : fileType === 'video' ||
							  deletedFileType === 'video' ||
							  (editing && postToEdit.fileType === 'video') ? (
								<VideoCameraFrontIcon />
							) : (
								false
							)
						}
						alertId='smallMuiAlert'
						message={
							editing && !setUndoDeletedFile
								? postToEdit.fileType
								: editing && setUndoDeletedFile
								? `${t('delete')} ${postToEdit.fileType}`
								: deletedFileName
								? deletedFileName
								: fileName
						}
						tooltip={setUndoDeletedFile ? t('undo') : t('delete')}
						closeIcon={
							!setUndoDeletedFile ? (
								<DeleteOutlineIcon />
							) : setUndoDeletedFile ? (
								<ReplayIcon />
							) : null
						}
						onClose={
							!setUndoDeletedFile
								? this.handleDeleteFile
								: editing || setUndoDeletedFile
								? this.undoDeletedFile
								: null
						}
						close={true}
						secondTooltip={t('delete')}
						secondClose={setUndoDeletedFile}
						secondIcon={<DeleteOutlineIcon />}
						secondAction={() => this.clearDeletedFile(fileType)}
						noMargin
					/>
				</div>
			</Grow>
		);

		return (
			<Media
				queries={{
					small: '(max-width: 600px)',
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<Fragment>
						<CustomButton
							onClick={this.handleOpen}
							variant='contained'
							btnText={t('post')}
							margin={feed ? '1rem auto' : '1rem auto'}
							id='customInfoButton'
							startIcon={<ImageIcon />}
							className={feed ? 'fullWidth' : ''}
						/>
						{open && (
							<Dialog
								open={open}
								onClose={this.handleDialog}
								disableEnforceFocus
								fullScreen
								style={{ zIndex: '6' }}
								// disableAutoFocus
							>
								<RefreshSite />
								<Toolbar id='dialogBar'>
									<div className='titleToolbar'>
										{editing ? (
											<span>
												{t('edit_post')}
												<small>
													{postToEdit.edited > 0
														? ` (${postToEdit.edited} ${
																postToEdit.edited === 1
																	? t('edition')
																	: t('editions')
														  })`
														: ''}
												</small>
											</span>
										) : (
											<span>{t('your_post')}</span>
										)}
									</div>
									<CloseIcon
										id='closeDialogButton'
										onClick={this.handleDialog}
										aria-label='close'
									/>

									{openCloseConfirmation && (
										<ConfirmationDialog
											open={openCloseConfirmation}
											openFunc={this.handleDialog}
											closeFunc={this.handleDialog}
											actionBtnText={t('exit')}
											startIcon={<LogoutIcon />}
											cancelBtnIcon={<EditIcon />}
											customButtonId='customYellowButton'
											cancelBtnText={t('continue_editing')}
											disableRipple
											variant='none'
											actionBtnMargin='1rem 0'
											action={() => this.handleDialog(true)}
											phrase={t('exit')}
											contentTitle={t('all_changes_will_be_lost')}
										/>
									)}
								</Toolbar>

								<MobileStepper
									variant='progress'
									steps={6}
									position='static'
									activeStep={activeStep}
									id='stepper_MP'
									nextButton={
										<Button
											onClick={this.handleNext}
											disabled={
												postsMadeToday >= 30 ||
												uploadingPost ||
												activeStep === 5
											}
											type='submit'
											id='mgl'
										>
											{t('next')}
											<KeyboardArrowRight />
										</Button>
									}
									backButton={
										<Button
											onClick={this.handleBack}
											disabled={
												postsMadeToday >= 30 ||
												activeStep <= 1 ||
												(activeStep === 2 && editPostText)
											}
											id='mgr'
										>
											<KeyboardArrowLeft />
											{t('back')}
										</Button>
									}
								/>
								<DialogContent className='skyBackground' sx={{ padding: 0 }}>
									<div
										ref={(node) => {
											this.dialogContent = node;
										}}
									>
										<DialogContent
											id='scrollableMakePostDiv'
											sx={{ padding: matches.isMobile ? 0 : 2 }}
										>
											<div className='postTitles'>
												{postsMadeToday >= 30 ? (
													<div>
														<CustomAlerts
															severity='warning'
															info={t('you_have_exceeded_daily_limit_action')}
															margin='0'
															noBorder={matches.isMobile}
															alertId='smallMuiAlert'
														/>
														<CustomAlerts
															info={t('security_anti_spam_measures')}
															icon={<SecurityIcon />}
															margin='1rem 0 0'
															noBorder={matches.isMobile}
															alertId='smallMuiAlert'
														/>
													</div>
												) : activeStep <= 1 && !editPostText ? (
													<CustomAlerts
														info={true}
														message={<span>{t('media_content')}</span>}
														icon={<PermMediaIcon />}
														margin='0'
														noBorder={matches.isMobile}
													/>
												) : activeStep === 2 ? (
													<CustomAlerts
														info={true}
														message={<span>{t('text')}</span>}
														icon={<NotesIcon />}
														margin='0'
														noBorder={matches.isMobile}
													/>
												) : activeStep === 3 ? (
													<CustomAlerts
														info={true}
														message={<span>{t('select_visibility')}</span>}
														icon={
															publicPost ? (
																<LockOpenRoundedIcon />
															) : (
																<Groups3Icon />
															)
														}
														margin='0'
														noBorder={matches.isMobile}
													/>
												) : activeStep === 4 ? (
													<CustomAlerts
														info={true}
														message={
															<span>
																{editing && postToEdit.livePost && releaseNow
																	? t('release_on')
																	: releaseNow
																	? t('release_now')
																	: isSameDay(dateValue, new Date().getTime())
																	? t('release_today')
																	: t('select_release_date')}
															</span>
														}
														margin='0'
														noBorder={matches.isMobile}
													/>
												) : (
													<CustomAlerts
														success={true}
														message={t('preview')}
														margin='0'
														noBorder={matches.isMobile}
														icon={<BlurOnRoundedIcon />}
													/>
												)}
											</div>

											<div style={dim(postsMadeToday >= 30)}>
												<Paper elevation={6} id='mp_Paper'>
													{activeStep <= 1 && (
														<div>
															{activeStep === 1 && matches.isMobile && (
																<CameraPermission />
															)}
															<CustomAlerts
																error={exceededFileSize}
																noMargin
																alertId='smallMuiAlert'
															/>

															{fileErrors === t('suported_files') && (
																<CustomAlerts
																	error={fileErrors}
																	message={t('unsupported_ext')}
																	noMargin
																	alertId='smallMuiAlert'
																/>
															)}

															<CustomAlerts
																info={fileErrors}
																severity='warning'
																close
																noMargin
																onClose={this.clearFileErrors}
																alertId='smallMuiAlert'
															/>
															{fileType !== 'audio' &&
															(editing ||
																setUndoDeletedFile ||
																fileName ||
																stateFileToUpload)
																? fileAlert
																: null}
														</div>
													)}

													{!editing &&
														fileType === 'image' &&
														stateFileToUpload !== null && (
															<ImageEditor
																fileSrc={stateFileToUpload}
																mimetype={mimetype}
																fileName={fileName}
																saveImage={saveImage}
																activeStep={activeStep}
																component='MakePost'
																scrollToBottom={
																	matches.isMobile ? this.scrollToBottom : null
																}
															/>
														)}
													{activeStep <= 1 && !editPostText ? (
														<div>
															<form encType='multipart/form-data'>
																{(fileType === 'text' ||
																	fileType === 'image') &&
																	fileType2 === '' &&
																	!editedAudioFileToUpload && (
																		<input
																			type='file'
																			accept='audio/*'
																			id='audioUploadInput'
																			hidden='hidden'
																			onChange={this.handleFileChangeAndSubmit}
																			name='audio'
																		/>
																	)}
																{fileType !== 'image' &&
																	fileType !== 'video' &&
																	!editing && (
																		<div>
																			<input
																				type='file'
																				name='file'
																				id='fileInput'
																				hidden='hidden'
																				accept={
																					(fileType !== 'text' &&
																						fileType2 !== '') ||
																					editedAudioFileToUpload
																						? 'image/*'
																						: ''
																				}
																				onChange={
																					this.handleFileChangeAndSubmit
																				}
																			/>

																			{!loadingHeic && !matches.isMobile && (
																				<div>
																					<input
																						type='file'
																						accept='image/*'
																						id='imageUploadInput'
																						hidden='hidden'
																						onChange={
																							this.handleFileChangeAndSubmit
																						}
																						name='image'
																					/>

																					{fileType === 'text' &&
																						fileType2 === '' &&
																						!editedAudioFileToUpload && (
																							<input
																								type='file'
																								accept='video/*'
																								id='videoUploadInput'
																								hidden='hidden'
																								onChange={
																									this.handleFileChangeAndSubmit
																								}
																								name='video'
																							/>
																						)}
																				</div>
																			)}

																			<BackdropUi
																				open={loadingHeic}
																				circularProgress={true}
																			>
																				<div className='pd'>
																					<FileDrop
																						onDragOver={() =>
																							this.setDragOver(true)
																						}
																						onDragLeave={() =>
																							this.setDragOver(false)
																						}
																						onDrop={(files, event) =>
																							this.handleFileChangeAndSubmit(
																								event,
																								false,
																								files,
																							)
																						}
																						disabled={postsMadeToday >= 30}
																					>
																						<p
																							onClick={(e) =>
																								this.handleOpenFile(
																									e,
																									'fileInput',
																								)
																							}
																							className={`
											pointer file-msg ${fileDragOver ? 'dropArea_active' : 'dropArea_inactive'}
										`}
																						>
																							{loadingHeic
																								? ''
																								: matches.isMobile
																								? t('add_file')
																								: fileDragOver
																								? t('release_to_upload')
																								: fileType !== 'text' ||
																								  fileType2 !== '' ||
																								  editedAudioFileToUpload
																								? t('drag_and_drop_image')
																								: t('drag_and_drop')}
																						</p>
																					</FileDrop>
																				</div>
																			</BackdropUi>

																			{matches.isMobile && (
																				<div className='flex center'>
																					<CustomButton
																						onClick={this.handleNext}
																						variant='text'
																						btnText={t('skip_to_text')}
																						margin='0 0 5px 0'
																						startIcon={
																							<TextSnippetOutlinedIcon />
																						}
																						disabled={postsMadeToday >= 30}
																					/>
																				</div>
																			)}
																		</div>
																	)}

																{stateFileToUpload !== null && (
																	<div>
																		{fileType === 'image' && editing ? (
																			<div
																				style={{
																					lineHeight: 0,
																				}}
																			>
																				<img
																					src={stateFileToUpload}
																					style={{
																						backgroundSize: 'cover',
																						cursor: 'pointer',
																					}}
																					alt={body}
																					id='imageContain'
																					loading='lazy'
																				/>
																			</div>
																		) : fileType === 'audio' ? (
																			<div>
																				{fileAlert}
																				<AudioPlayer
																					fileUrl={stateFileToUpload}
																					postId='preview'
																					waveColor='#7e7e7e'
																					makePostFunctionality
																				/>
																			</div>
																		) : fileType === 'video' ? (
																			<VideoPlayer
																				fileUrl={stateFileToUpload}
																				postId='preview'
																				dialogPost
																				makePostFunctionality
																			/>
																		) : null}
																	</div>
																)}

																{stateFileToUpload2 ||
																(deletedStateFileToUpload2 &&
																	!editedAudioFile) ? (
																	<div>
																		<Grow in={true}>
																			<div>
																				<CustomAlerts
																					info={true}
																					severity={
																						setUndoDeletedFile2
																							? 'warning'
																							: 'success'
																					}
																					icon={
																						fileType2 === 'audio' ||
																						deletedFileType2 === 'audio' ||
																						(editing &&
																							postToEdit.fileType2 ===
																								'audio') ? (
																							<GraphicEqIcon />
																						) : (
																							false
																						)
																					}
																					message={
																						editing && !setUndoDeletedFile2
																							? t(postToEdit.fileType2)
																							: editing && setUndoDeletedFile2
																							? `${t('delete')} ${t(
																									postToEdit.fileType2,
																							  )}`
																							: deletedFileName2
																							? deletedFileName2
																							: fileName2
																					}
																					tooltip={
																						setUndoDeletedFile2
																							? t('undo')
																							: t('delete')
																					}
																					closeIcon={
																						!setUndoDeletedFile2 ? (
																							<DeleteOutlineIcon />
																						) : setUndoDeletedFile2 ? (
																							<ReplayIcon />
																						) : null
																					}
																					onClose={() =>
																						!setUndoDeletedFile2
																							? this.handleDeleteFile(true)
																							: setUndoDeletedFile2
																							? this.undoDeletedFile(true)
																							: null
																					}
																					close={true}
																					secondTooltip={t('delete')}
																					secondClose={setUndoDeletedFile2}
																					secondIcon={<DeleteOutlineIcon />}
																					secondAction={() =>
																						this.clearDeletedFile('audio')
																					}
																					noMargin
																					alertId='smallMuiAlert'
																				/>
																			</div>
																		</Grow>
																		{!setUndoDeletedFile2 && (
																			<AudioPlayer
																				fileUrl={stateFileToUpload2}
																				postId='preview'
																				waveColor='#7e7e7e'
																				makePostFunctionality
																			/>
																		)}
																	</div>
																) : null}

																{!editing &&
																	((fileType === 'image' &&
																		(editedAudioFile || !stateFileToUpload2)) ||
																		(((!stateFileToUpload &&
																			!stateFileToUpload2) ||
																			editedAudioFile) &&
																			(fileType === 'text' ||
																				fileType === 'audio'))) && (
																		<div className='center'>
																			<AudioRecorder
																				makePostFunctionality
																				fileType={fileType}
																				setFileType={this.setFileType}
																				editedAudioFile={editedAudioFile}
																				deletedAudioFile={deletedAudioFile}
																				deletedEditedAudioFileToUpload={
																					deletedEditedAudioFileToUpload
																				}
																				severity={
																					setUndoDeletedFile2
																						? 'warning'
																						: 'success'
																				}
																				icon={<GraphicEqIcon />}
																				message={
																					editing && !setUndoDeletedFile2
																						? t(postToEdit.fileType2)
																						: editing && setUndoDeletedFile2
																						? `${t('delete')} ${t(
																								postToEdit.fileType2,
																						  )}`
																						: deletedFileName2
																						? deletedFileName2
																						: deletedAudioFile &&
																						  setUndoDeletedFile2
																						? t('recover_audio_recording')
																						: t('audio_recording')
																				}
																				tooltip={
																					setUndoDeletedFile2
																						? t('undo')
																						: t('delete')
																				}
																				closeIcon={
																					!setUndoDeletedFile2 ? (
																						<DeleteOutlineIcon />
																					) : setUndoDeletedFile2 ? (
																						<ReplayIcon />
																					) : null
																				}
																				secondTooltip={t('delete')}
																				secondClose={setUndoDeletedFile2}
																				secondIcon={<DeleteOutlineIcon />}
																				secondAction={() =>
																					this.clearDeletedFile('audio')
																				}
																				onClose={() =>
																					!setUndoDeletedFile2
																						? this.handleDeleteFile(true)
																						: setUndoDeletedFile2
																						? this.undoDeletedFile(true)
																						: null
																				}
																				openAudioInput={
																					fileType === 'image' &&
																					!editedAudioFile
																				}
																				clickAudioInput={(e) =>
																					this.handleOpenFile(
																						e,
																						'audioUploadInput',
																					)
																				}
																				disabled={postsMadeToday >= 30}
																			/>
																		</div>
																	)}
															</form>
														</div>
													) : activeStep === 2 ? (
														<form
															encType='multipart/form-data'
															id='textForm'
															style={{ overflow: 'hidden' }}
															ref={this.containerRefStep1}
														>
															<Slide
																in={activeStep === 2}
																direction='up'
																container={this.containerRefStep1.current}
															>
																<div>
																	<Collapse
																		in={
																			step2Errors ||
																			errorUploadingPost ||
																			mentionsError !== '' ||
																			hashtagsError !== ''
																		}
																	>
																		<CustomAlerts
																			info={step2Errors}
																			severity='warning'
																			message={t('text_required')}
																			noMargin
																			icon={<EditNoteIcon />}
																			alertId='smallMuiAlert'
																		/>
																		<CustomAlerts
																			error={mentionsError}
																			alertId='smallMuiAlert'
																		/>
																		<CustomAlerts
																			error={hashtagsError}
																			alertId='smallMuiAlert'
																		/>
																	</Collapse>

																	<CustomAlerts
																		info={
																			editing && postToEdit.sentToMentions >= 20
																		}
																		severity='warning'
																		message={t('content_spam', {
																			content: t('post'),
																			action: t('mentions'),
																		})}
																		icon={<AlternateEmailIcon />}
																		noMargin
																		alertId='smallMuiAlert'
																	/>
																	<CustomAlerts
																		info={
																			editing && postToEdit.hashtagsMade >= 20
																		}
																		severity='warning'
																		message={t('hashtag_post_spam')}
																		icon={<TagIcon />}
																		noMargin
																		alertId='smallMuiAlert'
																	/>
																	<CommentForm
																		postBodyChange={this.handleChange}
																		setPostMentions={this.setPostMentions}
																		postMentions={mentions}
																		postHashtags={hashtags}
																		canHash={
																			!editing || postToEdit.hashtagsMade < 20
																		}
																		canMention={
																			!editing || postToEdit.sentToMentions < 20
																		}
																		makePostFunctionality
																		makePostFocus={
																			!matches.isMobile
																			// ||
																			// (fileType === 'text' &&
																			// 	!stateFileToUpload &&
																			// 	!editedAudioFile)
																		}
																		tagFollowers={tagFollowers}
																		postBody={body}
																	/>
																</div>
															</Slide>
														</form>
													) : activeStep === 3 ? (
														<div
															style={{ overflow: 'hidden' }}
															ref={this.containerRefStep2}
														>
															<div>
																<div className='flex center spaceAround mgY'>
																	<div className='widthHalf'>
																		<FormControlLabel
																			onClick={() =>
																				!publicPost &&
																				this.handleChecked('publicPost', true)
																			}
																			control={<Radio checked={publicPost} />}
																			label={
																				<p className='mg0 bold'>
																					{t('public')}
																				</p>
																			}
																		/>
																		<p className='mgXH'>{t('public_desc')}</p>
																	</div>
																	<div className='widthHalf'>
																		<FormControlLabel
																			onClick={() =>
																				publicPost &&
																				this.handleChecked('publicPost', false)
																			}
																			control={<Radio checked={!publicPost} />}
																			label={
																				<p className='mg0 bold'>
																					{t('private')}
																				</p>
																			}
																		/>
																		<p className='mgXH'>{t('private_desc')}</p>
																	</div>
																</div>
																{publicPost ? (
																	<div>
																		<div>
																			<Divider className='bold'>
																				{t('optional')}
																			</Divider>
																			<p className='center mgY pdX'>
																				{t('email_notification_question')}
																			</p>
																		</div>

																		<div className='flex spaceEvenly mgY'>
																			<FormControlLabel
																				control={
																					<Radio
																						checked={notify}
																						onClick={() =>
																							!notify &&
																							this.handleChecked('notify', true)
																						}
																					/>
																				}
																				label={
																					<p className='mg0 bold'>{t('yes')}</p>
																				}
																				labelPlacement='bottom'
																			/>
																			<FormControlLabel
																				control={
																					<Radio
																						checked={!notify}
																						onClick={() =>
																							notify &&
																							this.handleChecked(
																								'notify',
																								false,
																							)
																						}
																					/>
																				}
																				label={
																					<p className='mg0 bold'>{t('no')}</p>
																				}
																				labelPlacement='bottom'
																			/>
																		</div>
																	</div>
																) : (
																	<div>
																		<Divider className='bold'>
																			{t('required')}
																		</Divider>
																		<p className={'center mgY pdX'}>
																			{loadingPostRecipientsData
																				? t('loading')
																				: t('select_who_can_view')}
																		</p>
																	</div>
																)}

																{(!publicPost || notify) && (
																	<div>
																		<CustomAlerts
																			alertId='smallMuiAlert'
																			success={t('choose_recipients')}
																			icon={<AccountCircleIcon />}
																			margin='0 0 1rem 0'
																		/>
																		<div className='flex spaceEvenly mgb center'>
																			<div className='widthHalf'>
																				<FormControlLabel
																					onClick={() =>
																						this.handleChecked(
																							'recipientType',
																							'user',
																						)
																					}
																					control={
																						<Radio
																							checked={recipientType === 'user'}
																						/>
																					}
																					labelPlacement='bottom'
																					label={
																						<p className='mgXH bold notranslate'>
																							{t('goodbyeapp')}
																						</p>
																					}
																				/>
																			</div>
																			<div className='widthHalf'>
																				<FormControlLabel
																					onClick={() =>
																						this.handleChecked(
																							'recipientType',
																							'email',
																						)
																					}
																					control={
																						<Radio
																							checked={
																								recipientType === 'email'
																							}
																						/>
																					}
																					labelPlacement='bottom'
																					label={
																						<p className='mgXH bold'>
																							{t('email_address')}
																						</p>
																					}
																				/>
																			</div>
																		</div>
																	</div>
																)}

																{errorLoadingPostRecipientsData ? (
																	<CustomAlerts
																		error={errorLoadingPostRecipientsData}
																		message={t('unable_to_load_recipients', {
																			number:
																				editing && postToEdit.recipients.length,
																		})}
																		alertId='smallMuiAlert'
																	/>
																) : loadingPostRecipientsData &&
																  editing &&
																  postToEdit.recipients.length > 0 ? (
																	<div className='pdX'>
																		<Recipients
																			avatars={true}
																			loadRecipients={
																				editing && postToEdit.recipients
																			}
																		/>
																	</div>
																) : notify && postRecipients.length > 0 ? (
																	<div className='pdX'>
																		<Recipients
																			recipients={postRecipients}
																			deleteRecipient={this.delRecipient}
																			avatars={true}
																		/>

																		<Recipients
																			recipients={postRecipients}
																			deleteRecipient={this.delRecipient}
																			message={
																				<div>
																					{publicPost && (
																						<div>{t('spam_email_warning')}</div>
																					)}
																				</div>
																			}
																		/>
																	</div>
																) : null}
																{notify && emailRecipients.length > 0 && (
																	<div>
																		<p className='mgX capitalize'>
																			{t('email_recipients')}:
																		</p>
																		<Paper
																			elevation={3}
																			sx={{
																				display: 'flex',
																				justifyContent: 'center',
																				flexDirection: 'row',
																				flexWrap: 'wrap',
																				listStyle: 'none',
																				p: 0.5,
																				m: 0,
																			}}
																			component='ul'
																		>
																			{emailRecipients.map((email, index) => {
																				return (
																					<ListItem
																						key={index}
																						sx={{ justifyContent: 'center' }}
																					>
																						<Chip
																							avatar={
																								<Avatar className='bold notranslate'>
																									{email
																										.charAt(0)
																										.toUpperCase()}
																								</Avatar>
																							}
																							variant='outlined'
																							color={
																								mode === 'dark'
																									? 'default'
																									: 'info'
																							}
																							label={
																								<p className='mg0 bold breakWord'>
																									{email}
																								</p>
																							}
																							onDelete={() =>
																								this.delRecipient({
																									email: email,
																								})
																							}
																						/>
																					</ListItem>
																				);
																			})}
																		</Paper>
																	</div>
																)}

																<CustomAlerts
																	info={
																		editing &&
																		postToEdit.sentTo >= 20 &&
																		recipientType === 'user'
																	}
																	severity='warning'
																	message={t('content_spam', {
																		content: t('post'),
																		action: t('recipients'),
																	})}
																	icon={<AccountCircleIcon />}
																	margin='1rem 0 0'
																	alertId='smallMuiAlert'
																/>
																{recipientType === 'user' &&
																	(notify || !publicPost) &&
																	(Object.keys(postToEdit).length === 0 ||
																		postToEdit.sentTo < 20) && (
																		<Grow
																			in={notify || !publicPost}
																			{...(notify || !publicPost
																				? { timeout: 800 }
																				: {})}
																		>
																			<section>
																				<div className='pdX'>
																					<RadioGroup
																						name='radio-buttons-group'
																						id='meridiem_mp'
																						value={queryField}
																						onChange={this.radioChange}
																					>
																						<FormControlLabel
																							value='Users'
																							control={<Radio />}
																							label={t('all_users')}
																						/>
																						<FormControlLabel
																							value='Followers'
																							control={<Radio />}
																							label={t(
																								'users_that_follow_you',
																								{
																									number: followerCount,
																								},
																							)}
																						/>
																						<FormControlLabel
																							value='Following'
																							control={<Radio />}
																							label={t('users_you_follow', {
																								number: followingCount,
																							})}
																						/>
																					</RadioGroup>
																				</div>
																				{showFollowers && (
																					<FollowerCount
																						makePostFunctionality
																						errorMP={step3Errors}
																					/>
																				)}
																				{showFollowing && (
																					<FollowingCount
																						makePostFunctionality
																						errorMP={step3Errors}
																					/>
																				)}
																				{showUsers && (
																					<Searchbar
																						makePostFunctionality
																						errorMP={step3Errors}
																					/>
																				)}
																			</section>
																		</Grow>
																	)}

																<CustomAlerts
																	info={
																		editing &&
																		postToEdit.sentToEmails >= 20 &&
																		recipientType === 'email'
																	}
																	severity='warning'
																	message={t('content_spam', {
																		content: t('post'),
																		action: t('email_recipients'),
																	})}
																	icon={<EmailIcon />}
																	margin='1rem 0 0'
																	alertId='smallMuiAlert'
																/>
																{recipientType === 'email' &&
																	(notify || !publicPost) &&
																	(Object.keys(postToEdit).length === 0 ||
																		postToEdit.sentToEmails < 20) && (
																		<div className='pdtExcept center'>
																			<CustomInput
																				variant='outlined'
																				type='email'
																				titleLabel={t('email_address')}
																				inputId='recipientEmail'
																				placeholder={t('email')}
																				name='recipientEmail'
																				autoComplete='email'
																				onChange={
																					this.handleChangeEmailRecipient
																				}
																				helperText={emailErrors.email}
																				inputError={
																					step3Errors || emailErrors.email
																						? true
																						: false
																				}
																				inputValue={recipientEmail}
																				inputAdornmentPosition='end'
																				maxLength={320}
																				fullWidth={true}
																				isEmail={isEmail(recipientEmail)}
																			/>

																			<CustomButton
																				btnText={t('add_email')}
																				startIcon={
																					<AddCircleOutlineSharpIcon />
																				}
																				margin='1rem 0 0'
																				variant={
																					isEmail(recipientEmail)
																						? 'outlined'
																						: 'text'
																				}
																				color={
																					isEmail(recipientEmail)
																						? 'primary'
																						: 'inherit'
																				}
																				onClick={this.addRecipientEmail}
																			/>
																		</div>
																	)}
															</div>
														</div>
													) : activeStep === 4 ? (
														<form
															encType='multipart/form-data'
															id='textForm'
															style={{ overflow: 'hidden' }}
															ref={this.containerRefStep3}
														>
															<div>
																{releaseNow ? (
																	<p className='pdX center '>
																		<EventAvailableRoundedIcon
																			id='mp_dateIcon'
																			color='primary'
																		/>
																		<span>
																			{editing &&
																			postToEdit.livePost &&
																			releaseNow
																				? dayjs(editedReleaseDate).format(
																						'LLLL',
																				  )
																				: dayjs(Date.now()).format('LLLL')}
																		</span>
																	</p>
																) : (
																	<p className='pdX center '>
																		<TodayRoundedIcon
																			onClick={this.openDateTimePicker}
																			id='mp_dateIconClicker'
																			color='primary'
																		/>
																		{dateValue !== null && (
																			<span>
																				{dayjs(dateValue).format(
																					'dddd, MMMM D, YYYY',
																				)}
																			</span>
																		)}
																	</p>
																)}
															</div>
															<div className='flex spaceAround center mgY'>
																<div>
																	<FormControlLabel
																		control={
																			<Radio
																				checked={releaseNow}
																				onClick={() =>
																					!releaseNow &&
																					this.handleChecked('releaseNow', true)
																				}
																			/>
																		}
																		label={
																			<p className='mg0 bold'>
																				{editing && postToEdit.livePost
																					? t('released_date')
																					: t('now')}
																			</p>
																		}
																		labelPlacement='bottom'
																	/>
																</div>
																{(!editing ||
																	(editing &&
																		(!postToEdit.releasedCount ||
																			postToEdit.releasedCount < 3))) && (
																	<div>
																		<FormControlLabel
																			control={
																				<Radio
																					checked={!releaseNow}
																					onClick={() =>
																						releaseNow &&
																						this.handleChecked(
																							'releaseNow',
																							false,
																						)
																					}
																				/>
																			}
																			label={
																				<p className='mg0 bold'>
																					{t('future')}
																				</p>
																			}
																			labelPlacement='bottom'
																		/>
																		{/* <p className='mgXH'>
																			Select a future date and time.
																		</p> */}
																	</div>
																)}
															</div>
															{!releaseNow && (
																<div>
																	<Divider>{t('up_to_30_years')}</Divider>

																	{step4Errors &&
																		postRecipients.length === 0 && (
																			<Grow
																				in={
																					step4Errors &&
																					postRecipients.length === 0
																				}
																				style={{
																					transformOrigin: 'top',
																					marginTop: '1rem',
																					marginBottom: '-1rem',
																				}}
																				{...(step4Errors
																					? { timeout: 800 }
																					: {})}
																			>
																				<CustomAlerts
																					error={t('select_future_date')}
																					alertId='smallMuiAlert'
																				/>
																			</Grow>
																		)}
																	<Slide
																		in={activeStep === 4}
																		direction='up'
																		container={this.containerRefStep3.current}
																	>
																		<div>
																			<DateAndTimePicker
																				makePostFunctionality
																			/>
																			{postRecipients.length > 0 && (
																				<p className='center mgtonly'>
																					{t('notified_on_release', {
																						content: t('post'),
																					})}
																				</p>
																			)}
																		</div>
																	</Slide>
																</div>
															)}
														</form>
													) : (
														<div>
															<AutocompleteHashtag
																makePostFunctionality
																postBodyChange={this.handleChange}
																postBody={body}
															/>
															<Post
																post={{
																	body: body.trim(),
																	publicPost: publicPost,
																	releaseDate: releaseNow
																		? 'now'
																		: new Date(dateValue).toISOString(),
																	fileType: fileType,
																	fileUrl:
																		fileType === 'image' &&
																		!editing &&
																		editedImageFile
																			? editedImageFile
																			: editedAudioFile
																			? editedAudioFile
																			: stateFileToUpload
																			? stateFileToUpload
																			: null,
																	fileType2: fileType2,
																	fileUrl2:
																		(fileType === 'image' ||
																			fileType === 'text') &&
																		editedAudioFile
																			? editedAudioFile
																			: // fileType === 'image' &&
																			stateFileToUpload2
																			? stateFileToUpload2
																			: null,
																	createdAt: postCreatedAt
																		? postCreatedAt
																		: Date.now(),
																	userId: userId,
																	postId: 'makePost',
																	previewPost: true,
																	livePost: releaseNow ? true : false,
																	hashtags: hashtags.map((obj) => obj.hashtag),
																	mentions: mentions,
																	recipients: [],
																	emailRecipients: [],
																}}
																makePostFunctionality
															/>
															{postRecipients.length > 0 && (
																<div className='pdtExcept'>
																	<Recipients
																		recipients={postRecipients}
																		deleteRecipient={this.delRecipient}
																		publicPost={publicPost}
																		message={t('notify')}
																	/>
																</div>
															)}
															{emailRecipients.length > 0 && (
																<div className='pdb'>
																	<Paper
																		elevation={3}
																		sx={{
																			display: 'flex',
																			justifyContent: 'center',
																			flexDirection: 'row',
																			flexWrap: 'wrap',
																			listStyle: 'none',
																			p: 0.5,
																		}}
																		component='ul'
																	>
																		{emailRecipients.map((email, index) => {
																			return (
																				<ListItem
																					key={index}
																					sx={{ justifyContent: 'center' }}
																				>
																					<Chip
																						avatar={
																							<Avatar className='bold notranslate'>
																								{email.charAt(0).toUpperCase()}
																							</Avatar>
																						}
																						variant='outlined'
																						color={
																							mode === 'dark'
																								? 'default'
																								: 'info'
																						}
																						label={
																							<p className='mg0 bold breakWord'>
																								{email}
																							</p>
																						}
																						onDelete={() =>
																							this.delRecipient({
																								email: email,
																							})
																						}
																					/>
																				</ListItem>
																			);
																		})}
																	</Paper>
																</div>
															)}

															<div className='pdbH'>
																<CustomButton
																	btnText={
																		activeStep === 5 && editing
																			? t('update')
																			: t('post')
																	}
																	margin='0 auto 1rem'
																	id='customInfoButton'
																	onClick={() =>
																		this.handleFileChangeAndSubmit(false, true)
																	}
																	startIcon={
																		<CheckCircleOutlineOutlinedIcon fontSize='small' />
																	}
																/>
															</div>
														</div>
													)}
												</Paper>
											</div>
										</DialogContent>
									</div>
								</DialogContent>
							</Dialog>
						)}
					</Fragment>
				)}
			</Media>
		);
	}
}

MakePost.propTypes = {
	editPost: PropTypes.func.isRequired,
	deleteRecipients: PropTypes.func.isRequired,
	clearQueriedUsers: PropTypes.func.isRequired,
	fetchRecipientsData: PropTypes.func.isRequired,
	setPostToEdit: PropTypes.func.isRequired,
	clearAudioFile: PropTypes.func.isRequired,
	clearPostUploadError: PropTypes.func.isRequired,
	setHashtags: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	setDateTimer: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
	uploadPost: PropTypes.func.isRequired,
	uploadFile: PropTypes.func.isRequired,
	resetImageFile: PropTypes.func.isRequired,
	setAllEmailRecipients: PropTypes.func.isRequired,
	addRecipients: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});
const mapActionsToProps = {
	editPost,
	deleteRecipients,
	clearQueriedUsers,
	setPostToEdit,
	clearAudioFile,
	clearPostUploadError,
	fetchRecipientsData,
	setHashtags,
	setDateTimer,
	setOpenedDialog,
	uploadPost,
	uploadFile,
	resetImageFile,
	setAllEmailRecipients,
	addRecipients,
};
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(MakePost));
