import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate } from 'react-router-dom';
import { Avatar, Checkbox } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import MailIcon from '@mui/icons-material/Mail';
import { signupUser } from '../../firebase/firebaseInit';
import { getUserData, clearAuthErrors } from '../../redux/actions/userActions';
import { withRouter } from '../../redux/withRouter';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import Footer from '../../components/Footer/Footer';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import { isEmail } from '../../redux/actions/dataActions';
import Maintainance from '../../components/SocialComponents/Maintainance';
import DateAndTimePicker from '../../components/SocialComponents/DateAndTimePicker';
import { t } from 'i18next';
import PasswordStrength from '../../components/SocialComponents/PasswordStrength';

class Signup extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			inputHandle: '',
			emailErrors: {},
			passwordErrors: {},
			confirmPasswordErrors: {},
			usernameErrors: {},
			showPasswordRequirements: false,
			termsAgreed: false,
			authAge: false,
			notAuthAge: false,
			termsNotAgreed: false,

			mustBeOlderThan18: false,
		};
	}

	componentDidMount() {
		if (
			this.props.user.authenticated &&
			this.props.user.refreshTab &&
			!this.props.user.loadingLogout
		) {
			this.props.getUserData('loadUser');
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});

		if (event.target.name === 'confirmPassword') {
			this.testConfirmPass(event.target.value);
		}
	};

	handleChangeUserAndMail = (event) => {
		if (event.target.name === 'email') {
			this.setState({
				[event.target.name]: event.target.value,
			});
		} else {
			this.setState({
				inputHandle: event.target.value.toLowerCase().trim(),
			});
		}
	};

	focus = (id) => {
		document.getElementById(id).focus();
	};

	testEmail = () => {
		if (this.state.email.trim() === '') {
			this.focus('email');
			this.setState({
				emailErrors: {
					email: t('email_error_enter_address'),
				},
			});
		} else if (!isEmail(this.state.email)) {
			this.focus('email');
			this.setState({
				emailErrors: {
					email: t('email_error_invalid_address'),
				},
			});
		} else {
			this.setState({
				emailErrors: { email: '' },
			});
			return true;
		}
	};
	testPassword = () => {
		let value = this.state.password;

		if (value === '') {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('enter_password'),
				},
			});
		} else if (value.length <= 5) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('password_short_length'),
				},
			});
		}
		// else if (value.search(/^[a-zA-Z0-9]*$/) === -1) {
		// 	this.focus('password');
		// 	this.setState({
		// 		passwordErrors: {
		// 			password:
		// 				"Please remove spaces and any special characters like '(;@&$!#%/=)'.",
		// 		},
		// 	});
		// }
		else if (value.length >= 30) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('password_long_length'),
				},
			});
		} else if (value.search(/\d/) === -1) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('add_password_number'),
				},
			});
		}
		// else if (value.search(/[A-Z]/) === -1) {
		// 	this.focus('password');
		// 	this.setState({
		// 		passwordErrors: {
		// 			password: 'The password must have one capitalized letter or more.',
		// 		},
		// 	});
		// }
		else {
			this.setState({
				passwordErrors: { password: '' },
			});
			return true;
		}
	};
	testConfirmPass = (value) => {
		if (value === '') {
			this.focus('confirmPassword');
			this.setState({
				confirmPasswordErrors: {
					confirmPassword: t('re_enter_password'),
				},
			});
		} else if (this.state.password !== value) {
			this.focus('confirmPassword');
			this.setState({
				confirmPasswordErrors: {
					confirmPassword: t('passwords_dont_match'),
				},
			});
		} else {
			this.setState({
				confirmPasswordErrors: { confirmPassword: '' },
			});
			return true;
		}
	};

	testUsername = () => {
		const inputHandle = this.state.inputHandle;
		const handleRegex = /^[a-z0-9_.]+$/;
		if (inputHandle === '') {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					inputHandle: t('enter_username'),
				},
			});
		} else if (inputHandle.length < 3) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					inputHandle: t('username_short_length'),
				},
			});
		} else if (inputHandle.length > 15) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					inputHandle: t('username_long_length'),
				},
			});
		} else if (!handleRegex.test(inputHandle)) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					inputHandle: t('username_special_characters'),
				},
			});
		} else {
			this.setState({
				usernameErrors: { inputHandle: '' },
			});
			return true;
		}
	};

	onRecaptchaResolved = (value) => {
		const newUserData = {
			email: this.state.email.trim(),
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			handle: this.state.inputHandle.trim(),
			birthDate: new Date(this.props.data.dateTimer.dateValue).toISOString(),
			responseToken: value,
		};

		this.props.signupUser(newUserData);
		this.recaptcha.reset();
	};
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.user.errorSignUp) {
			this.props.clearAuthErrors();
		}

		if (
			this.testEmail() &&
			this.testPassword() &&
			this.testConfirmPass(this.state.confirmPassword) &&
			this.testUsername() &&
			this.checkTerms('age') &&
			this.isOlderThan13() &&
			this.checkTerms('terms')
		) {
			this.recaptcha.execute();
		} else {
			this.recaptcha.reset();
		}
	};
	checkTerms = (type) => {
		if (type === 'age') {
			if (this.state.authAge) {
				return true;
			} else {
				this.setState({
					notAuthAge: true,
				});
			}
		}

		if (type === 'terms') {
			if (this.state.termsAgreed) {
				return true;
			} else {
				this.setState({
					termsNotAgreed: true,
				});
			}
		}
	};

	agreeTerms = (type) => {
		if (type === 'age') {
			if (this.state.notAuthAge) {
				this.setState({
					notAuthAge: false,
				});
			}
			this.setState((prevState) => ({ authAge: !prevState.authAge }));
		}
		if (type === 'terms') {
			if (this.state.termsNotAgreed) {
				this.setState({
					termsNotAgreed: false,
				});
			}
			this.setState((prevState) => ({ termsAgreed: !prevState.termsAgreed }));
		}
	};

	isOlderThan13 = (date) => {
		const dateVal = date ? date : this.props.data.dateTimer.dateValue;
		const birthDate = new Date(dateVal); // Use the actual birth date here
		birthDate.setHours(0, 0, 0, 0);
		// Calculate today's date
		// Calculate the date exactly 18 years ago
		const today = new Date();
		const cutoff = new Date(
			today.getFullYear() - 18,
			today.getMonth(),
			today.getDate(),
		);
		cutoff.setHours(0, 0, 0, 0);

		// Check if the person is older than 18 years
		if (birthDate <= cutoff) {
			if (this.state.mustBeOlderThan18) {
				this.setState({
					mustBeOlderThan18: false,
				});
			}
			return true;
		} else {
			this.setState({
				mustBeOlderThan18: true,
			});
			return false;
		}
	};
	componentWillUnmount() {
		if (this.props.user.errorSignUp) {
			this.props.clearAuthErrors();
		}
	}
	render() {
		const {
			user: {
				authenticated,
				emailVerified,
				loadingLogout,
				loadingSignUp,
				errorSignUp,
			},
			UI: { onlineStatus, mode },
		} = this.props;

		const {
			inputHandle,
			email,
			password,
			confirmPassword,
			emailErrors,
			passwordErrors,
			confirmPasswordErrors,
			usernameErrors,
			showPasswordRequirements,
			termsAgreed,
			termsNotAgreed,
			authAge,
			notAuthAge,
			mustBeOlderThan18,
		} = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Sign up - Goodbye App</title>
				</Helmet>

				{false ? (
					<Maintainance />
				) : (
					<div>
						{!loadingLogout && authenticated && emailVerified ? (
							<Navigate to='/feed' />
						) : (
							<div>
								<ReCAPTCHA
									sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
									ref={(ref) => (this.recaptcha = ref)}
									theme='dark'
									onChange={this.onRecaptchaResolved}
									size='invisible'
								/>

								<div className='skyBackground pd2Media'>
									<div
										className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}
									>
										{/* <div className='flexColumn alignItemsCenter pdt08OnlyMedia'>
											<div className='backgroundLogo'>
												<img className='logo ' src={logo} alt='Logo' />
											</div>
										</div> */}

										<Avatar id='cardMainIcon' className='flexAuto'>
											<AccountCircleIcon id='cardMainIconSize' />
										</Avatar>
										<h1 className='title center mg0'>{t('signup')}</h1>
										<h3 className='subtitle center mg0'>
											{t('sign_up_subtitle')}
										</h3>
										<form noValidate onSubmit={this.handleSubmit}>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												error={errorSignUp}
												icon={
													errorSignUp === t('email_address_in_use') ? (
														<MailIcon />
													) : (
														''
													)
												}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={notAuthAge || mustBeOlderThan18}
												message={t('too_young')}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={termsNotAgreed}
												message={t('terms_and_conditions_checkbox_error')}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={!onlineStatus}
												message={t('check_your_internet')}
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												severity='warning'
												info={
													errorSignUp === t('email_address_in_use')
														? t('email_in_use')
														: false
												}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												margin='1rem 0 0 0'
												info={errorSignUp === t('email_address_in_use')}
												message={
													<div>
														{t('your_email_auth')}{' '}
														<Link to='/login' id='link' className='bold'>
															{t('here')}.
														</Link>
													</div>
												}
											/>

											<CustomInput
												variant='outlined'
												type='email'
												titleLabel={t('email')}
												placeholder={t('type_here')}
												name='email'
												inputId='email'
												autoComplete='email'
												onChange={this.handleChangeUserAndMail}
												helperText={
													emailErrors.email ||
													(errorSignUp === t('email_address_in_use') &&
														t('check_info'))
												}
												inputError={
													emailErrors.email ||
													errorSignUp === t('email_address_in_use')
														? true
														: false
												}
												inputValue={email}
												inputAdornmentPosition='end'
												required={true}
												maxLength={320}
												fullWidth={true}
												deletable={() => this.setState({ email: '' })}
												isEmail={isEmail(email)}
												disabled={loadingSignUp}
											/>

											<CustomInput
												variant='outlined'
												type='password'
												titleLabel={t('password')}
												smallLabel={
													showPasswordRequirements && password.length <= 6
														? t('use_six_or_more')
														: ''
												}
												placeholder={t('type_here')}
												name='password'
												inputId='password'
												onChange={this.handleChange}
												onFocus={() =>
													this.setState({
														showPasswordRequirements: true,
													})
												}
												onBlur={() =>
													this.setState({
														showPasswordRequirements: false,
													})
												}
												helperText={passwordErrors.password}
												inputError={passwordErrors.password ? true : false}
												inputValue={password}
												inputAdornmentPosition='end'
												maxLength={50}
												autoComplete='new-password'
												required
												fullWidth
												disabled={loadingSignUp}
											/>
											{showPasswordRequirements && (
												<PasswordStrength password={password} />
											)}

											<CustomInput
												variant='outlined'
												type='confirmPassword'
												titleLabel={t('confirm_password')}
												placeholder={t('re_enter_password')}
												name='confirmPassword'
												inputId='confirmPassword'
												onChange={this.handleChange}
												helperText={confirmPasswordErrors.confirmPassword}
												inputError={
													confirmPasswordErrors.confirmPassword ? true : false
												}
												inputValue={confirmPassword}
												autoComplete='new-password'
												inputAdornmentPosition='end'
												maxLength={50}
												required
												fullWidth
												disabled={loadingSignUp}
											/>
											<CustomInput
												variant='outlined'
												type='given-name'
												titleLabel={t('username')}
												placeholder={t('type_here')}
												inputId='inputHandle'
												name='given-name'
												autoComplete='given-name'
												onChange={this.handleChangeUserAndMail}
												helperText={
													(errorSignUp === 'username_taken' &&
														t('enter_new_username')) ||
													usernameErrors.inputHandle
												}
												inputError={
													errorSignUp === 'username_taken' ||
													usernameErrors.inputHandle
														? true
														: false
												}
												inputValue={inputHandle}
												inputAdornmentPosition='end'
												maxLength={15}
												required
												fullWidth
												disabled={loadingSignUp}
											/>

											<div className='termsCheckbox mgt'>
												<Checkbox
													checked={authAge}
													required
													onClick={() => this.agreeTerms('age')}
													style={{
														color: notAuthAge ? 'red' : undefined,
														transform: 'translate(0, -1px)',
													}}
													disabled={loadingSignUp}
												/>
												<p className='mg0 inputText'>
													{t('age_verification_checkbox')}
												</p>
											</div>
											{authAge && (
												<div className='pdtH'>
													<p className='mg0 mgbH inputText'>
														{t('birth_date')}
													</p>
													<DateAndTimePicker
														signup
														onlyPicker
														setDate={(value) => this.isOlderThan13(value)}
														error={mustBeOlderThan18}
														disabled={loadingSignUp}
													/>
												</div>
											)}
											<div className='termsCheckbox mgtH'>
												<Checkbox
													checked={termsAgreed}
													required
													onClick={() => this.agreeTerms('terms')}
													style={{
														color: termsNotAgreed ? 'red' : undefined,
														transform: 'translate(0, -1px)',
													}}
													disabled={loadingSignUp}
												/>
												<span className='mg0'>
													{t('read_and_agreed_to')}{' '}
													<Link
														to='/terms-of-service'
														className='new underlineHover pointer'
													>
														{t('terms_of_service')},
													</Link>{' '}
													<Link
														to='/privacy-policy'
														className='new underlineHover pointer'
													>
														{t('privacy_policy')},
													</Link>{' '}
													<Link
														to='/cookie-use'
														className='new underlineHover pointer'
													>
														{t('cookie_use')},
													</Link>{' '}
													{t('and')}{' '}
													<Link
														to='/community-guidelines'
														className='new underlineHover pointer'
													>
														{t('community_guidelines')}.
													</Link>
												</span>
											</div>

											<div className='flex mgtH'>
												<CustomButton
													type='submit'
													variant='contained'
													disabled={!onlineStatus}
													loading={loadingSignUp}
													startIcon={<AccountCircleIcon />}
													btnText={t('signup')}
													fullWidth
													id='customSuccessButton'
												/>
											</div>
											<hr />
											<div className='center'>
												<p className='authQuestion'>
													{t('already_have_account')}
												</p>
												<Link to='/login' id='link'>
													<span className='text bold capitalize'>
														{t('login')}
													</span>
												</Link>
											</div>
										</form>
									</div>
								</div>
								<CustomAlerts
									alertId='smallMuiAlert'
									info={true}
									message={
										<div>
											<p className='center mg0 small '>
												{t('protected_google')}{' '}
												<a
													href='https://policies.google.com/privacy'
													rel='noopener noreferrer'
													target='_blank'
													className='bold link underlineHover'
												>
													{t('privacy_policy')}
												</a>{' '}
												{t('and')}{' '}
												<a
													href='https://policies.google.com/terms'
													rel='noopener noreferrer'
													target='_blank'
													className='bold link underlineHover'
												>
													{t('terms_of_service')}
												</a>{' '}
												{t('apply')}.
											</p>
										</div>
									}
									margin='0'
									icon={<SecurityIcon />}
								/>
							</div>
						)}
					</div>
				)}
				<Footer />
			</React.Fragment>
		);
	}
}
Signup.propTypes = {
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	clearAuthErrors: PropTypes.func.isRequired,
	getUserData: PropTypes.func.isRequired,
	signupUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

const mapActionsToProps = {
	signupUser,
	clearAuthErrors,
	getUserData,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Signup));
