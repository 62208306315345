import {
	Autocomplete,
	Chip,
	Divider,
	Grow,
	InputAdornment,
	TextField,
} from '@mui/material';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	setFeedData,
	clearFilteredPosts,
	numberFormatter,
	loadHashtags,
	queryHashtags,
	setHashtags,
} from '../../redux/actions/dataActions';
import Paper from '@mui/material/Paper';
import CustomAlerts from './CustomAlerts';
import { t } from 'i18next';
const regex = /^\w+$/;
const whiteSpaceRegex = /^\s+$/;

class AutocompleteHashtag extends Component {
	state = {
		lastQuery: '',
		hashtagBody: '',
		hashtagsError: '',
		invalidHashtag: '',
	};
	componentDidMount() {
		if (this.props.data.filteredPosts.length > 0) {
			this.setState({
				order: this.props.data.hashtagOrder,
			});
		}
	}

	debounce = (callback, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => callback(...args), delay);
		};
	};

	debouncedLogHashtags = this.debounce((queryData) => {
		this.setState({
			lastQuery: queryData.search,
		});
		this.props.queryHashtags(queryData, true);
	}, 600);

	handleSelected = (tag) => {
		const value = tag.hashtag;
		if (this.props.makePostFunctionality) {
			this.setState({
				hashtagBody: '',
			});
			this.handleHashtagAction(tag);
		} else if (value) {
			this.props.setFeedData({ hashtagOption: value });
		}
	};
	clearInvalidHashtagError = () => {
		this.setState({
			invalidHashtag: '',
		});
	};
	handleChangeHashtags = (e, value, reason) => {
		if (e) {
			e.preventDefault();
		}

		if (reason === 'input' && !value) {
			this.props.setFeedData({ hashtagOption: '', lastHashtag: '' });
			this.setState({
				hashtagBody: '',
			});
			if (this.props.data.errorLoadingFilteredPosts) {
				this.props.clearFilteredPosts();
			}
		} else if (reason === 'clear') {
			this.props.setFeedData({ hashtagOption: '', lastHashtag: '' });
			if (this.props.data.errorLoadingFilteredPosts) {
				this.props.clearFilteredPosts();
			}
		}
		// if (reason === 'reset' || reason === 'clear') {
		// 	if (
		// 		this.props.data.hashtagOption !== '' ||
		// 		this.props.data.lastHashtag !== ''
		// 	) {
		// 		this.setState({
		// 			hashtagBody: '',
		// 		});
		// 	}

		// } else
		if (
			reason === 'input' &&
			e.target.value !== '' &&
			e.target.value !== this.state.hashtagBody
		) {
			let value = e.target.value;
			if (regex.test(value.trim())) {
				if (whiteSpaceRegex.test(value)) {
					this.setState({
						invalidHashtag: t('hashtag_spaces'),
					});
				} else if (this.state.invalidHashtag) {
					this.setState({
						invalidHashtag: '',
					});
				}
				this.setState({
					hashtagBody: value.trim(),
				});
				if (this.props.feed) {
					this.props.setFeedData({ hashtagOption: value.trim() });
				}

				if (this.state.lastQuery !== value.trim()) {
					let queryData = { search: value.trim() };
					this.debouncedLogHashtags(queryData);
					if (value === '' && this.props.data.loadingHashtags) {
						this.props.loadHashtags(false);
					}
					if (value !== '' && !this.props.data.loadingHashtags) {
						this.props.loadHashtags(true);
					}
				}
			} else {
				this.setState({
					invalidHashtag: t('hashtag_format'),
				});
			}
		}
	};
	clearBody = () => {
		this.setState({
			hashtagBody: '',
		});
	};

	handleHashtagAction = (tag) => {
		if (this.state.hashtagsError) {
			this.setState({
				hashtagsError: '',
			});
		}
		if (
			this.props.data.hashtags.findIndex(
				(obj) => obj.hashtag === tag.hashtag,
			) === -1
		) {
			if (this.props.data.hashtags.length < 20) {
				this.props.postBodyChange(
					`${this.props.postBody.trim()} #${tag.hashtag}`,
				);
				this.props.setHashtags([
					...this.props.data.hashtags,
					{ hashtag: tag.hashtag, count: tag.count ? tag.count : '' },
				]);
			} else {
				this.setState({
					hashtagsError: t('only_15_hashtags'),
				});
			}
		} else if (
			this.props.data.hashtags.findIndex(
				(obj) => obj.hashtag === tag.hashtag,
			) !== -1
		) {
			let originalString = this.props.postBody;
			let valueToDelete = `#${tag.hashtag}`;
			// Create a new string by replacing the value to delete with an empty string
			let regex = new RegExp(valueToDelete, 'g');
			let modifiedString = originalString.replace(regex, '');
			this.props.postBodyChange(modifiedString);

			this.props.setHashtags(
				this.props.data.hashtags.filter((obj) => obj.hashtag !== tag.hashtag),
			);
		}
	};
	render() {
		const {
			data: {
				lastHashtag,
				lastHashtagCount,
				filterPostHashtags,
				previewHashtags,
				loadingHashtags,
				hashtags,
				errorQueryingHashtags,
			},
			feed,
			makePostFunctionality,
		} = this.props;
		const { hashtagBody, invalidHashtag, hashtagsError } = this.state;

		const filteredHashtagsArray =
			loadingHashtags &&
			filterPostHashtags.findIndex((tag) => tag.hashtag === hashtagBody) ===
				-1 &&
			hashtagBody !== ''
				? [
						{
							hashtag: hashtagBody,
						},
						...filterPostHashtags,
				  ]
				: errorQueryingHashtags === 'hash_404' &&
				  hashtagBody !== '' &&
				  filterPostHashtags.findIndex((tag) => tag.hashtag === hashtagBody) ===
						-1 &&
				  hashtagBody !== ''
				? [
						{
							hashtag: hashtagBody,
						},
						...filterPostHashtags,
				  ]
				: filterPostHashtags.length > 0
				? filterPostHashtags
				: previewHashtags;

		return (
			<div>
				{makePostFunctionality && (
					<div>
						<div className='center pdt'>
							<Divider className='bold'>{t('content_categorization')}</Divider>
							<p className='center'>{t('hashtags_help')}</p>
						</div>

						<Paper
							elevation={3}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								flexWrap: 'wrap',
								listStyle: 'none',
								p: 0.5,
								width: '100%',
							}}
							component='ul'
							className='notranslate'
						>
							{previewHashtags.map((tag, key) => {
								return hashtags.findIndex(
									(obj) => obj.hashtag === tag.hashtag,
								) === -1 ? (
									<Chip
										key={key}
										variant='outlined'
										id='bold'
										sx={{
											margin: '8px',
										}}
										label={`#${tag.hashtag}`}
										onClick={() => this.handleHashtagAction(tag)}
									/>
								) : (
									<Chip
										key={key}
										variant='outlined'
										color={'primary'}
										id='bold'
										sx={{
											margin: '8px',
										}}
										label={`#${tag.hashtag}`}
										onDelete={() => this.handleHashtagAction(tag)}
									/>
								);
							})}
							{hashtags.map((tag, key) => {
								if (
									previewHashtags.findIndex(
										(pTag) => pTag.hashtag === tag.hashtag,
									) === -1
								) {
									return hashtags.findIndex(
										(obj) => obj.hashtag === tag.hashtag,
									) === -1 ? (
										<Chip
											key={key}
											variant='outlined'
											color={'primary'}
											id='bold'
											sx={{
												margin: '8px',
											}}
											label={`#${tag.hashtag}`}
											onClick={() => this.handleHashtagAction(tag)}
										/>
									) : (
										<Chip
											key={key}
											variant='outlined'
											color={'primary'}
											id='bold'
											sx={{
												margin: '8px',
											}}
											label={`#${tag.hashtag}`}
											onDelete={() => this.handleHashtagAction(tag)}
										/>
									);
								} else return '';
							})}
						</Paper>
					</div>
				)}
				<CustomAlerts
					info={invalidHashtag}
					icon='#'
					close
					noMargin
					onClose={this.clearInvalidHashtagError}
				/>
				<Grow in={true}>
					<Autocomplete
						sx={{
							// margin: makePostFunctionality ? '2rem auto 1rem auto' : '',
							padding: makePostFunctionality ? '1rem 1rem 0' : '',
						}}
						size='small'
						freeSolo
						// autoHighlight
						forcePopupIcon={previewHashtags.length > 0}
						blurOnSelect={true}
						clearOnBlur={true}
						clearOnEscape={true}
						value={
							lastHashtag && hashtagBody === ''
								? {
										hashtag: lastHashtag,
										count: lastHashtagCount,
								  }
								: hashtagBody
								? {
										hashtag: hashtagBody,
										count: 0,
								  }
								: null
						}
						onInputChange={this.handleChangeHashtags}
						onChange={(e, value) => {
							value ? this.handleSelected(value) : this.clearBody();
						}}
						name='autoComplete'
						options={filteredHashtagsArray}
						getOptionDisabled={(option) =>
							loadingHashtags && option === filteredHashtagsArray[0]
						}
						isOptionEqualToValue={(option, value) =>
							option.hashtag === value.hashtag
						}
						getOptionLabel={(option) => option.hashtag}
						filterOptions={(x) => x}
						fullWidth
						renderOption={(props, option) => (
							<li
								{...props}
								style={{
									justifyContent: 'space-between',
								}}
							>
								<span className='notranslate'>#{option.hashtag}</span>
								<span>
									{option.count > 0 ? numberFormatter(option.count, 1) : ''}{' '}
									{loadingHashtags &&
									filteredHashtagsArray[0].hashtag === option.hashtag
										? t('loading')
										: feed && (option.count === undefined || option.count === 0)
										? t('nothing_yet')
										: !feed &&
										  (option.count === undefined || option.count === 0)
										? t('create_hashtag')
										: option.count > 1
										? t('posts')
										: t('post')}
								</span>
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('hashtag')}
								InputProps={{
									...params.InputProps,
									startAdornment: (
										<InputAdornment position='start'>
											<span className='rightTranslate5 mgr10px-'>#</span>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</Grow>
				<CustomAlerts error={hashtagsError} />
			</div>
		);
	}
}

AutocompleteHashtag.propTypes = {
	setFeedData: PropTypes.func.isRequired,
	clearFilteredPosts: PropTypes.func.isRequired,
	numberFormatter: PropTypes.func.isRequired,
	loadHashtags: PropTypes.func.isRequired,
	queryHashtags: PropTypes.func.isRequired,
	setHashtags: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
});
const mapActionsToProps = {
	setFeedData,
	clearFilteredPosts,
	numberFormatter,
	loadHashtags,
	queryHashtags,
	setHashtags,
};

export default connect(mapStateToProps, mapActionsToProps)(AutocompleteHashtag);
