import EditNoteIcon from '@mui/icons-material/EditNote';
import CustomButton from '../SocialComponents/CustomButton';
import HandleTopic from './HandleTopic';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import { useTranslation } from 'react-i18next';

export default function LetterTemplate({
	sent,
	loading,
	state,
	edit,
	openPanel,
	handleRemoveTopic,
	handleRemoveCustomTopic,
	getInitialValuesForType,
	titleCase,
	transformKey,

	recording,
	receivedLetter,
	isMobile,
}) {
	const { letterOrder } = state;
	const { t } = useTranslation();
	const notSp = <span className='bold'>{t('not_specified')}</span>;
	return (
		<div>
			{letterOrder && letterOrder.length > 0 ? (
				letterOrder.map((obj, index) => {
					const sectionData = state[obj.key];
					// let title = titleCase(obj.key);
					const title = t(transformKey(obj.key));
					if (obj.key === 'goodbyeMessage') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										title={title}
										setTopicTo=''
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<p className='mgtH mgb'>{sectionData}</p>
							</div>
						);
					} else if (obj.key === 'checklist') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										listStyle: 'none',
										padding: 0,
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>✔ {list.content}</p>
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'keyContacts') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('name')}:</b> {list.name ? list.name : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('number')}:</b>{' '}
													{list.number ? list.number : notSp}
												</p>
												{list.content && (
													<p className='mgtH mgb'>
														<b>{t('content')}:</b> {list.content}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'contacts') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('name')}:</b> {list.name ? list.name : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('number')}:</b>{' '}
													{list.number ? list.number : notSp}
												</p>
												{list.content && (
													<p className='mgtH mgb'>
														<b>{t('content')}</b> {list.content}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'importantDocuments') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('document_type')}:</b>{' '}
													{list.type ? list.type : notSp}
												</p>
												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
												<p className='mgtH mgb'>
													<b>{t('location')}:</b>{' '}
													{list.location ? list.location : notSp}
												</p>
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'personalInformation') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('full_name')}:</b>{' '}
													{list.fullName ? list.fullName : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('address')}:</b>{' '}
													{list.address ? list.address : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('birth_date')}:</b>{' '}
													{list.birthDate ? list.birthDate : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('mother_full_name')}:</b>{' '}
													{list.mothersFullName ? list.mothersFullName : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('father_full_name')}:</b>{' '}
													{list.fathersFullName ? list.fathersFullName : notSp}
												</p>

												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'devicePasswords') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('device')}:</b>{' '}
													{list.device ? list.device : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('location')}:</b>{' '}
													{list.location ? list.location : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('password')}:</b>{' '}
													{list.password ? list.password : notSp}
												</p>
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'mediaPasswords') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('website')}:</b>{' '}
													{list.website ? list.website : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('logbook_location')}:</b>{' '}
													{list.location ? list.location : notSp}
												</p>
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'pets') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('pet_name')}:</b>{' '}
													{list.name ? list.name : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('pet_diet')}:</b>{' '}
													{list.food ? list.food : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('medication_and_allegies')}:</b>{' '}
													{list.medicationAndAllergies
														? list.medicationAndAllergies
														: notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('caregiver')}:</b>{' '}
													{list.emergencyCaregiver
														? list.emergencyCaregiver
														: notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('caregiver_number')}:</b>{' '}
													{list.emergencyCaregiverNumber
														? list.emergencyCaregiverNumber
														: notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('veterinarian_name')}:</b>{' '}
													{list.vetName ? list.vetName : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('veterinarian_number')}:</b>{' '}
													{list.vetNumber ? list.vetNumber : notSp}
												</p>
												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'assets') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('asset')}:</b> {list.asset ? list.asset : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>
														{t('address')} / {t('location')}:
													</b>{' '}
													{list.location ? list.location : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('keys_location')}:</b>{' '}
													{list.keyLocation ? list.keyLocation : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('action')}:</b>{' '}
													{list.action ? list.action : notSp}
												</p>
												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'heirlooms') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('item')}:</b> {list.item ? list.item : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('location')}:</b>{' '}
													{list.location ? list.location : notSp}
												</p>

												<p className='mgtH mgb'>
													<b>{t('action')}:</b>{' '}
													{list.action ? list.action : notSp}
												</p>
												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'owed') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<ul
									style={{
										padding: '0 0 0 1rem',
										margin: 0,
									}}
								>
									{sectionData.map((list, index) => {
										return (
											<li key={list.id ? list.id : index}>
												<p className='mgtH mgb'>
													<b>{t('lender')}:</b>{' '}
													{list.lender ? list.lender : notSp}
												</p>
												<p className='mgtH mgb'>
													<b>{t('loan_type')}:</b>{' '}
													{list.loanType ? list.loanType : notSp}
												</p>

												<p className='mgtH mgb'>
													<b className='capitalize'>{t('owed')}:</b>{' '}
													{list.owed ? list.owed : notSp}
												</p>
												{list.additional && (
													<p className='mgtH mgb'>
														<b>{t('additional')}:</b>{' '}
														{list.additional ? list.additional : notSp}
													</p>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					} else if (obj.key === 'funeralArrangements') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<p className='mgtH mgb'>{sectionData}</p>
							</div>
						);
					} else if (obj.key === 'lifePreferences') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<p className='mgtH mgb'>{sectionData}</p>
							</div>
						);
					} else if (obj.key === 'audioRecording') {
						return (
							<div key={index}>
								{sent ? (
									<p className='textTitle bold underline mg0'>{title}</p>
								) : (
									<HandleTopic
										state={state}
										edit={edit}
										keyname={obj.key}
										setTopicTo={[]}
										title={title}
										handleRemoveTopic={handleRemoveTopic}
										getInitialValuesForType={getInitialValuesForType}
									/>
								)}

								<AudioPlayer
									fileUrl={recording ? recording : state.audioUrl}
									isMobile={isMobile}
									postId='audioLetter'
									waveColor='#7e7e7e'
									makePostFunctionality
								/>

								{sent && (
									<div>
										<hr />

										<a
											href={`https://goodbyeapp.com/${state.storageAudioUrl}`}
											target='_blank'
											className='letterTemplateBtn capitalize'
											rel='noopener noreferrer'
										>
											{t('open_public_url')}
										</a>
									</div>
								)}
							</div>
						);
					} else if (obj.key === 'customTopics') {
						return (
							<div key={index}>
								{sectionData.map((list, index) => {
									return (
										<div key={Math.random()}>
											{sent ? (
												<p className='textTitle bold underline mg0'>
													{titleCase(list.topic)}
												</p>
											) : (
												<HandleTopic
													state={state}
													edit={edit}
													customEditParams={`customTopicsInput_${index}`}
													keyname={obj.key}
													setTopicTo={[]}
													indx={index}
													title={titleCase(list.topic)}
													handleRemoveTopic={handleRemoveCustomTopic}
													getInitialValuesForType={getInitialValuesForType}
												/>
											)}

											<p className='mgtH '>
												{' '}
												{list.content ? list.content : notSp}
											</p>
										</div>
									);
								})}
							</div>
						);
					}
				})
			) : !sent && !loading ? (
				<div className='center'>
					<CustomButton
						btnText={t('add_content')}
						onClick={() => openPanel('write')}
						variant='contained'
						id='customCancelButton'
						margin='0 auto 1rem'
						startIcon={<EditNoteIcon />}
					/>
				</div>
			) : null}
		</div>
	);
}
