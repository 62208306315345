import PropTypes from 'prop-types';
import {
	Checkbox,
	Radio,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
	ToggleButton,
	ToggleButtonGroup,
	Toolbar,
} from '@mui/material';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
	getCollectionReports,
	getReport,
	clearReportErrors,
	submitCommentAdmin,
	updateReport,
	getAccountReports,
	getPostAdmin,
	getUserDataAdmin,
	setAdminReportId,
	fetchCommentAdmin,
	fetchUserLikedData,
	getUserDetailsByUserIdAdmin,
	getOnlyPostCommentsAdmin,
	clearAdminPostComments,
	noImgUrl,
	adminFetchGoodbyeLetter,
	titleCase,
	clearAccountReports,
	clearQueriedReports,
	isEmail,
} from '../../redux/actions/dataActions';
import {
	setAdminPanelData,
	clearAccountRestriction,
	getAdminCollection,
} from '../../redux/actions/userActions';
import gbaLogo from '../../util/Images/gbaLogo.png';
import logo from '../../util/Images/logo.svg';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import BackdropUi from '../../components/SocialComponents/BackdropUi';
import LinearProgress from '@mui/material/LinearProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReportSkeleton from '../../components/Skeletons/ReportSkeleton';
import Switch from '../../components/SocialComponents/Switch';
import User from '../USERPAGE/User';
import PostDialog from '../../components/SocialComponents/PostDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TourRoundedIcon from '@mui/icons-material/TourRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import SendIcon from '@mui/icons-material/Send';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmailIcon from '@mui/icons-material/Email';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import KeyIcon from '@mui/icons-material/Key';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import Comment from '../../components/SocialComponents/Comment';
import Searchbar from '../../components/SocialComponents/Searchbar';
import PostComments from '../../components/SocialComponents/PostComments';
import AccountReports from './AccountReports';
import CustomButton from '../../components/SocialComponents/CustomButton';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CreateMessage from './CreateMessage';
import CopyText from '../../components/SocialComponents/CopyText';
import DottedMenu from '../../components/SocialComponents/DottedMenu';
import Recipients from '../../components/SocialComponents/Recipients';
import AdminActions from './AdminActions';
import LetterTemplate from '../../components/GoodbyeLetter/LetterTemplate';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import countdown from '../../components/Countdownjs/countdown';
import gbLetterBackground from '../../util/Images/gbLetterBackground.jpg';
import ConfirmationDialog from '../../components/SocialComponents/ConfirmationDialog';
import { t } from 'i18next';

class Admin extends Component {
	state = {
		active: true,
		viewed: false,
		documentId: '',
		subDocumentId: '',
		keyValue: '',
		collection: 'issueReports',
		subCollection: '',
		reportDialog: false,
		reportDialogError: false,
		reportDialogId: '',
		errors: {},
		reportKey: 'userId',
		queryBy: 'reportId',
		priority: '',
		topic: '',
		device: '',
		browser: '',
		brand: '',
		createDevice: 'computer',
		createBrowser: 'chrome',
		createBrand: 'iphone',
		lastQueriedCollection: '',
		comment: '',
		adminOpenedReport: '',
		adminViewedReport: 'viewed',
		adminChangeReport: 'dontChange',
		convertSupportToIssue: 'dontMove',
		updatedPriority: '',
		updatedTopic: '',
		adminReportSeverity: 'info',
		infringements: undefined,
		confirmationDialog: false,
		querySearchbar: false,
		queryByCollection: 'reportId',
		queryPanelCollection: 'userReports',
		reportPanelCollection: 'issueReports',
		order: 'asc',
		reportCommentCollection: 'comments',
		recipient: [],
		readLetter: false,
		commentId: '',
		delCommentDialog: false,
	};

	componentDidMount() {
		let props = this.props;

		if (
			props.user.credentials.admin === true &&
			// props.user.adminPanel.adminPanelActive === false && //activate later!!! OK!
			props.user.admins &&
			props.user.admins.indexOf(props.user.credentials.userId) > -1
		) {
			this.props.setAdminPanelData({
				adminPanelActive: true,
				panel: 'reportPanel',
				order: 'asc',
			});
			this.props.getAdminCollection();
		}
	}

	componentWillUnmount() {
		if (this.props.user.adminPanel.adminPanelActive) {
			this.props.setAdminPanelData({ adminPanelActive: false });
		}

		if (this.props.data.reportsErrors) {
			this.props.clearReportErrors();
		}
	}
	handleCheckedPanel = (event) => {
		if (event.target.name === 'queryReports') {
			this.setState({
				errors: {},
				active: true,
				viewed: false,
				priority: '',
				queryBy: this.state.queryByCollection,
				collection: this.state.queryPanelCollection,
				reportPanelCollection: this.state.collection,
			});
			this.props.setAdminPanelData({
				panel: 'queryReports',
				queryBy: 'reportId',
			});
			if (this.props.data.adminReportId !== '') {
				this.props.setAdminReportId('', '', '', '', '');
				this.setState({
					reportDialogId: '',
				});
			}
		} else if (event.target.name === 'adminOpenedReport') {
			let value =
				event.target.value === 'closeIssue' &&
				this.state.adminOpenedReport === 'closeIssue'
					? ''
					: event.target.value === 'reopenIssue' &&
					  this.state.adminOpenedReport === 'reopenIssue'
					? ''
					: event.target.value;
			this.setState({
				adminOpenedReport: value,
			});
		} else if (event.target.name === 'adminViewedReport') {
			this.setState({
				adminViewedReport: event.target.value,
			});
			if (this.state.adminOpenedReport === 'closeIssue') {
				this.setState({
					adminOpenedReport: '',
				});
			}
		}
		//  else if (event.target.name === 'adminChangeReport') {
		// 	this.setState({
		// 		adminChangeReport: event.target.value,
		// 	});
		// }
		else if (event.target.name === 'convertSupportToIssue') {
			this.setState({
				convertSupportToIssue: event.target.value,
			});

			if (this.state.updatedPriority === '') {
				this.setState({
					updatedPriority: 'minor',
				});
			}
			if (event.target.value === 'move') {
				this.setState({
					adminOpenedReport: '',
					adminViewedReport: 'unviewed',
				});
			}
		} else if (event.target.name === 'infringements') {
			this.setState({
				infringements: event.target.value === 'true' ? true : false,
			});
		} else if (event.target.name === 'reportPanel') {
			this.setState({
				errors: {},
				active: true,
				priority: '',
				collection: this.state.reportPanelCollection,
				queryPanelCollection: this.state.collection,
				queryByCollection: this.state.queryBy,
				queryBy: 'reportId',
			});
			this.props.setAdminPanelData({
				panel: 'reportPanel',
			});
		} else if (event.target.name === 'customMessage') {
			this.setState({
				errors: {},
			});
			this.props.setAdminPanelData({
				panel: 'customMessage',
			});
		} else if (event.target.name === 'accountPanel') {
			this.setState({
				errors: {},
			});
			this.props.setAdminPanelData({
				panel: 'accountPanel',
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value,
			});
		}
	};

	handleClose = () => {
		this.setState({ reportDialog: false, comment: '' });

		if (this.props.data.reportsErrors) {
			this.props.clearReportErrors();
		}

		if (
			this.props.user.accountRestriction ||
			this.props.user.errorAccountRestriction
		) {
			this.props.clearAccountRestriction();
		}

		if (this.props.data.adminPostComments.length > 0) {
			this.props.clearAdminPostComments();
		}
	};

	queryShortcut = ({ value, key, collection }) => {
		this.props.setAdminPanelData({
			panel: 'queryReports',
			queryBy: 'keyValue',
		});
		this.setState({
			collection: collection,
			viewed: false,
			active: true,
			queryBy: 'keyValue',
			reportKey: key,
			[key]: value,
			order: 'asc',
		});
	};

	getRemovedPosts = (report) => {
		let formData = {
			userId: report.reported,
			order: 'desc',
		};
		formData.postCollection = this.state.subCollection;
		this.props.getUserDataAdmin(formData);
	};

	openReportDialog = (report) => {
		let state = this.state;
		this.setState({
			reportDialog: true,
			reportDialogId: report.reportId,
		});
		if (
			state.collection !== 'issueReports' &&
			state.collection !== 'supportReports' &&
			state.collection !== 'featureReports' &&
			state.collection !== 'boundaryReports' &&
			state.collection !== 'feedback' &&
			state.reportDialogId !== report.reportId
		) {
			this.props.setAdminReportId(
				report.reported ? report.reported : report.userId,
				report.reportId,
				state.collection,
				report.postId,
				report.commentId,
				report.repliedId,
				report.infringements,
			);

			let formData = {
				userId: report.userId,
				reportedId: report.reported,
			};
			if (isEmail(this.props.userId)) {
				formData.isEmail = true;
			}
			this.props.getAccountReports({
				userId: report.userId,
				reportedId: report.reported,
			});
			if (
				state.collection === 'userReports' ||
				state.collection === 'userAppeals'
			) {
				this.setState({ subCollection: 'posts' });
				let formData = {
					order: 'desc',
					postCollection: 'posts',
				};
				formData.userId =
					state.collection === 'userReports' ? report.reported : report.userId;
				this.props.getUserDataAdmin(formData);
			} else if (
				state.collection === 'postReports' ||
				state.collection === 'postAppeals'
			) {
				this.props.getPostAdmin({
					postId: report.postId,
					postCollection: report.infringements ? 'removedPosts' : 'posts',
					// commentId: null,
					commentCollection: 'comments',
					limit: 5,
					order: state.order,
				});
			} else if (
				state.collection === 'commentReports' ||
				state.collection === 'commentAppeals'
			) {
				let id = report.repliedId ? report.repliedId : report.commentId;
				let commentCollection =
					!report.repliedId && report.infringements
						? 'removedComments'
						: 'comments';

				this.props.getPostAdmin({
					postId: report.postId,
					postCollection: 'posts',
					commentId: id,
					commentCollection,
					limit: 5,
					order: state.order,
				});
			}
		}

		if (!report.viewed) {
			this.props.updateReport({
				report: report,
				openDialog: true,
				collection: state.collection,
				queriedReports: this.props.user.adminPanel.panel === 'queryReports',
				viewed: true,
				adminDoc: {},
			});
		}
	};

	submitComment = (reportId, actions, delComment) => {
		let formData = {
			body: this.state.comment,
			severity: this.state.adminReportSeverity,
			collection: this.state.collection,
			reportId: reportId,
			handle: this.props.user.credentials.handle,
			userId: this.props.user.credentials.userId,
			createdAt: new Date().toISOString(),
		};
		if (delComment) {
			formData.delComment = true;
		}
		if (this.state.commentId) {
			formData.commentId = this.state.commentId;
		}
		let prevActions = Array.isArray(actions) ? actions : [];
		const editing = this.state.commentId !== '';
		this.props.submitCommentAdmin(formData, prevActions, editing);
		this.setState({ comment: '', commentId: '', delCommentDialog: false });
	};
	reportUpdate = (report) => {
		let state = this.state;
		let viewed;
		let openedAction;
		if (state.adminViewedReport === 'viewed') {
			viewed = true;
		} else {
			viewed = false;
		}

		if (state.adminOpenedReport === 'closeIssue' && report.active) {
			openedAction = false;
		} else if (state.adminOpenedReport === 'reopenIssue' && !report.active) {
			openedAction = true;
		} else {
			openedAction = undefined;
		}

		if (
			(report.priority !== state.updatedPriority &&
				state.updatedPriority !== '') ||
			(report.topic !== state.updatedTopic && state.updatedTopic !== '') ||
			report.viewed !== viewed ||
			openedAction !== undefined
		) {
			if (!this.props.data.loadingReportUpdate) {
				this.props.updateReport({
					report: report,
					openDialog: false,
					collection: state.collection,
					queriedReports: this.props.user.adminPanel.panel === 'queryReports',
					viewed: false,
					updatedPriority: state.updatedPriority,
					updatedTopic: state.updatedTopic,
					convertSupportToIssue: state.convertSupportToIssue === 'move',
					createDevice: state.createDevice,
					createBrowser: state.createBrowser,
					createBrand: state.createBrand,
					openedAction: openedAction,
					viewedReport: viewed,
					stateInfringements: state.infringements,
					postData: this.props.data.post,
					adminDoc: this.props.user.adminDoc,
				});
				this.setState({
					reportDialog: false,
					reportDialogError: false,
					comment: '',
					adminOpenedReport: '',
					adminViewedReport: 'viewed',
					adminChangeReport: 'dontChange',
					convertSupportToIssue: 'dontMove',
					updatedPriority: '',
					updatedTopic: '',
					adminReportSeverity: 'info',
					infringements: undefined,
				});
			}
		} else {
			this.setState({
				reportDialogError: true,
			});
		}
	};

	radioChangeOpened = (event, val) => {
		let queryField = event.target.value;
		let state = this.state;
		if (queryField === 'active' && !state.active) {
			if (this.props.user.adminPanel.panel === 'queryReports' && state.viewed) {
				this.setState({
					active: true,
					viewed: false,
				});
			} else {
				this.setState({
					active: true,
				});
			}
		} else if (queryField === 'active' && state.active) {
			this.setState({
				active: false,
			});
		} else if (val === 'viewed' && !state.viewed) {
			if (this.props.user.adminPanel.panel === 'queryReports' && state.active) {
				this.setState({
					active: false,
					viewed: true,
				});
			} else {
				this.setState({
					viewed: true,
				});
			}
		} else if (val === 'viewed' && state.viewed) {
			this.setState({
				viewed: false,
			});
		} else if (val === 'querySearchbar') {
			this.setState((prevState) => ({
				querySearchbar: !prevState.querySearchbar,
			}));
			// this.setState({
			// 	querySearchbar: true,
			// });
		} else if (val === 'order' && state.order === 'asc') {
			this.setState({
				order: 'desc',
			});
			this.props.setAdminPanelData({ order: 'desc' });
		} else if (val === 'order') {
			this.setState({
				order: 'asc',
			});
			this.props.setAdminPanelData({ order: 'asc' });
		}
	};

	handleChange = (event) => {
		let state = this.state;

		if (
			(state.collection === 'issueReports' ||
				state.collection === 'supportReports') &&
			state.viewed
		) {
			this.setState({
				[event.target.name]: event.target.value.trim(),
				viewed: false,
			});
		} else if (event.target.value === 'priority') {
			this.setState({
				[event.target.name]: event.target.value.trim(),
				priority: 'major',
			});
		} else if (event.target.name === 'comment') {
			this.setState({
				[event.target.name]: event.target.value,
			});
		} else if (event.target.value === 'user') {
			// this.props.setAdminReportId(id, '', 'user', '', '');
			this.setState({
				[event.target.name]: event.target.value,
				collection: 'users',
				subCollection: 'posts',
			});
		} else if (
			event.target.value === 'reportId' ||
			event.target.value === 'keyValue'
		) {
			if (
				state.collection !== 'issueReports' &&
				state.collection !== 'supportReports' &&
				state.collection !== 'userReports' &&
				state.collection !== 'postReports' &&
				state.collection !== 'commentReports' &&
				state.collection !== 'featureReports' &&
				state.collection !== 'feedback' &&
				state.collection !== 'boundaryReports'
			) {
				this.setState({
					[event.target.name]: event.target.value,
					collection: 'issueReports',
				});
			} else {
				this.setState({
					[event.target.name]: event.target.value,
				});
			}
		} else if (event.target.value === 'postId') {
			this.setState({
				[event.target.name]: event.target.value,
			});
			if (event.target.name === 'queryBy') {
				this.setState({
					collection: 'posts',
				});
			}
		} else if (event.target.value === 'commentId') {
			this.setState({
				[event.target.name]: event.target.value,
			});
			if (event.target.name === 'queryBy') {
				this.setState({
					collection: 'comments',
				});
			}
		} else if (event.target.value === 'appealId') {
			this.setState({
				[event.target.name]: event.target.value,
				collection: 'userAppeals',
			});
		} else if (event.target.value === 'letterId') {
			this.setState({
				[event.target.name]: event.target.value,
				collection: 'letters',
			});
		} else if (event.target.value === 'postAndComment') {
			this.setState({
				[event.target.name]: event.target.value,
				collection: 'posts',
				subCollection: 'comments',
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value.trim(),
			});
		}

		if (
			event.target.value === 'user' ||
			event.target.value === 'reportId' ||
			event.target.value === 'keyValue' ||
			event.target.value === 'postId' ||
			event.target.value === 'commentId' ||
			event.target.value === 'appealId' ||
			event.target.value === 'postAndComment'
		) {
			this.props.setAdminPanelData({ queryBy: event.target.value });
		}
	};
	resetInput = (stateKey) => {
		if (stateKey === 'documentId') {
			this.setState({
				documentId: '',
			});
		} else if (stateKey === 'keyValue') {
			this.setState({
				keyValue: '',
			});
		} else if (stateKey === 'comment') {
			this.setState({
				comment: '',
			});
		} else {
			this.setState({
				subDocumentId: '',
			});
		}
		document.getElementById(stateKey).focus();

		if (this.props.data.reportsErrors) {
			this.props.clearReportErrors();
		}
	};
	formatReport = (reportNumber) => {
		if (reportNumber === 'userReport1') {
			return 'It is spam.';
		} else if (reportNumber === 'userReport2') {
			return "I simply don't enjoy the content of the user.";
		} else if (reportNumber === 'userReport3') {
			return 'Self-harm, eating disorders, or suicide.';
		} else if (reportNumber === 'userReport4') {
			return 'Sale of illegal or regulated items.';
		} else if (reportNumber === 'userReport5') {
			return 'Nudity or sexual behavior.';
		} else if (reportNumber === 'userReport6') {
			return 'Offensive words or symbols.';
		} else if (reportNumber === 'userReport7') {
			return 'Violence or dangerous organizations.';
		} else if (reportNumber === 'userReport8') {
			return 'Bullying or harassment.';
		} else if (reportNumber === 'userReport9') {
			return 'Intellectual property infringements.';
		} else if (reportNumber === 'userReport10') {
			return 'Scam or fraud.';
		} else if (reportNumber === 'userReport11') {
			return 'Impersonating me or someone I know.';
		} else return '';
	};
	handleChangeCollection = (event) => {
		let state = this.state;
		if (event.target.name === 'subCollection') {
			this.setState({
				subCollection: event.target.value,
			});
		} else if (
			this.props.user.adminPanel.panel === 'queryReports' &&
			event.target.value !== 'issueReports' &&
			event.target.value !== 'supportReports'
		) {
			if (state.queryBy === 'reportId') {
				this.setState({
					collection: event.target.value,
				});
			} else {
				this.setState({
					collection: event.target.value,
				});
			}
		} else {
			this.setState({
				collection: event.target.value,
			});
		}

		if (state.queryBy === 'keyValue') {
			this.setState({
				collection: event.target.value,
				reportKey: 'userId',
			});
		}
	};
	getReports = (lastkey) => {
		let state = this.state;
		if (state.collection === '') {
			this.setState({
				errors: {
					collection: 'Please select a report collection.',
				},
			});
			return;
		}
		if (state.errors) {
			this.setState({
				errors: {},
			});
		}
		let key =
			state.collection === 'issueReports' &&
			this.props.user.adminPanel.panel === 'queryReports' &&
			state.priority !== ''
				? 'priority'
				: state.collection === 'supportReports' &&
				  this.props.user.adminPanel.panel === 'queryReports' &&
				  state.topic !== ''
				? 'topic'
				: state.active
				? 'active'
				: state.viewed
				? 'viewed'
				: '';
		let value =
			state.collection === 'issueReports' &&
			this.props.user.adminPanel.panel === 'queryReports' &&
			state.priority !== ''
				? state.priority
				: state.collection === 'supportReports' &&
				  this.props.user.adminPanel.panel === 'queryReports' &&
				  state.topic !== ''
				? 'topic'
				: state.active
				? state.active
				: state.viewed
				? state.viewed
				: '';

		let formData = {
			collection: state.collection,
		};

		if (this.props.user.adminPanel.panel === 'reportPanel') {
			if (state.viewed && key !== 'viewed') {
				formData.key0 = key;
				formData.value0 = value;
				formData.key = 'viewed';
				formData.value = state.viewed;
			} else {
				formData.key = key;
				formData.value = value;
			}
			if (lastkey) {
				formData.startAfter = lastkey;
			}

			if (!state.active || state.viewed) {
				formData.order = state.order;
			}

			this.props.getCollectionReports(formData);
		} else {
			formData.order = state.order;
			if (
				state.documentId === '' &&
				(state.queryBy === 'user' ||
					state.queryBy === 'reportId' ||
					state.queryBy === 'postId' ||
					state.queryBy === 'commentId' ||
					state.queryBy === 'appealId' ||
					state.queryBy === 'letterId' ||
					state.queryBy === 'postAndComment')
			) {
				this.setState({
					errors: {
						documentId: 'Please add a document ID.',
					},
				});
			} else if (
				state.documentId !== '' &&
				(state.queryBy === 'user' ||
					state.queryBy === 'reportId' ||
					state.queryBy === 'postId' ||
					state.queryBy === 'commentId' ||
					state.queryBy === 'appealId' ||
					state.queryBy === 'letterId' ||
					state.queryBy === 'postAndComment')
			) {
				let id = state.documentId.trim();
				if (state.queryBy === 'user') {
					let formData = {
						userId: state.documentId,
						order: state.order,
					};

					if (
						state.subCollection === 'posts' ||
						state.subCollection === 'removedPosts' ||
						state.subCollection === 'unreleasedPosts'
					) {
						formData.postCollection = state.subCollection;
						this.props.getUserDataAdmin(formData);
					} else if (
						state.subCollection === 'comments' ||
						state.subCollection === 'removedComments'
					) {
						formData.commentCollection = state.subCollection;
						this.props.getUserDataAdmin(formData);
					} else if (
						state.subCollection === 'likedPostData' ||
						state.subCollection === 'likedCommentData' ||
						state.subCollection === 'likedReplyData'
					) {
						formData.sortBy =
							state.subCollection === 'likedPostData'
								? 'post'
								: state.subCollection === 'likedCommentData'
								? 'comment'
								: 'reply';
						this.props.getUserDetailsByUserIdAdmin(formData);
						formData.admin = true;
						this.props.fetchUserLikedData(formData);
						this.props.setAdminPanelData({
							subCollection: state.subCollection,
						});
					}

					// this.props.setAdminReportId('', '', 'user', '', '');
				} else if (state.queryBy === 'reportId') {
					formData.reportId = id;
					this.props.getReport(formData);
					this.setState({
						lastQueriedCollection: state.collection,
					});
				} else if (state.queryBy === 'postId') {
					// if (state.collection === 'posts') {
					// state.commentCollection,

					this.props.getPostAdmin({
						postId: id,
						postCollection: state.collection,
						// commentId: undefined,
						commentCollection: 'comments',
						limit: 5,
						order: state.order,
					});
				} else if (state.queryBy === 'commentId') {
					this.props.fetchCommentAdmin(id, state.collection);
				} else if (state.queryBy === 'appealId') {
					formData.reportId = id;
					this.props.getReport(formData);
					this.setState({
						lastQueriedCollection: state.collection,
					});
				} else if (state.queryBy === 'letterId') {
					this.setState({
						readLetter: true,
					});
					this.props.adminFetchGoodbyeLetter(id, state.collection);
				} else if (state.queryBy === 'postAndComment') {
					this.props.getPostAdmin({
						postId: state.documentId,
						postCollection: state.collection,
						commentId: state.subDocumentId,
						commentCollection: state.subCollection,
						limit: 5,
						order: state.order,
					});
				}
			} else if (
				state.queryBy === 'keyValue' &&
				(state.reportKey === 'userId' || state.reportKey === 'reported') &&
				state.keyValue === ''
			) {
				this.setState({
					errors: {
						keyValue: 'Please add a value.',
					},
				});
			} else if (
				state.queryBy === 'keyValue'
				// ||
				// state.queryBy === 'priority'
			) {
				if (state.reportKey === 'boundaryId') {
					delete formData.order;
				} else if (state.active) {
					formData.key0 = 'active';
					formData.value0 = state.active;
				} else if (state.viewed) {
					formData.key0 = 'viewed';
					formData.value0 = state.viewed;
				}
				formData.key = state.reportKey;

				if (
					state.reportKey === 'userId' ||
					state.reportKey === 'reported' ||
					state.reportKey === 'postId' ||
					state.reportKey === 'commentId' ||
					state.reportKey === 'boundaryId'
				) {
					formData.value = state.keyValue.trim();
				} else if (state.reportKey === 'priority') {
					formData.value = state.priority;
				} else if (state.reportKey === 'device') {
					formData.value = state.device;
				} else if (state.reportKey === 'browser') {
					formData.value = state.browser;
				} else if (state.reportKey === 'brand') {
					formData.value = state.brand;
				} else if (state.reportKey === 'topic') {
					formData.value = state.topic;
				}
				if (lastkey) {
					formData.startAfter = lastkey;
				}
				if (this.props.data.queriedReports.length > 0) {
					this.props.clearQueriedReports();
				}
				this.props.getCollectionReports(formData, true);
				this.setState({
					lastQueriedCollection: state.collection,
				});
			}
		}
	};

	priority = (priorityLevel) => {
		if (priorityLevel === 'major') {
			return 'majorPriority';
		} else if (priorityLevel === 'medium') {
			return 'medPriority';
		} else {
			return 'minorPriority';
		}
	};

	updateAdminPanel = () => {
		// this.props.update
		if (this.props.user.credentials.admin) {
			this.props.getAdminCollection();
		}
	};

	getPostAdminFunc = (postId, collection, commentCollection, commentId) => {
		this.props.getPostAdmin({
			postId,
			postCollection: collection,
			commentId: commentId ? commentId : null,
			commentCollection,
			limit: 5,
			order: 'desc',
		});
		if (this.props.data.adminPostComments.length > 0) {
			this.props.clearAdminPostComments();
		}
	};

	setRecipient = (recipient) => {
		this.setState({
			documentId: recipient.userId,
			querySearchbar: false,
			recipient: [recipient],
		});
	};
	deleteRecipient = () => {
		this.setState({
			documentId: '',
			querySearchbar: true,
			recipient: [],
		});
	};

	getRemovedPostComments = () => {
		let data = this.props.data;
		let formData = {
			postId: data.adminReportPostId,
			order: 'desc',
			limit: 10,
			collection: 'removedComments',
			field: 'postId',
		};
		if (data.adminPostComments.length > 0) {
			formData.key =
				data.adminPostComments[data.adminPostComments.length - 1].createdAt;
		}
		this.props.getOnlyPostCommentsAdmin(formData);
	};

	handleReadLetter = (letterId, collection) => {
		if (this.props.data.receivedGoodbyeLetter.letterId !== letterId) {
			this.props.adminFetchGoodbyeLetter(letterId, collection);
		}
		this.setState({
			readLetter: true,
		});
	};
	handleCloseLetter = () => {
		this.setState({
			readLetter: false,
		});
	};

	editComment = (body, severity, id) => {
		this.setState({
			comment: body,
			adminReportSeverity: severity,
			commentId: id,
		});
		if (body) {
			document.getElementById('comment').focus();
		}
	};

	handleConfirmationDialog = (boolean, dialog, id) => {
		if (dialog === 'delCommentDialog') {
			this.setState({ delCommentDialog: boolean, commentId: id });
		}
	};

	formatDate = (date) => {
		const time = countdown(
			new Date(date),
			null,
			countdown.DECADES |
				countdown.YEARS |
				countdown.MONTHS |
				countdown.WEEKS |
				countdown.DAYS |
				countdown.HOURS,
		).toString();
		if (time === '') {
			return countdown(new Date(date), null, countdown.MINUTES).toString();
		} else return time;
	};
	render() {
		const {
			user: {
				credentials,
				adminDoc,
				admins,
				loadingAdminData,
				adminPanel: { panel },
				loadingAccountRestriction,
				accountRestriction,
				errorAccountRestriction,
			},
			data: {
				loadingAdminPostComments,
				adminPostComments,
				loadingReports,
				loadingMoreReports,
				sendingCommentAdmin,
				reports,
				supportReports,
				userReports,
				postReports,
				commentReports,
				featureReports,
				feedback,
				boundaryReports,
				userAppeals,
				postAppeals,
				commentAppeals,
				reportsErrors,
				updateReportError,
				queriedReports,
				loadingReportUpdate,
				loadingAccountReports,
				setAccountReports: {
					//  reporterId,
					reportedId,
				},
				fetchingAdminComments,
				adminUserComments,
				errorFetchingAdminComments,
				visitedUserCredentials: {
					name,
					handle,
					userId,
					imageUrl,
					postCount,
					unreleasedPostCount,
					appealable,
					letters,
					unreleasedLetters,
				},
				post,
				loadingPost,
				loadingProfile,
				adminReportPostId,
				errorLoadingPost,
				loadingReceivedGoodbyeLetter,
				receivedGoodbyeLetter,
				errorLoadingReceivedGoodbyeLetter,
			},
			UI: { mode },
		} = this.props;

		const {
			active,
			viewed,
			documentId,
			subDocumentId,
			keyValue,
			collection,
			subCollection,
			delCommentDialog,
			reportDialog,
			reportDialogError,
			reportDialogId,
			errors,
			reportKey,
			queryBy,
			topic,
			priority,
			device,
			browser,
			brand,
			createDevice,
			createBrowser,
			createBrand,
			lastQueriedCollection,
			comment,
			adminOpenedReport,
			adminViewedReport,
			adminChangeReport,
			convertSupportToIssue,
			updatedPriority,
			updatedTopic,
			adminReportSeverity,
			// infringements,
			order,
			querySearchbar,
			reportCommentCollection,
			recipient,
			readLetter,
			commentId,
		} = this.state;

		let reportArray =
			queriedReports.length > 0 &&
			panel === 'queryReports' &&
			collection === lastQueriedCollection
				? queriedReports
				: panel === 'queryReports' && collection !== lastQueriedCollection
				? []
				: collection === 'issueReports'
				? reports
				: collection === 'supportReports'
				? supportReports
				: collection === 'userReports'
				? userReports
				: collection === 'postReports'
				? postReports
				: collection === 'commentReports'
				? commentReports
				: collection === 'featureReports'
				? featureReports
				: collection === 'feedback'
				? feedback
				: collection === 'boundaryReports'
				? boundaryReports
				: collection === 'userAppeals'
				? userAppeals
				: collection === 'postAppeals'
				? postAppeals
				: collection === 'commentAppeals'
				? commentAppeals
				: [];

		let lastReportCreatedAtKey = reportArray[reportArray.length - 1]?.createdAt;
		let dialogCollectionTitle =
			collection === 'feedback'
				? 'Feedback'
				: titleCase(collection).slice(0, -1);

		let moreResults =
			reportArray.length > 0 && reportArray.length % 10 === 0
				? 'More than 10 results'
				: reportArray.length;

		let adminDocCount =
			collection === 'issueReports'
				? adminDoc.issueReports
				: collection === 'supportReports'
				? adminDoc.supportReports
				: collection === 'userReports'
				? adminDoc.userReports
				: collection === 'postReports'
				? adminDoc.postReports
				: collection === 'commentReports'
				? adminDoc.commentReports
				: collection === 'featureReports'
				? adminDoc.featureReports
				: collection === 'feedback'
				? adminDoc.feedback
				: collection === 'boundaryReports'
				? adminDoc.boundaryReports
				: collection === 'userAppeals'
				? adminDoc.userAppeals
				: collection === 'postAppeals'
				? adminDoc.postAppeals
				: collection === 'commentAppeals'
				? adminDoc.commentAppeals
				: 10;

		let reportCount = (adminDocCount ? adminDocCount : 0) - reportArray.length;
		let reportLimit = reportCount >= 10 || viewed ? 10 : reportCount;

		return (
			<div>
				{(loadingAdminData && adminDoc === undefined) ||
				(credentials.admin && admins.length === 0) ? (
					<div className='pdt2'>
						<CircularProgress size={50} id='center' />
					</div>
				) : credentials.admin === true &&
				  admins &&
				  admins.indexOf(credentials.userId) > -1 ? (
					<div>
						<BackdropUi open={loadingAdminData} circularProgress={true}>
							<div className='cardContainer'>
								<div className={`card pd ${mode === 'dark' && 'dbc'}`}>
									<UpdateIcon
										id='adminUpdateIcon'
										onClick={this.updateAdminPanel}
									/>
									<div className='adminHeader'>
										<p className='title mg0'>Admin Panel</p>

										<div className='reportPanelOptions'>
											<FormControlLabel
												value='reportPanel'
												control={
													<Radio
														onChange={this.handleCheckedPanel}
														name='reportPanel'
														sx={{
															color: '#2980b9',
														}}
														checked={panel === 'reportPanel'}
													/>
												}
												label='Reports'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='queryReports'
												control={
													<Radio
														onChange={this.handleCheckedPanel}
														name='queryReports'
														sx={{
															color: '#2980b9',
														}}
														checked={panel === 'queryReports'}
													/>
												}
												label='Query'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='customMessage'
												control={
													<Radio
														onChange={this.handleCheckedPanel}
														name='customMessage'
														sx={{
															color: '#2980b9',
														}}
														checked={panel === 'customMessage'}
													/>
												}
												label='Message'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='accountPanel'
												control={
													<Radio
														onChange={this.handleCheckedPanel}
														name='accountPanel'
														sx={{
															color: '#2980b9',
														}}
														checked={panel === 'accountPanel'}
													/>
												}
												label='Actions'
												labelPlacement='bottom'
											/>
										</div>
									</div>
									{panel === 'customMessage' && <CreateMessage custom={true} />}
									{panel === 'accountPanel' && <AdminActions />}
									{panel === 'reportPanel' || panel === 'queryReports' ? (
										<div className='adminReportPanel'>
											<div className='flex spaceEvenly'>
												<FormControlLabel
													sx={{ width: '33%' }}
													value='active'
													control={
														<Checkbox
															onChange={this.radioChangeOpened}
															name='active'
															sx={{
																color: '#2980b9',
															}}
															checked={active}
														/>
													}
													label='Active'
													labelPlacement='bottom'
													disabled={
														panel === 'queryReports' && queryBy !== 'keyValue'
													}
												/>
												<FormControlLabel
													sx={{ width: '34%' }}
													control={
														<Switch
															handleSwitchChange={(e) =>
																this.radioChangeOpened(e, 'viewed')
															}
															checked={viewed}
															disabled={
																panel === 'queryReports' &&
																queryBy !== 'keyValue'
															}
														/>
													}
													label={viewed ? 'Viewed Reports' : 'All Reports'}
													labelPlacement='bottom'
												/>

												<FormControlLabel
													sx={{ width: '33%' }}
													disabled={
														!(
															(panel === 'reportPanel' &&
																(!active || viewed)) ||
															queryBy === 'user' ||
															queryBy === 'postAndComment' ||
															queryBy === 'keyValue'
														)
													}
													control={
														<Switch
															value='order'
															handleSwitchChange={(e) =>
																this.radioChangeOpened(e, 'order')
															}
															checked={order === 'desc' ? true : false}
															name='order'
														/>
													}
													label={`${
														order === 'desc' ? 'Newest on top' : 'First on top'
													}`}
													labelPlacement='bottom'
												/>
											</div>

											<p className='textTitle '>Report Counts</p>

											<ToggleButtonGroup
												name='collection'
												color='info'
												value={collection}
												exclusive
												onChange={this.handleChangeCollection}
												aria-label='Platform'
												disabled={
													queryBy !== 'reportId' && queryBy !== 'keyValue'
												}
											>
												<ToggleButton value='issueReports'>
													Issues: {adminDoc.issueReports}
												</ToggleButton>
												<ToggleButton value='supportReports'>
													Support: {adminDoc.supportReports}
												</ToggleButton>
												<ToggleButton value='userReports'>
													Users: {adminDoc.userReports}
												</ToggleButton>
												<ToggleButton value='postReports'>
													Posts: {adminDoc.postReports}
												</ToggleButton>
												<ToggleButton value='commentReports'>
													Comments: {adminDoc.commentReports}
												</ToggleButton>
												<ToggleButton value='featureReports'>
													Features: {adminDoc.featureReports}
												</ToggleButton>
												<ToggleButton value='feedback'>
													Feedback: {adminDoc.feedback}
												</ToggleButton>
												<ToggleButton value='boundaryReports'>
													Boundary: {adminDoc.boundaryReports}
												</ToggleButton>
											</ToggleButtonGroup>
											<p className='textTitle '>Appeal Counts</p>
											<ToggleButtonGroup
												name='appealCollection'
												color='primary'
												value={collection}
												exclusive
												onChange={this.handleChangeCollection}
												aria-label='Platform'
												disabled={
													queryBy !== 'reportId' && queryBy !== 'keyValue'
												}
											>
												<ToggleButton value='userAppeals'>
													User: {adminDoc.userAppeals}
												</ToggleButton>
												<ToggleButton value='postAppeals'>
													Post: {adminDoc.postAppeals}
												</ToggleButton>
												<ToggleButton value='commentAppeals'>
													Comment: {adminDoc.commentAppeals}
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
									) : null}
									{collection === 'issueReports' &&
										(panel === 'reportPanel' || panel === 'queryReports') && (
											<div>
												<p className='textTitle center mgbH'>Priorities</p>

												<div className='flex spaceEvenly pd'>
													<CustomButton
														variant='outlined'
														btnText={`MINOR: ${adminDoc.minorPriority}`}
														onClick={() =>
															this.queryShortcut({
																value: 'minor',
																key: 'priority',
																collection: 'issueReports',
															})
														}
														color='success'
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`MEDIUM: ${adminDoc.mediumPriority}`}
														onClick={() =>
															this.queryShortcut({
																value: 'medium',
																key: 'priority',
																collection: 'issueReports',
															})
														}
														color='warning'
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`MAJOR: ${adminDoc.majorPriority}`}
														onClick={() =>
															this.queryShortcut({
																value: 'major',
																key: 'priority',
																collection: 'issueReports',
															})
														}
														color='error'
														margin='0'
													/>
												</div>
											</div>
										)}
									{collection === 'supportReports' &&
										(panel === 'reportPanel' || panel === 'queryReports') && (
											<div>
												<p className='textTitle center mgbH'>Topics</p>

												<div className='flex spaceEvenly pd'>
													<CustomButton
														variant='outlined'
														btnText={`Support: ${adminDoc.supportReports}`}
														onClick={() =>
															this.queryShortcut({
																value: 'support',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Marketing: ${adminDoc.marketing}`}
														onClick={() =>
															this.queryShortcut({
																value: 'marketing',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Jobs: ${adminDoc.jobs}`}
														onClick={() =>
															this.queryShortcut({
																value: 'jobs',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Suggestions: ${adminDoc.suggestions}`}
														onClick={() =>
															this.queryShortcut({
																value: 'suggestions',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Legal: ${adminDoc.legal}`}
														onClick={() =>
															this.queryShortcut({
																value: 'legal',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Copyright: ${adminDoc.copyright}`}
														onClick={() =>
															this.queryShortcut({
																value: 'copyright',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
													<CustomButton
														variant='outlined'
														btnText={`Refunds: ${adminDoc.refunds}`}
														onClick={() =>
															this.queryShortcut({
																value: 'refunds',
																key: 'topic',
																collection: 'supportReports',
															})
														}
														margin='0'
													/>
												</div>
											</div>
										)}
									{panel === 'queryReports' && (
										<div>
											<div>
												<p className='textTitle center'>Query By</p>

												<div className='flex center pdb'>
													<FormGroup id='reportInputRadio' row>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'user'}
																	name='queryBy'
																	value='user'
																	onChange={this.handleChange}
																	icon={<PersonSearchOutlinedIcon />}
																	checkedIcon={<PersonSearchRoundedIcon />}
																/>
															}
															label='User'
														/>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'reportId'}
																	name='queryBy'
																	onChange={this.handleChange}
																	value='reportId'
																	icon={<ReportGmailerrorredOutlinedIcon />}
																	checkedIcon={<ReportRoundedIcon />}
																/>
															}
															label='Report ID'
														/>

														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'keyValue'}
																	name='queryBy'
																	value='keyValue'
																	onChange={this.handleChange}
																	icon={<VpnKeyOutlinedIcon />}
																	checkedIcon={<KeyIcon />}
																/>
															}
															label='Key Value'
														/>
														{/* )} */}
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'postId'}
																	name='queryBy'
																	onChange={this.handleChange}
																	value='postId'
																	icon={<EmailOutlinedIcon />}
																	checkedIcon={<EmailIcon />}
																/>
															}
															label='Post ID'
														/>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'commentId'}
																	name='queryBy'
																	value='commentId'
																	onChange={this.handleChange}
																	icon={<ModeCommentOutlinedIcon />}
																	checkedIcon={<ModeCommentIcon />}
																/>
															}
															label='Comment ID'
														/>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'appealId'}
																	name='queryBy'
																	value='appealId'
																	onChange={this.handleChange}
																	icon={<RecordVoiceOverOutlinedIcon />}
																	checkedIcon={<RecordVoiceOverIcon />}
																/>
															}
															label='Appeal ID'
														/>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'letterId'}
																	name='queryBy'
																	value='letterId'
																	onChange={this.handleChange}
																	icon={<HistoryEduIcon />}
																	checkedIcon={<HistoryEduIcon />}
																/>
															}
															label='Letter ID'
														/>
														<FormControlLabel
															labelPlacement='top'
															control={
																<Checkbox
																	checked={queryBy === 'postAndComment'}
																	name='queryBy'
																	value='postAndComment'
																	onChange={this.handleChange}
																	icon={<FindInPageOutlinedIcon />}
																	checkedIcon={<FindInPageIcon />}
																/>
															}
															label='Post & Comment'
														/>
													</FormGroup>
												</div>
											</div>

											{queryBy === 'user' && (
												<div className='flex center pdb'>
													<FormControlLabel
														control={
															<Switch
																value='querySearchbar'
																handleSwitchChange={(e) =>
																	this.radioChangeOpened(e, 'querySearchbar')
																}
																checked={querySearchbar}
																name='querySearchbar'
															/>
														}
														label='Query User Id'
														labelPlacement='bottom'
													/>
												</div>
											)}
											{querySearchbar && (
												<div
													id='scrollableAdminDiv'
													style={{
														overflow: 'auto',
														height: '21vh',
														minHeight: '100px',
													}}
												>
													<Searchbar
														adminFunctionality={true}
														setRecipient={this.setRecipient}
													/>
												</div>
											)}
											<div className='reportIdInput'>
												{queryBy === 'user' ||
												queryBy === 'reportId' ||
												queryBy === 'postId' ||
												queryBy === 'commentId' ||
												queryBy === 'appealId' ||
												queryBy === 'letterId' ||
												queryBy === 'postAndComment' ? (
													<div>
														{recipient.length > 0 && (
															<div className='mgt'>
																<Recipients
																	recipients={recipient}
																	deleteRecipient={this.deleteRecipient}
																	admin={true}
																/>
															</div>
														)}
														<label className='inputText mgt'>Collection:</label>
														<FormControl fullWidth>
															<Select
																value={collection}
																name='collection'
																onChange={this.handleChangeCollection}
															>
																{queryBy === 'user' && (
																	<MenuItem value={'users'}>Users</MenuItem>
																)}

																{queryBy === 'reportId' && (
																	<MenuItem value={'issueReports'}>
																		Issue Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'supportReports'}>
																		Support Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'userReports'}>
																		User Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'postReports'}>
																		Post Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'commentReports'}>
																		Comment Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'featureReports'}>
																		Feature Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'feedback'}>
																		Feedback
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'boundaryReports'}>
																		Boundary Reports
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'userAppeals'}>
																		User Appeals
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'postAppeals'}>
																		Post Appeals
																	</MenuItem>
																)}
																{queryBy === 'reportId' && (
																	<MenuItem value={'commentAppeals'}>
																		Comment Appeals
																	</MenuItem>
																)}
																{(queryBy === 'postId' ||
																	queryBy === 'postAndComment') && (
																	<MenuItem value={'posts'}>Posts</MenuItem>
																)}
																{(queryBy === 'postId' ||
																	queryBy === 'postAndComment') && (
																	<MenuItem value={'removedPosts'}>
																		Removed Posts
																	</MenuItem>
																)}
																{(queryBy === 'postId' ||
																	queryBy === 'postAndComment') && (
																	<MenuItem value={'unreleasedPosts'}>
																		Unreleased Posts
																	</MenuItem>
																)}

																{queryBy === 'commentId' && (
																	<MenuItem value={'comments'}>
																		Comments
																	</MenuItem>
																)}
																{queryBy === 'commentId' && (
																	<MenuItem value={'removedComments'}>
																		Removed Comments
																	</MenuItem>
																)}
																{queryBy === 'appealId' && (
																	<MenuItem value={'userAppeals'}>
																		User Appeals
																	</MenuItem>
																)}
																{queryBy === 'appealId' && (
																	<MenuItem value={'postAppeals'}>
																		Post Appeals
																	</MenuItem>
																)}
																{queryBy === 'appealId' && (
																	<MenuItem value={'commentAppeals'}>
																		Comment Appeals
																	</MenuItem>
																)}
																{queryBy === 'letterId' && (
																	<MenuItem value={'letters'}>Letters</MenuItem>
																)}
																{queryBy === 'letterId' && (
																	<MenuItem value={'unreleasedLetters'}>
																		Unreleased Letters
																	</MenuItem>
																)}
															</Select>
														</FormControl>

														<CustomInput
															variant='outlined'
															type='documentId'
															titleLabel='Document ID'
															placeholder='ID'
															name='documentId'
															inputId='documentId'
															autoComplete='documentId'
															onChange={this.handleChange}
															helperText={reportsErrors || errors.documentId}
															inputError={
																reportsErrors || errors.documentId
																	? true
																	: false
															}
															inputValue={documentId}
															required={true}
															maxLength={120}
															fullWidth={true}
															deletable={() => this.resetInput('documentId')}
														/>

														{(queryBy === 'user' ||
															queryBy === 'postAndComment') && (
															<div>
																<label className='inputText mgt'>
																	Sub-Collection:
																</label>

																<FormControl fullWidth>
																	<Select
																		value={subCollection}
																		name='subCollection'
																		onChange={this.handleChangeCollection}
																	>
																		<MenuItem value={'comments'}>
																			Comments
																		</MenuItem>
																		<MenuItem value={'removedComments'}>
																			Removed Comments
																		</MenuItem>

																		{/* {queryBy === 'user' && (
																			<MenuItem value={'letters'}>
																				Goodbye Letters
																			</MenuItem>
																		)}
																		{queryBy === 'user' && (
																			<MenuItem
																				value={'unreleasedLetters'}
																			>
																				Unreleased Goodbye Letters
																			</MenuItem>
																		)} */}
																		{queryBy === 'user' && (
																			<MenuItem value={'posts'}>Posts</MenuItem>
																		)}

																		{queryBy === 'user' && (
																			<MenuItem value={'unreleasedPosts'}>
																				Unreleased Posts
																			</MenuItem>
																		)}
																		{queryBy === 'user' && (
																			<MenuItem value={'removedPosts'}>
																				Removed Posts
																			</MenuItem>
																		)}
																		<MenuItem value={'likedPostData'}>
																			Liked Posts
																		</MenuItem>
																		<MenuItem value={'likedCommentData'}>
																			Liked Comments
																		</MenuItem>
																		<MenuItem value={'likedReplyData'}>
																			Liked Replies
																		</MenuItem>
																	</Select>
																</FormControl>
															</div>
														)}
														{queryBy === 'postAndComment' && (
															<div className='mgt'>
																<Divider textAlign='left'>
																	<Chip label='OPTIONAL' />
																</Divider>

																<CustomInput
																	variant='outlined'
																	type='subDocumentId'
																	titleLabel='Sub-Collection Document ID'
																	placeholder='SUB-ID'
																	name='subDocumentId'
																	inputId='subDocumentId'
																	autoComplete='subDocumentId'
																	onChange={this.handleChange}
																	helperText={
																		reportsErrors || errors.subDocumentId
																	}
																	inputError={
																		reportsErrors || errors.subDocumentId
																			? true
																			: false
																	}
																	inputValue={subDocumentId}
																	required={true}
																	maxLength={120}
																	fullWidth={true}
																	deletable={() =>
																		this.resetInput('subDocumentId')
																	}
																/>
															</div>
														)}
													</div>
												) : queryBy === 'keyValue' ? (
													<div>
														<FormControl fullWidth>
															<label className='inputText mgb3px'>Key</label>
															<Select
																value={reportKey}
																name='reportKey'
																onChange={this.handleChange}
															>
																<MenuItem value={'userId'}>
																	Reporter / User Id
																</MenuItem>
																{(collection === 'userReports' ||
																	collection === 'postReports' ||
																	collection === 'commentReports' ||
																	collection === 'featureReports') && (
																	<MenuItem value={'reported'}>
																		Reported
																	</MenuItem>
																)}
																{(collection === 'postReports' ||
																	collection === 'commentReports') && (
																	<MenuItem value={'postId'}>Post Id</MenuItem>
																)}
																{collection === 'commentReports' && (
																	<MenuItem value={'commentId'}>
																		Comment Id
																	</MenuItem>
																)}

																{collection === 'issueReports' && (
																	<MenuItem value={'priority'}>
																		Priority
																	</MenuItem>
																)}
																{collection === 'issueReports' && (
																	<MenuItem value={'device'}>Device</MenuItem>
																)}
																{collection === 'issueReports' && (
																	<MenuItem value={'browser'}>Browser</MenuItem>
																)}
																{collection === 'issueReports' && (
																	<MenuItem value={'brand'}>Brand</MenuItem>
																)}
																{collection === 'issueReports' && (
																	<MenuItem value={'boundaryId'}>
																		Boundary ID
																	</MenuItem>
																)}
																{collection === 'supportReports' && (
																	<MenuItem value={'topic'}>Topic</MenuItem>
																)}
															</Select>
														</FormControl>

														{reportKey === 'priority' ? (
															<FormControl fullWidth>
																<label className='mgt inputText mgb3px'>
																	Priority
																</label>
																<Select
																	value={priority}
																	name='priority'
																	onChange={this.handleChange}
																>
																	<MenuItem value={'minor'}>MINOR</MenuItem>
																	<MenuItem value={'medium'}>MEDIUM</MenuItem>
																	<MenuItem value={'major'}>MAJOR</MenuItem>
																</Select>
															</FormControl>
														) : reportKey === 'topic' ? (
															<FormControl fullWidth>
																<label className='mgt inputText mgb3px'>
																	Topic
																</label>
																<Select
																	value={topic}
																	name='topic'
																	onChange={this.handleChange}
																>
																	<MenuItem value='support'>Support</MenuItem>
																	<MenuItem value='marketing'>
																		Marketing
																	</MenuItem>
																	<MenuItem value='jobs'>Jobs</MenuItem>
																	<MenuItem value='suggestions'>
																		Suggestions
																	</MenuItem>
																	<MenuItem value='legal'>Legal</MenuItem>
																	<MenuItem value='copyright'>
																		Copyright
																	</MenuItem>
																	<MenuItem value='refunds'>Refunds</MenuItem>
																</Select>
															</FormControl>
														) : reportKey === 'device' ? (
															<FormControl
																fullWidth
																className='controlEnvironment'
															>
																<label className='mgt inputText mgb3px'>
																	Value
																</label>

																<Select
																	name='device'
																	value={device}
																	onChange={this.handleChange}
																>
																	<MenuItem value='computer'>Computer</MenuItem>
																	<MenuItem value='phone'>Phone</MenuItem>
																</Select>
															</FormControl>
														) : reportKey === 'browser' ? (
															<FormControl fullWidth>
																<label className='mgt inputText mgb3px'>
																	Value
																</label>
																<Select
																	labelId='Browser Selector'
																	value={browser}
																	name='browser'
																	onChange={this.handleChange}
																>
																	<MenuItem value='chrome'>
																		Google Chrome
																	</MenuItem>
																	<MenuItem value='edge'>
																		Microsoft Edge
																	</MenuItem>
																	<MenuItem value='explorer'>
																		Internet Explorer
																	</MenuItem>
																	<MenuItem value='safari'>Safari</MenuItem>
																	<MenuItem value='firefox'>Firefox</MenuItem>
																	<MenuItem value='opera'>Opera</MenuItem>
																	<MenuItem value='other'>Other</MenuItem>
																</Select>
															</FormControl>
														) : reportKey === 'brand' ? (
															<FormControl fullWidth>
																<label className='mgt inputText mgb3px'>
																	Value
																</label>
																<Select
																	labelId='Brand Selector'
																	value={brand}
																	name='brand'
																	onChange={this.handleChange}
																>
																	<MenuItem value='iphone'>Iphone</MenuItem>
																	<MenuItem value='samsung'>Samsung</MenuItem>
																	<MenuItem value='xiaomi'>Xiaomi</MenuItem>
																	<MenuItem value='motorola'>Motorola</MenuItem>
																	<MenuItem value='oppo'>OPPO</MenuItem>
																	<MenuItem value='google'>Google</MenuItem>
																	<MenuItem value='onePlus'>One Plus</MenuItem>
																	<MenuItem value='huawei'>Huawei</MenuItem>
																	<MenuItem value='other'>Other</MenuItem>
																</Select>
															</FormControl>
														) : (
															<CustomInput
																variant='outlined'
																type='keyValue'
																titleLabel='Value'
																placeholder='SUB-ID'
																name='keyValue'
																inputId='keyValue'
																autoComplete='keyValue'
																onChange={this.handleChange}
																helperText={errors.keyValue}
																inputError={
																	reportsErrors || errors.keyValue
																		? true
																		: false
																}
																inputValue={keyValue}
																required={true}
																maxLength={120}
																fullWidth={true}
																deletable={() => this.resetInput('keyValue')}
															/>
														)}
													</div>
												) : null}
											</div>
										</div>
									)}

									{(panel === 'reportPanel' || panel === 'queryReports') && (
										<div>
											<CustomButton
												onClick={() => !loadingReports && this.getReports()}
												variant='contained'
												btnText={t('search')}
												id='customInfoButton'
												margin='2rem auto 1rem auto'
												loading={
													loadingReports ||
													loadingAccountReports ||
													loadingProfile ||
													loadingPost
												}
												endIcon={<SearchIcon />}
											/>
											<Dialog
												disableEnforceFocus
												// disableAutoFocus
												fullScreen
												open={reportDialog}
												onClose={this.handleClose}
												style={{ zIndex: '4' }}

												// TransitionComponent={Transition}
											>
												<Toolbar id='dialogBar'>
													<div className='titleToolbar'>
														{dialogCollectionTitle}
													</div>
													<CloseIcon
														id='closeDialogButton'
														onClick={this.handleClose}
														aria-label='close'
													/>
												</Toolbar>
												<DialogContent className='reportDialogContent'>
													{reportArray &&
														reportArray.map(
															(report) =>
																report.reportId === reportDialogId && (
																	<div key={report.reportId}>
																		<div
																			className={`pd card ${
																				mode === 'dark' && 'dbc'
																			}`}
																		>
																			{(collection === 'issueReports' ||
																				collection === 'supportReports' ||
																				collection === 'featureReports' ||
																				collection === 'feedback' ||
																				collection === 'boundaryReports') && (
																				<AdminActions
																					userId={report.userId}
																					userQueried
																					reportDialog
																				/>
																			)}

																			<AccountReports
																				collection={collection}
																				report={report}
																				reportDialogId={reportDialogId}
																			/>
																			{report.boundaryId && (
																				<div className='flex alignItemsCenter'>
																					<p className='mg0 bold'>
																						Error Boundary
																					</p>
																					<CopyText text={report.boundaryId} />
																				</div>
																			)}
																			{report.transactionId && (
																				<div className='flex alignItemsCenter'>
																					<p className='mg0 bold'>
																						Transaction ID
																					</p>
																					<CopyText
																						text={report.transactionId}
																					/>
																				</div>
																			)}
																			{report.amount && (
																				<div className='flex alignItemsCenter'>
																					<p className='mg0 bold'>
																						Amount ${report.amount}
																					</p>
																				</div>
																			)}
																			{collection === 'issueReports' && (
																				<div className='flex'>
																					<p className='mgtOnly'>
																						<b>Device:</b> {report.device} -{' '}
																						{report.device === 'computer' ? (
																							<span>
																								<b>Browser:</b> {report.browser}
																							</span>
																						) : (
																							<span>
																								<b>Brand:</b> {report.brand}
																							</span>
																						)}
																					</p>
																				</div>
																			)}

																			<hr />

																			{report.title && (
																				<h2 className='underline mgtOnly'>
																					{collection === 'issueReports' ||
																					collection === 'supportReports' ||
																					collection === 'featureReports'
																						? report.title
																						: collection === 'boundaryReports'
																						? JSON.stringify(report.title)
																						: this.formatReport(report.title)}
																				</h2>
																			)}

																			{typeof report.body === 'string' && (
																				<p className='mg0'>{report.body}</p>
																			)}
																			{report.name !== undefined && (
																				<p className='mgtOnly'>
																					Name: <b>{report.name}</b>
																				</p>
																			)}
																			{collection !== 'issueReports' &&
																				collection !== 'supportReports' &&
																				collection !== 'userReports' &&
																				collection !== 'featureReports' &&
																				collection !== 'feedback' &&
																				collection !== 'boundaryReports' && (
																					<div>
																						<p className='mgtOnly'>
																							Infringement:{' '}
																							{report.infringements
																								? 'True'
																								: 'False'}
																						</p>
																					</div>
																				)}
																		</div>
																		<hr />
																		<hr />

																		<div
																			className={`card pdbExcept ${
																				mode === 'dark' && 'dbc'
																			}`}
																		>
																			{collection === 'issueReports' &&
																				report.topic && (
																					<b>
																						Report moved from support reports (
																						{report.topic}).
																					</b>
																				)}
																			{report.responsibleAdmin &&
																				report.responsibleAdmin.length > 0 && (
																					<CustomAlerts
																						info={true}
																						alertColor='secondary'
																						message={JSON.stringify(
																							report.responsibleAdmin,
																						)}
																						noCenter
																						alertTitle={
																							report.responsibleAdmin.length > 1
																								? 'Responsible Admins:'
																								: 'Responsible Admin:'
																						}
																						icon={<AdminPanelSettingsIcon />}
																						margin='0 0 1rem 0'
																					/>
																				)}
																			<CustomAlerts
																				info={true}
																				message='Previous Admin Actions - Comments'
																				icon={<ModeCommentOutlinedIcon />}
																				margin='0 0 1rem 0'
																			/>
																			{report.adminActions &&
																				report.adminActions.length > 0 &&
																				report.adminActions.map(
																					(obj, index) => {
																						return (
																							<div key={index}>
																								<CustomAlerts
																									info={true}
																									severity={obj.severity}
																									variant={
																										obj.deleted
																											? ''
																											: commentId ===
																											  obj.commentId
																											? 'filled'
																											: 'outlined'
																									}
																									alertTitle={obj.handle}
																									close={
																										commentId &&
																										obj.userId ===
																											credentials.userId
																									}
																									onClose={() =>
																										this.handleConfirmationDialog(
																											true,
																											'delCommentDialog',
																											obj.commentId,
																										)
																									}
																									secondClose={
																										obj.userId ===
																										credentials.userId
																									}
																									secondTooltip={t('edit')}
																									secondIcon={
																										<EditIcon fontSize='small' />
																									}
																									secondAction={() =>
																										this.editComment(
																											obj.body,
																											obj.severity,
																											obj.commentId,
																										)
																									}
																									icon={
																										sendingCommentAdmin ===
																										obj.commentId ? (
																											<CircularProgress
																												size={20}
																												color={
																													adminReportSeverity
																												}
																											/>
																										) : (
																											''
																										)
																									}
																									smallAlert={
																										obj.deleted
																											? 'Deleting'
																											: sendingCommentAdmin ===
																											  obj.commentId
																											? 'uploading'
																											: `${dayjs(
																													obj.createdAt,
																											  ).fromNow()} by
																												${obj.userId}`
																									}
																									message={obj.body}
																									noCenter
																									margin='0 0 1rem 0'
																								/>
																							</div>
																						);
																					},
																				)}

																			<ConfirmationDialog
																				open={delCommentDialog}
																				customButtonId='customDangerButton'
																				closeFunc={() =>
																					this.handleConfirmationDialog(
																						false,
																						'delCommentDialog',
																						'',
																					)
																				}
																				actionBtnMargin='1rem 0 0.5rem 0'
																				actionBtnText={t('delete')}
																				action={() =>
																					this.submitComment(
																						report.reportId,
																						report.adminActions,
																						true,
																					)
																				}
																				phrase='Delete Comment'
																				phrase2="Action can't be undone"
																				contentTitle='Are you sure you want to delete your comment?'
																			/>
																			<Divider textAlign='left'>
																				{commentId
																					? 'Editing'
																					: 'Admin Action (if needed)'}
																				{commentId && (
																					<b
																						className='link mgl4px'
																						onClick={() =>
																							this.editComment('', 'info', '')
																						}
																					>
																						- undo
																					</b>
																				)}
																			</Divider>
																			<div className='flex pdY'>
																				<FormControl id='severityAdminControl'>
																					<InputLabel>
																						{adminReportSeverity}
																					</InputLabel>
																					<Select
																						value={adminReportSeverity}
																						name='adminReportSeverity'
																						label={adminReportSeverity}
																						onChange={this.handleChange}
																					>
																						<MenuItem value='info'>
																							<InfoOutlinedIcon id='infoIcon' />
																						</MenuItem>
																						<MenuItem value='warning'>
																							<WarningAmberIcon id='warningIcon' />
																						</MenuItem>
																						<MenuItem value='error'>
																							<ReportGmailerrorredIcon id='errorIcon' />
																						</MenuItem>
																						<MenuItem value='success'>
																							<CheckCircleOutlinedIcon id='successIcon' />
																						</MenuItem>
																					</Select>
																				</FormControl>

																				<CustomInput
																					variant='outlined'
																					type='comment'
																					placeholder='Type your report action / comment.'
																					name='comment'
																					inputId='comment'
																					autoComplete='comment'
																					onChange={this.handleChange}
																					helperText={errors.comment}
																					inputError={
																						errors.comment ? true : false
																					}
																					inputValue={comment}
																					maxLength={3000}
																					fullWidth
																					deletable={() =>
																						this.resetInput('comment')
																					}
																				/>
																				<CustomButton
																					onClick={() =>
																						this.submitComment(
																							report.reportId,
																							report.adminActions,
																						)
																					}
																					iconButton
																					disableRipple
																					startIcon={
																						<SendIcon
																							sx={{ fontSize: '1.4rem' }}
																						/>
																					}
																					padding='0'
																					margin='0 0 0 8px'
																					color='inherit'
																					disabled={comment === ''}
																					tooltip={
																						commentId ? t('update') : t('send')
																					}
																					placement='right'
																				/>
																			</div>
																		</div>
																		<hr />

																		{collection === 'userReports' ||
																		collection === 'postReports' ||
																		collection === 'commentReports' ||
																		collection === 'userAppeals' ||
																		collection === 'postAppeals' ||
																		collection === 'commentAppeals' ? (
																			<div
																				className={`card pd ${
																					mode === 'dark' && 'dbc'
																				}`}
																				style={{
																					overflowY: 'scroll',
																					height: '75vh',
																				}}
																				id='scrollableAdminDiv'
																			>
																				{collection === 'userReports' ||
																				collection === 'userAppeals' ? (
																					<div>
																						<div className='spaceAround'>
																							<p>Posts: {postCount}</p>
																							<p>
																								Unreleased Posts:{' '}
																								{unreleasedPostCount}
																							</p>
																						</div>
																						<div className='flex'>
																							<FormControl fullWidth>
																								<InputLabel>
																									Sub-Collection
																								</InputLabel>
																								<Select
																									value={subCollection}
																									label='Sub-Collection'
																									name='subCollection'
																									onChange={
																										this.handleChangeCollection
																									}
																								>
																									<MenuItem value={'posts'}>
																										Posts
																									</MenuItem>

																									<MenuItem
																										value={'removedPosts'}
																									>
																										Removed Posts
																									</MenuItem>

																									<MenuItem
																										value={'unreleasedPosts'}
																									>
																										Unreleased Posts
																									</MenuItem>
																								</Select>
																							</FormControl>
																							<button
																								onClick={() =>
																									this.getRemovedPosts(report)
																								}
																							>
																								fetch
																							</button>
																						</div>
																						<div>
																							<CustomAlerts
																								close
																								info={
																									loadingAccountRestriction
																										? t('loading')
																										: null
																								}
																							/>
																							<CustomAlerts
																								close
																								success={accountRestriction}
																							/>
																							<CustomAlerts
																								close
																								error={errorAccountRestriction}
																							/>
																						</div>
																						<User
																							adminUserId={
																								report.userId
																									? report.userId
																									: report.reported
																							}
																						/>
																					</div>
																				) : collection === 'postReports' ||
																				  collection === 'postAppeals' ||
																				  collection === 'commentReports' ||
																				  collection === 'commentAppeals' ? (
																					<div>
																						{errorLoadingPost && (
																							<div>
																								<CustomButton
																									onClick={() =>
																										this.getPostAdminFunc(
																											adminReportPostId,
																											'unreleasedPosts',
																											reportCommentCollection,
																											report.commentId,
																										)
																									}
																									variant='contained'
																									btnText='Search on Unreleased Posts
																									Collection'
																									id='customCancelButton'
																									fontSize='15px'
																									endIcon={<SearchIcon />}
																								/>
																								<hr />
																								<CustomButton
																									onClick={() =>
																										this.getPostAdminFunc(
																											adminReportPostId,
																											'posts',
																											reportCommentCollection,
																											report.commentId,
																										)
																									}
																									variant='contained'
																									btnText='Search on Posts
																									Collection'
																									id='customCancelButton'
																									fontSize='15px'
																									endIcon={<SearchIcon />}
																								/>
																								<hr />
																								<CustomButton
																									onClick={() =>
																										this.getPostAdminFunc(
																											adminReportPostId,
																											'removedPosts',
																											reportCommentCollection,
																											report.commentId,
																										)
																									}
																									variant='contained'
																									btnText='Search on Removed Posts
																									Collection'
																									id='customCancelButton'
																									fontSize='15px'
																									endIcon={<SearchIcon />}
																								/>
																								<hr />
																								{collection ===
																									'commentReports' && (
																									<div className='flex center'>
																										<div>
																											comments
																											<Radio
																												id='checkbox_auth'
																												checked={
																													reportCommentCollection ===
																													'comments'
																												}
																												required
																												onClick={() =>
																													this.setState({
																														reportCommentCollection:
																															'comments',
																													})
																												}
																											/>
																										</div>
																										-||-
																										<div>
																											<Radio
																												id='checkbox_auth'
																												checked={
																													reportCommentCollection ===
																													'removedComments'
																												}
																												required
																												onClick={() =>
																													this.setState({
																														reportCommentCollection:
																															'removedComments',
																													})
																												}
																											/>
																											removed comments
																										</div>
																									</div>
																								)}
																							</div>
																						)}
																						{loadingPost ? (
																							<div>
																								<CustomAlerts info='Loading post.' />
																								<Skeleton
																									variant='rectangular'
																									width='100%'
																									height='40vh'
																									animation='wave'
																									sx={{
																										margin: '1rem auto 0 auto',
																									}}
																								/>
																							</div>
																						) : (
																							<PostDialog
																								queryBy={queryBy}
																								openAdminDialog={false}
																								subCollection={subCollection}
																							/>
																						)}
																						{!loadingPost && (
																							<div>
																								<PostComments
																									comments={adminPostComments}
																								/>
																								<CustomButton
																									onClick={() =>
																										!loadingAdminPostComments &&
																										this.getRemovedPostComments()
																									}
																									variant='contained'
																									btnText={
																										adminPostComments.length >
																											0 &&
																										adminPostComments.length %
																											10 ===
																											0
																											? 'Search More Removed'
																											: 'Search Removed Comments'
																									}
																									id='customCancelButton'
																									fontSize='15px'
																									loading={
																										loadingAdminPostComments
																									}
																									endIcon={<SearchIcon />}
																								/>
																							</div>
																						)}
																					</div>
																				) : null}
																			</div>
																		) : null}

																		<hr />
																		<div
																			className={`card pd ${
																				mode === 'dark' && 'dbc'
																			}`}
																		>
																			<CustomAlerts
																				success={true}
																				message={
																					<CopyText
																						frase='Report Id:'
																						text={report.reportId}
																						noMargin
																					/>
																				}
																				icon={<FindInPageOutlinedIcon />}
																				margin='0 0 0.5rem 0'
																			/>
																			<span className='flex center mgbH'>
																				Reported on{' '}
																				{dayjs(report.createdAt).format('LLLL')}{' '}
																				<b className='mgl6px'>
																					({this.formatDate(report.createdAt)}{' '}
																					ago)
																				</b>
																			</span>

																			{report.active &&
																				collection === 'supportReports' &&
																				adminChangeReport === 'dontChange' && (
																					<div>
																						<h2 className='center underline'>
																							Move to Issues
																						</h2>{' '}
																						<div className='flexCenter'>
																							<FormControlLabel
																								value='dontMove'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='convertSupportToIssue'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											convertSupportToIssue ===
																											'dontMove'
																										}
																									/>
																								}
																								label="Don't move"
																								labelPlacement='bottom'
																							/>
																							<FormControlLabel
																								value='move'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='convertSupportToIssue'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											convertSupportToIssue ===
																											'move'
																										}
																									/>
																								}
																								label='Move'
																								labelPlacement='bottom'
																							/>
																						</div>
																					</div>
																				)}
																			{report.active &&
																				collection === 'issueReports' && (
																					<div>
																						<h2 className='center underline'>
																							Change Priority
																						</h2>{' '}
																						<div className='flexCenter'>
																							<FormControlLabel
																								value='dontChange'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='adminChangeReport'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											adminChangeReport ===
																											'dontChange'
																										}
																									/>
																								}
																								label="Don't change"
																								labelPlacement='bottom'
																							/>
																							<FormControlLabel
																								value='change'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='adminChangeReport'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											adminChangeReport ===
																											'change'
																										}
																									/>
																								}
																								label={t('change')}
																								labelPlacement='bottom'
																							/>
																						</div>
																					</div>
																				)}
																			{((adminChangeReport === 'change' &&
																				collection === 'issueReports') ||
																				(collection === 'supportReports' &&
																					convertSupportToIssue === 'move' &&
																					adminChangeReport ===
																						'dontChange')) && (
																				<div className='pdY'>
																					<FormControl fullWidth>
																						<InputLabel>Priority</InputLabel>
																						<Select
																							value={updatedPriority}
																							label='Priority'
																							name='updatedPriority'
																							onChange={this.handleChange}
																						>
																							{report.priority !== 'minor' && (
																								<MenuItem value={'minor'}>
																									MINOR
																								</MenuItem>
																							)}
																							{report.priority !== 'medium' && (
																								<MenuItem value={'medium'}>
																									MEDIUM
																								</MenuItem>
																							)}
																							{report.priority !== 'major' && (
																								<MenuItem value={'major'}>
																									MAJOR
																								</MenuItem>
																							)}
																						</Select>
																					</FormControl>
																				</div>
																			)}

																			{collection === 'supportReports' &&
																				convertSupportToIssue === 'move' &&
																				adminChangeReport === 'dontChange' && (
																					<div>
																						<FormControl fullWidth>
																							<InputLabel>Device</InputLabel>
																							<Select
																								label='Device'
																								name='createDevice'
																								value={createDevice}
																								onChange={this.handleChange}
																							>
																								<MenuItem value='computer'>
																									Computer
																								</MenuItem>

																								<MenuItem value='phone'>
																									Phone
																								</MenuItem>
																							</Select>
																						</FormControl>
																						{createDevice === 'computer' ? (
																							<div className='pdt08'>
																								<FormControl fullWidth>
																									<Select
																										labelId='Browser Selector'
																										value={createBrowser}
																										name='createBrowser'
																										onChange={this.handleChange}
																									>
																										<MenuItem value='chrome'>
																											Google Chrome
																										</MenuItem>
																										<MenuItem value='edge'>
																											Microsoft Edge
																										</MenuItem>
																										<MenuItem value='explorer'>
																											Internet Explorer
																										</MenuItem>
																										<MenuItem value='safari'>
																											Safari
																										</MenuItem>
																										<MenuItem value='firefox'>
																											Firefox
																										</MenuItem>
																										<MenuItem value='opera'>
																											Opera
																										</MenuItem>
																										<MenuItem value='other'>
																											Other
																										</MenuItem>
																									</Select>
																								</FormControl>
																							</div>
																						) : (
																							<div className='pdt08'>
																								<FormControl fullWidth>
																									<Select
																										labelId='Brand Selector'
																										value={createBrand}
																										name='createBrand'
																										onChange={this.handleChange}
																									>
																										<MenuItem value='iphone'>
																											Iphone
																										</MenuItem>
																										<MenuItem value='samsung'>
																											Samsung
																										</MenuItem>
																										<MenuItem value='xiaomi'>
																											Xiaomi
																										</MenuItem>
																										<MenuItem value='motorola'>
																											Motorola
																										</MenuItem>
																										<MenuItem value='oppo'>
																											OPPO
																										</MenuItem>
																										<MenuItem value='google'>
																											Google
																										</MenuItem>
																										<MenuItem value='onePlus'>
																											One Plus
																										</MenuItem>
																										<MenuItem value='huawei'>
																											Huawei
																										</MenuItem>
																										<MenuItem value='other'>
																											Other
																										</MenuItem>
																									</Select>
																								</FormControl>
																							</div>
																						)}
																					</div>
																				)}
																			{report.active &&
																				collection === 'supportReports' &&
																				convertSupportToIssue ===
																					'dontMove' && (
																					<div>
																						<h2 className='center underline'>
																							Change Topic
																						</h2>{' '}
																						<div className='flexCenter'>
																							<FormControlLabel
																								value='dontChange'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='adminChangeReport'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											adminChangeReport ===
																											'dontChange'
																										}
																									/>
																								}
																								label="Don't change"
																								labelPlacement='bottom'
																							/>
																							<FormControlLabel
																								value='change'
																								control={
																									<Radio
																										onChange={
																											this.handleCheckedPanel
																										}
																										name='adminChangeReport'
																										sx={{
																											color: '#2980b9',
																										}}
																										checked={
																											adminChangeReport ===
																											'change'
																										}
																									/>
																								}
																								label={t('change')}
																								labelPlacement='bottom'
																							/>
																						</div>
																						{adminChangeReport === 'change' && (
																							<div className='pdY'>
																								<FormControl
																									fullWidth
																									className='pd'
																								>
																									<InputLabel>Topic</InputLabel>
																									<Select
																										value={updatedTopic}
																										label='Report Topic'
																										name='updatedTopic'
																										onChange={this.handleChange}
																									>
																										{report.topic !==
																											'support' && (
																											<MenuItem value='support'>
																												Support
																											</MenuItem>
																										)}
																										{report.topic !==
																											'marketing' && (
																											<MenuItem value='marketing'>
																												Marketing
																											</MenuItem>
																										)}
																										{report.topic !==
																											'jobs' && (
																											<MenuItem value='jobs'>
																												Jobs
																											</MenuItem>
																										)}
																										{report.topic !==
																											'suggestions' && (
																											<MenuItem value='suggestions'>
																												Suggestions
																											</MenuItem>
																										)}
																										{report.topic !==
																											'legal' && (
																											<MenuItem value='legal'>
																												Legal
																											</MenuItem>
																										)}
																										{report.topic !==
																											'copyright' && (
																											<MenuItem value='copyright'>
																												Copyright
																											</MenuItem>
																										)}
																										{report.topic !==
																											'refunds' && (
																											<MenuItem value='refunds'>
																												Refunds
																											</MenuItem>
																										)}
																									</Select>
																								</FormControl>
																							</div>
																						)}
																					</div>
																				)}

																			{(collection !== 'supportReports' ||
																				(collection === 'supportReports' &&
																					convertSupportToIssue ===
																						'dontMove')) && (
																				<div className='flex spaceAround mgY'>
																					{report.active && (
																						<div>
																							<h2 className='center underline mg0'>
																								Viewed
																							</h2>{' '}
																							<div className='flexCenter halfWidth'>
																								<FormControlLabel
																									value='viewed'
																									control={
																										<Radio
																											onChange={
																												this.handleCheckedPanel
																											}
																											name='adminViewedReport'
																											sx={{
																												color: '#2980b9',
																											}}
																											checked={
																												adminViewedReport ===
																												'viewed'
																											}
																										/>
																									}
																									label='Viewed'
																									labelPlacement='bottom'
																								/>
																								<FormControlLabel
																									value='unviewed'
																									control={
																										<Radio
																											onChange={
																												this.handleCheckedPanel
																											}
																											name='adminViewedReport'
																											sx={{
																												color: '#2980b9',
																											}}
																											checked={
																												adminViewedReport ===
																												'unviewed'
																											}
																										/>
																									}
																									label='Unview'
																									labelPlacement='bottom'
																								/>
																							</div>
																						</div>
																					)}
																					{adminViewedReport === 'viewed' && (
																						<div>
																							<h2 className='center underline mg0'>
																								Action
																							</h2>{' '}
																							<div className='flexCenter'>
																								{report.active ? (
																									<div>
																										<FormControlLabel
																											value='closeIssue'
																											control={
																												<Radio
																													onChange={
																														this
																															.handleCheckedPanel
																													}
																													color='success'
																													name='adminOpenedReport'
																													sx={{
																														color: '#2980b9',
																													}}
																													checked={
																														adminOpenedReport ===
																														'closeIssue'
																													}
																												/>
																											}
																											label={t('close')}
																											labelPlacement='bottom'
																										/>
																									</div>
																								) : (
																									<FormControlLabel
																										value='reopenIssue'
																										control={
																											<Radio
																												onChange={
																													this
																														.handleCheckedPanel
																												}
																												name='adminOpenedReport'
																												sx={{
																													color: '#2980b9',
																												}}
																												checked={
																													adminOpenedReport ===
																													'reopenIssue'
																												}
																											/>
																										}
																										label='Reopen'
																										labelPlacement='bottom'
																									/>
																								)}
																							</div>
																						</div>
																					)}
																				</div>
																			)}

																			<CustomButton
																				onClick={() =>
																					this.reportUpdate(report)
																				}
																				variant='contained'
																				btnText='Update Report'
																				margin='2rem auto 1rem auto'
																				id='customInfoButton'
																				loading={loadingReportUpdate}
																				startIcon={<CloudUploadIcon />}
																			/>

																			<CustomAlerts
																				close
																				error={
																					reportDialogError &&
																					'Nothing to update.'
																				}
																				margin='0'
																			/>
																			{reportedId !== undefined &&
																				collection !== 'issueReports' &&
																				collection !== 'supportReports' &&
																				!loadingAccountReports &&
																				userId !==
																					'6qF5GMirSwMjxNesFhFuZh9Lks13' && (
																					<div>
																						<hr />
																						<CustomAlerts
																							close
																							verifying={
																								loadingAccountRestriction
																									? t('loading')
																									: null
																							}
																						/>
																						<CustomAlerts
																							close
																							success={accountRestriction}
																						/>
																						<CustomAlerts
																							close
																							error={errorAccountRestriction}
																						/>

																						<div className='spaceBetween'>
																							{reportedId.disabled ? (
																								<h2>Unban Account</h2>
																							) : (
																								<h2>Restrict - Ban Account</h2>
																							)}
																							<div className='flex spaceBetween'>
																								<CopyText
																									info={true}
																									text={userId}
																									frase='User Id:'
																									noMargin
																								/>
																								<DottedMenu
																									dataHandle={handle}
																									dataUserId={userId}
																									disabled={reportedId.disabled}
																									profile={true}
																									infringements={
																										reportedId?.disabled
																									}
																									appealable={appealable}
																									margin='0'
																								/>
																							</div>
																						</div>
																					</div>
																				)}
																		</div>
																		<CustomAlerts
																			close
																			error={updateReportError}
																			margin='0'
																		/>
																		<hr />
																		<hr />
																		{(report.userId || report.reported) && (
																			<div>
																				<CustomAlerts
																					info={true}
																					message='Contact'
																					icon={<EmailIcon />}
																					noMargin
																				/>
																				<CreateMessage
																					userId={report.userId}
																					reported={report.reported}
																					reportId={report.reportId}
																					reportType={dialogCollectionTitle}
																				/>
																			</div>
																		)}
																	</div>
																),
														)}
												</DialogContent>
											</Dialog>
											<CustomAlerts
												close
												error={errors.collection}
												margin='0'
											/>
											<CustomAlerts close error={reportsErrors} margin='0' />
										</div>
									)}
								</div>
							</div>
						</BackdropUi>

						{(panel === 'reportPanel' || panel === 'queryReports') && (
							<div>
								{loadingReports ||
								loadingMoreReports ||
								fetchingAdminComments ||
								loadingAccountReports ||
								loadingPost ||
								loadingProfile ? (
									<LinearProgress />
								) : (
									<span className='linearProgressBorder'></span>
								)}
								<CustomAlerts
									close
									error={errorFetchingAdminComments}
									margin='0'
								/>
								<BackdropUi open={loadingReports} circularProgress={false}>
									<div className='adminResultsContainer'>
										{panel === 'queryReports' &&
										(queryBy === 'user' ||
											queryBy === 'postId' ||
											queryBy === 'postAndComment') ? (
											// userAdminCard
											<div className='cardContainer'>
												<div className={`card pd ${mode === 'dark' && 'dbc'}`}>
													{queryBy === 'user' && handle ? (
														<div>
															<div
																style={{
																	overflowY: 'scroll',
																	height: '95vh',
																}}
																id='scrollableAdminDiv'
															>
																<User
																	adminUserId={documentId}
																	subCollection={subCollection}
																/>
															</div>
															<hr />
															<p>Goodbye Letters</p>
															<ol className='mg0 pd0'>
																{letters &&
																	letters.length > 0 &&
																	letters.map((obj, index) => {
																		return (
																			<li
																				key={index}
																				className='flex alignItemsCenter spaceBetween'
																			>
																				<div className='flex alignItemsCenter'>
																					<CopyText
																						info={true}
																						text={obj.letterId}
																						frase='Letter Id:'
																					/>
																					<small className='mgl'>
																						{dayjs(obj.releaseDate).fromNow()}
																					</small>
																				</div>
																				<CustomButton
																					onClick={() =>
																						this.handleReadLetter(
																							obj.letterId,
																							'letters',
																						)
																					}
																					margin='0'
																					btnText={`Read`}
																				/>
																			</li>
																		);
																	})}
															</ol>
															<p>Unreleased Goodbye Letters</p>
															<ol className='mg0 pd0'>
																{unreleasedLetters &&
																	unreleasedLetters.length > 0 &&
																	unreleasedLetters.map((obj, index) => {
																		return (
																			<li
																				key={index}
																				className='flex alignItemsCenter spaceBetween'
																			>
																				<div className='flex alignItemsCenter'>
																					<CopyText
																						info={true}
																						text={obj.letterId}
																						frase='Letter Id:'
																					/>
																					<small className='mgl'>
																						{dayjs(obj.releaseDate).fromNow()}
																					</small>
																				</div>
																				<CustomButton
																					onClick={() =>
																						this.handleReadLetter(
																							obj.letterId,
																							'unreleasedLetters',
																						)
																					}
																					margin='0'
																					btnText={`Read`}
																				/>
																			</li>
																		);
																	})}
															</ol>

															{adminUserComments.length > 0 &&
																(subCollection === 'comments' ||
																	subCollection === 'removedComments') && (
																	<div
																		id='scrollableAdminCommentDiv'
																		style={{
																			overflowY: 'scroll',
																			height: '40vh',
																			border: '1px solid #ccc',
																			marginTop: '1rem',
																		}}
																	>
																		<PostComments
																			comments={adminUserComments}
																			subCollection={subCollection}
																		/>
																	</div>
																)}
															{!loadingProfile && (
																<AdminActions
																	userId={userId ? userId : ''}
																	userQueried
																/>
															)}
														</div>
													) : (queryBy === 'postId' ||
															queryBy === 'postAndComment') &&
													  post.postId ? (
														<PostDialog
															queryReports={panel === 'queryReports'}
															openAdminDialog={false}
															subCollection={subCollection}
														/>
													) : (
														<CustomAlerts
															info={true}
															message='Awaiting your query.'
															noMargin
															icon={<AdminPanelSettingsIcon />}
														/>
													)}
												</div>
											</div>
										) : queryBy === 'commentId' &&
										  adminUserComments.length > 0 ? (
											<Comment
												comment={adminUserComments[0]}
												queryCommentId={true}
											/>
										) : (
											<CustomAlerts
												info={true}
												severity='info'
												icon={<SearchIcon />}
												message={`${
													typeof moreResults === 'string'
														? moreResults
														: moreResults > 1
														? `${moreResults} results`
														: `${moreResults} result`
												}.`}
												noMargin
											/>
										)}
										<InfiniteScroll
											dataLength={reportArray.length} //This is important field to render the next data
											scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
											next={() => this.getReports(lastReportCreatedAtKey, 10)}
											hasMore={
												reportArray.length > 0 &&
												reportArray.length % 10 === 0 &&
												!reportsErrors
											}
											loader={
												(loadingReports || loadingMoreReports) && (
													<ReportSkeleton
														number={reportLimit}
														reportCollection={collection}
													/>
												)
											}
											endMessage={
												reportArray.length > 10 ? (
													<CustomAlerts
														info={true}
														message={`All ${reportArray.length} results loaded.`}
														noMargin
													/>
												) : (
													<CustomAlerts
														info={true}
														message='No more reports found.'
														noMargin
													/>
												)
											}
										>
											{reportArray &&
												reportArray.map((report) => (
													<div
														key={report.reportId}
														className={`cardReport pointer mg ${
															mode === 'dark' && 'dbc'
														}`}
														id={
															!report.active
																? 'closedIssue'
																: !report.viewed
																? 'unviewedReport'
																: ''
														}
														onClick={() => this.openReportDialog(report)}
													>
														<li className='flex center pd'>
															<div className='followList'>
																<div className='listItemText sliceReportContainer'>
																	{report.boundaryId && (
																		<p className='topTranslate8'>
																			<b>Error Boundary: {report.boundaryId}</b>
																		</p>
																	)}
																	{report.title && (
																		<div>
																			<span className='listItemSpan '>
																				{collection === 'issueReports' ||
																				collection === 'supportReports' ||
																				collection === 'featureReports'
																					? report.title
																					: collection === 'boundaryReports'
																					? JSON.stringify(report.title)
																					: this.formatReport(report.title)}
																			</span>
																			<hr />
																		</div>
																	)}

																	{typeof report.body === 'string' && (
																		<p className='mg0 '>{report.body}</p>
																	)}
																</div>
															</div>
															<div className='reportSecondContainer'>
																{collection === 'issueReports' && (
																	<div className='flex alignItemsCenter spaceAround'>
																		<CustomButton
																			variant='outlined'
																			btnText={
																				report.priority &&
																				report.priority.toUpperCase()
																			}
																			color={
																				report.priority === 'minor'
																					? 'success'
																					: report.priority === 'medium'
																					? 'warning'
																					: 'error'
																			}
																			margin='0'
																			padding='5px'
																		/>
																		<span className='mg0 bold'>
																			{report.device} (
																			{report.device === 'computer'
																				? report.browser
																				: report.brand}
																			)
																		</span>
																	</div>
																)}
																{collection === 'supportReports' && (
																	<div className='flex'>
																		<CustomButton
																			variant='contained'
																			btnText={
																				report.topic &&
																				report.topic.toUpperCase()
																			}
																			id='customCancelButton'
																			margin='0 auto 0 auto'
																		/>
																	</div>
																)}

																<p>
																	Reported on{' '}
																	{dayjs(report.createdAt).format('LLLL')}{' '}
																	<small>
																		({this.formatDate(report.createdAt)} ago.)
																	</small>
																</p>
																<div className='reportData bold'>
																	<span>
																		{report.active ? (
																			<LockOpenIcon id='reportLockOpenIcon' />
																		) : (
																			<LockIcon id='reportLockIcon' />
																		)}
																		Active: {report.active ? 'true' : 'false'}
																	</span>
																	<span>
																		{report.viewed ? (
																			<VisibilityIcon id='reportVisIcon' />
																		) : (
																			<VisibilityOffIcon id='reportVisOffIcon' />
																		)}
																		Viewed: {report.viewed ? 'true' : 'false'}
																	</span>
																	{(collection !== 'issueReports' ||
																		collection !== 'supportReports' ||
																		collection !== 'featureReports' ||
																		collection !== 'feedback' ||
																		collection !== 'boundaryReports') && (
																		<span>
																			{report.infringements ? (
																				<TourRoundedIcon id='reportTourIcon' />
																			) : (
																				<HistoryToggleOffRoundedIcon id='reportHistoryIcon' />
																			)}
																			Infringements:{' '}
																			{report.infringements ? 'true' : 'false'}
																		</span>
																	)}
																</div>
															</div>
														</li>
													</div>
												))}
										</InfiniteScroll>
									</div>
								</BackdropUi>
							</div>
						)}

						{readLetter && (
							<Dialog
								open={true}
								onClose={this.handleCloseLetter}
								fullWidth
								maxWidth='xl'
							>
								<Toolbar id='dialogBar'>
									<div className='flex alignItemsCenter navbarHeight'>
										<h3 className='dialogTitle mg0'>Letter</h3>
									</div>

									<CloseIcon
										id='closeDialogButton'
										onClick={this.handleCloseLetter}
										aria-label='close'
									/>
								</Toolbar>
								<DialogContent sx={{ padding: 0 }}>
									{loadingReceivedGoodbyeLetter && <LinearProgress />}
									<CustomAlerts error={errorLoadingReceivedGoodbyeLetter} />
									<div>
										{Object.keys(receivedGoodbyeLetter).length > 0 ? (
											<div>
												<div className='mg flex textTitle alignItemsCenter'>
													<AccountBoxIcon className='mgrH' />
													<span className='mgrH'>{t('username')}:</span>
													<b>{handle}</b>
												</div>
												<p className='mg textTitle'>
													<EmailIcon className='mgrH bottomTranslate4' />
													<span className='mgrH'>Subject:</span>
													<b>
														<span className='capitalize'>
															{name ? name : handle}
														</span>{' '}
														wanted you to receive this goodbye letter with the
														following information:
													</b>
												</p>
												{receivedGoodbyeLetter.recipients &&
													receivedGoodbyeLetter.recipients.length > 0 && (
														<div>
															<p className='inputText mg'>
																<span className='underline'>Recipients</span>:{' '}
															</p>
															<ul>
																{receivedGoodbyeLetter.recipients.map(
																	(userId, index) => {
																		return (
																			<span key={index}>
																				<CopyText text={userId} />
																			</span>
																		);
																	},
																)}
															</ul>
														</div>
													)}
												{receivedGoodbyeLetter.emailRecipients &&
													receivedGoodbyeLetter.emailRecipients.length > 0 && (
														<div>
															<p className='inputText mg'>
																<span className='underline'>
																	Email Recipients
																</span>
																:{' '}
															</p>
															{receivedGoodbyeLetter.emailRecipients.map(
																(email, index) => {
																	return (
																		<span key={index}>
																			<CopyText text={email} />
																		</span>
																	);
																},
															)}
														</div>
													)}

												<div style={{ maxWidth: 805, margin: '1rem auto' }}>
													<div className='cardEmailSender'>
														{imageUrl === noImgUrl() ? (
															<div
																id='imgEmailCard'
																style={{
																	overflow: 'hidden',
																}}
															>
																<img
																	src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/emailBlankProfile.png?alt=media&token=604541ba-7ffd-4511-98a6-72fe61ee6f03'
																	alt={`${handle} img`}
																	id='noImgTransparent'
																/>
															</div>
														) : (
															<img
																src={imageUrl}
																alt={`${handle} img`}
																id='imgEmailCard'
															/>
														)}
														<p
															className='white mg0 imgHandleCard capitalize'
															style={{
																textShadow: '2px 1px #000',
															}}
														>
															{name ? name : handle}
														</p>
													</div>

													<img
														src={gbLetterBackground}
														alt='lake background'
														id='mailCard'
													/>
												</div>

												<div className='mg'>
													{receivedGoodbyeLetter.title && (
														<p className='inputTextTitle'>
															{receivedGoodbyeLetter.title}
														</p>
													)}
													<LetterTemplate
														sent={true}
														state={receivedGoodbyeLetter}
														titleCase={titleCase}
														admin
													/>
												</div>
											</div>
										) : (
											<Skeleton
												variant='rectangular'
												width='80%'
												height='20vh'
												animation='wave'
												sx={{ margin: '1rem auto 0 auto' }}
											/>
										)}

										<div className='letterFooter pdX'>
											<div className='flex spaceBetween alignItemsCenter'>
												<div className='backgroundLogo mgX'>
													<img className='logo' src={logo} alt='Logo' />
												</div>
												<img className='navbarGBLogo' src={gbaLogo} alt='' />
											</div>
										</div>
									</div>
								</DialogContent>
							</Dialog>
						)}
					</div>
				) : (
					<Navigate to='/feed' />
				)}
			</div>
		);
	}
}
Admin.propTypes = {
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	getReport: PropTypes.func.isRequired,
	getCollectionReports: PropTypes.func.isRequired,
	clearReportErrors: PropTypes.func.isRequired,
	submitCommentAdmin: PropTypes.func.isRequired,
	updateReport: PropTypes.func.isRequired,
	getAccountReports: PropTypes.func.isRequired,
	getPostAdmin: PropTypes.func.isRequired,
	getUserDataAdmin: PropTypes.func.isRequired,
	setAdminPanelData: PropTypes.func.isRequired,
	clearAccountRestriction: PropTypes.func.isRequired,
	clearAdminPostComments: PropTypes.func.isRequired,
	getAdminCollection: PropTypes.func.isRequired,
	setAdminReportId: PropTypes.func.isRequired,
	fetchCommentAdmin: PropTypes.func.isRequired,
	fetchUserLikedData: PropTypes.func.isRequired,
	getUserDetailsByUserIdAdmin: PropTypes.func.isRequired,
	getOnlyPostCommentsAdmin: PropTypes.func.isRequired,
	adminFetchGoodbyeLetter: PropTypes.func.isRequired,
	clearAccountReports: PropTypes.func.isRequired,
	clearQueriedReports: PropTypes.func.isRequired,
};

const mapActionToProps = {
	getReport,
	getCollectionReports,
	clearReportErrors,
	submitCommentAdmin,
	updateReport,
	getAccountReports,
	getPostAdmin,
	getUserDataAdmin,
	setAdminPanelData,
	clearAccountRestriction,
	clearAdminPostComments,
	getAdminCollection,
	setAdminReportId,
	fetchCommentAdmin,
	fetchUserLikedData,
	getOnlyPostCommentsAdmin,
	getUserDetailsByUserIdAdmin,
	adminFetchGoodbyeLetter,
	clearAccountReports,
	clearQueriedReports,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionToProps)(Admin);
