import {
	ERROR_IMG_UPLOAD,
	IMG_UPLOAD_PROGRESS,
	ERROR_SET_RESETED_IMAGE,
	SET_UNAUTHENTICATED,
	SET_ONLINE_STATUS,
	CLEAR_ERROR_IMG_UPLOAD,
	TOGGLE_MODE,
	NEW_VERSION_AVAILABLE,
} from '../types';

const initialState = {
	onlineStatus: true,
	uploadProgress: null,
	errorImageUpload: null,
	errorResetImage: null,
	mode: '',
	newVersionAvailable: false,
};

export default function userInterface(state = initialState, action) {
	switch (action.type) {
		case NEW_VERSION_AVAILABLE:
			return {
				...state,
				newVersionAvailable: true,
			};
		case SET_UNAUTHENTICATED:
			return {
				...initialState,
				mode: state.mode,
			};
		case TOGGLE_MODE:
			return {
				...state,
				mode: action.payload,
			};

		case SET_ONLINE_STATUS:
			return {
				...state,
				onlineStatus: action.payload,
			};
		case IMG_UPLOAD_PROGRESS:
			return {
				...state,
				uploadProgress: action.payload,
			};
		case ERROR_IMG_UPLOAD:
			return {
				...state,
				errorImageUpload: action.payload,
				uploadProgress: null,
			};
		case CLEAR_ERROR_IMG_UPLOAD:
			return {
				...state,
				errorImageUpload: null,
			};
		case ERROR_SET_RESETED_IMAGE:
			return {
				...state,
				errorResetImage: action.payload,
				uploadProgress: null,
			};

		default:
			return state;
	}
}
